import { DataTable } from './DataTable';

export let RecommendedWallWeights = `Wall Type,Light,Medium,Heavy
Curtain Wall,4.24,8.81,16.77
Stud Wall,5.00,10.47,17.25
EIFS,7.48,14.06,27.02
Brick Wall,43.78,82.56,183.02
Concrete Block Wall,19.33,27.68,45.88
Precast / Cast-in place block wall,29.41,72.85,143.1
Unknown,10,30,70`

export let RecommendedRoofWeights = `Roof Type,Light,Medium,Heavy
Sloped Frame Roofs,2.51,6.15,11.27
Wood Deck,9.98,10.91,11.84
Metal Deck,4.87,7.03,12.41
Concrete,30.53,63.04,97.82`

export function GetRecommendedWallWeightsData() {
  return DataTable.createFromCSV(RecommendedWallWeights)
}

export function GetRecommendedRoofWeightsData() {
  return DataTable.createFromCSV(RecommendedRoofWeights)
}