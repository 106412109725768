import { ref, reactive, watch, watchEffect, } from 'vue'
import * as ser from './SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { toRads, toDegs, } from './Math.js'
import { DoorsData } from './MaterialData/DoorsData.js'
import { WindowsData, StdAssemblyColMap, FFsVals,
  getSLFVal, } from './MaterialData/Windows/WindowsData.js' 
import { WallAndRoofData } from './MaterialData/WallsAndRoofs/WallAndRoofData.js'
import { kFloorCoverings } from './MaterialData/FloorCoverings.js'
import { WeatherDataMap } from './WeatherDataMap.js'
import { GroundSurfaceTempAmplitudes } from './WeatherData/GroundSurfaceTempAmplitudes.js'
import * as psy from './Psychrometrics.js'
import * as calc from './ResidentialCalculations.js'
import * as cool from './CoolingCalculations.js'
import * as solar from './SolarCalculations.js'
import { Units } from './Units.js'
import { CalcContext } from './CalcContext.js'

import { prettyJson, clearArray, valOr, getElemNames,
  getElemWithNameValue,
  addElem, removeElem, elemIn,
  extendArray, extendMap, isObject,
  downloadTextFile,
  runAfterDelay, waitMillis, awaitAll,
  valueOrFuncRes,
  StrictParseNumber,
} from './SharedUtils.js'
import Papa from 'papaparse';
export { Units } from './Units.js'

import {
  kEpsilon,
  FieldType,
  kFieldTypesData,
  FieldInputType,
  Field,
  FieldGroup,
  ProjectUnits,
} from './Field.js'

import { gApp, DebugOn } from './State.js'


export let kDirectionChoices = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
];

export let Season = makeEnum({
  Winter: 'Winter',
  Summer: 'Summer',
})

export let WallOrRoof = makeEnum({
  Wall: 'Wall',
  Roof: 'Roof',
})

export class WallLayer {
  init(optIsEditable, opts) {
    this.isEditable = valOr(optIsEditable, true)
    this.opts = valOr(opts, {});
    this.isRoof = valOr(this.opts.isRoof, false);

    this.materialInfo = null;

    this.category = new Field({
      name: 'Category',
      type: FieldType.Select,
      choicesFunc: () => {
        let cats = WallAndRoofData.instance().getWallLayerCategories();
        removeElem(cats, 'Defaults');
        if (this.isRoof) {
          removeElem(cats, 'Wall Materials');
        } else {
          removeElem(cats, 'Roofing');
        }
        return cats;
      },
      hiddenChoices: ['Defaults'],
    }) 
    this.category.makeUpdater((field) => {
      // field.visible = this.isEditable;
    })
    this.type = new Field({
      name: 'Type',
      type: FieldType.Select,
      choices: [],
    })
    this.type.makeChoicesUpdater(() => {
      return WallAndRoofData.instance().getWallLayerTypes(this.category.value);
    })

    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      this.materialInfo = WallAndRoofData.instance().lookupWallMaterial(
        this.category.value, this.type.value);
    })

    this.thickness = new Field({
      name: 'Thickness',
      type: FieldType.SmallLength,
    })
    this.thickness.makeUpdater((field) => {
      if (!this.materialInfo) {
        field.isOutput = true;
        field.value = 0;
        return;
      }
      if (this.materialInfo['Thickness Required'] == '1') {
        field.isOutput = false;
      } else {
        field.isOutput = true
        if (!('Thickness' in this.materialInfo)) {
          throw new Error(`Material '${this.materialInfo.Material}' requires 'Thickness' col but not found in data.`);
        }
        field.value = StrictParseNumber(this.materialInfo['Thickness']);
      }
    })

    this.rValue = new Field({
      name: 'R-Value',
      type: FieldType.RValue,
      isOutput: true,
      min: kEpsilon,
    })
    this.rValue.makeUpdater((field) => {
      field.value = this.getRValue();
    })

    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      this.category.isOutput = !this.isEditable;
      this.type.isOutput = !this.isEditable;
      this.thickness.isOutput = !this.isEditable;
    })

    this.serFields = [
      'category',
      'type',
      'thickness',
      'rValue',
      'isEditable',
      'materialInfo',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      '_name': 'Wall Layer',
    }
  }

  getObjErrors() {
    let errors = [];
    ObjectUtils.addErrorsFromDict(this.errorsDict);
    return errors;
  }

  static makeFirstLayer() {
    let layer = WallLayer.create(false, this.opts);
    layer.category.value = "Defaults";
    layer.type.value = "Outdoor Surface Resistance";
    return layer;
  }

  static makeLastLayer() {
    let layer = WallLayer.create(false, this.opts);
    layer.category.value = "Defaults";
    layer.type.value = "Indoor Layer Air Resistance";
    return layer;
  }

  getRValue() {
    if (!this.materialInfo) {
      return 0;
    }
    if (this.materialInfo['Thickness Required'] == '1') {
      let thickness = this.thickness.value;
      let conductivity = StrictParseNumber(lookupData(this.materialInfo, ['Conductivity']));
      return thickness / conductivity;
    } else {
      return StrictParseNumber(lookupData(this.materialInfo, ['Resistance']));
    }
  }

  getThickness() {
    return this.thickness.value;
  }

  // Get the wall weight per area in lb/ft^2
  getWallWeight() {
    if (!this.materialInfo) {
      return 0
    }
    if (!('Density' in this.materialInfo)) {
      throw new Error(`Material '${this.materialInfo.Material}' requires 'Density' col but not found in data.`);
    }
    return StrictParseNumber(this.materialInfo['Density']) * (this.getThickness() / 12.0);
  }
};
setupClass(WallLayer)

export class WallBuilder {
  init(opts) {
    this.opts = valOr(opts, {});

    let firstLayer = WallLayer.makeFirstLayer();
    let lastLayer = WallLayer.makeLastLayer();
    this.layers = [
      firstLayer,
      lastLayer,
    ];

    this.serFields = [
      ser.arrayField('layers', () => {
        return WallLayer.create(true, this.opts);
      }),
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      '_name': 'Wall Builder',
      'layers': 'Layer',
    }
  }

  addLayer() {
    let newLayer = WallLayer.create(true, this.opts);
    addElem(this.layers, newLayer, this.layers.length - 1);
    return newLayer;
  }

  deleteLayer(layer) {
    removeElem(this.layers, layer);
  }

  computeRValue() {
    let rValue = 0;
    for (const layer of this.layers) {
      rValue += layer.getRValue();
    }
    return rValue;
  }

  computeExteriorWallWeight() {
    let wallWeight = 0
    for (const layer of this.layers) {
      wallWeight += layer.getWallWeight();
    }
    return wallWeight;
  }
};
setupClass(WallBuilder)

export let ThermalResistanceEntryMethod = makeEnum({
  Manual: 'Manual',
  BuildWall: 'Build wall',
})

export class WallThermalResistance {
  init(opts) {
    this.opts = valOr(opts, {});
    let isRoof = 

    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: [
        {label: 'Manual', value: ThermalResistanceEntryMethod.Manual},
        {label: `Build ${this.opts.isRoof ? 'Roof' : 'Wall'}`, value: ThermalResistanceEntryMethod.BuildWall},
      ],
      bold: true,
    })
    this.manualRValue = new Field({
      name: 'R-Value',
      type: FieldType.RValue,
      min: kEpsilon,
    })
    this.manualRValue.setVisibility(() => {
      return this.entryMethod.value == ThermalResistanceEntryMethod.Manual;
    })
    this.manualExteriorWallWeight = new Field({
      name: 'Exterior Wall Weight',
      type: FieldType.WeightPerArea,
    })
    this.manualExteriorWallWeight.setVisibility(() => {
      return this.entryMethod.value == ThermalResistanceEntryMethod.Manual;
    })

    this.wallBuilder = WallBuilder.create(this.opts);
    ObjectUtils.setEnabledWhen(this.wallBuilder, () => {
      return this.entryMethod.value == ThermalResistanceEntryMethod.BuildWall;
    })

    this.outputRValue = new Field({
      name: 'R-Value',
      type: FieldType.RValue,
      isOutput: true,
    })
    this.outputRValue.makeUpdater((field) => {
      if (this.entryMethod.value == ThermalResistanceEntryMethod.Manual) {
        field.value = this.manualRValue.value;
      } else {
        field.value = this.wallBuilder.computeRValue();
      }
    })
    this.outputExteriorWallWeight = new Field({
      name: 'Exterior Wall Weight',
      type: FieldType.WeightPerArea,
      isOutput: true,
    })
    this.outputExteriorWallWeight.makeUpdater((field) => {
      field.value = this.getWallWeight();
    })

    this.serFields = [
      'entryMethod',
      'manualRValue',
      'manualExteriorWallWeight',
      'wallBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Thermal Resistance',
    }
  }

  getWallWeight() {
    if (this.entryMethod.value == ThermalResistanceEntryMethod.Manual) {
      return this.manualExteriorWallWeight.value;
    } else {
      return this.wallBuilder.computeExteriorWallWeight();
    }
  }
}
setupClass(WallThermalResistance)

export let AbsorptanceEntryMethod = makeEnum({
  Manual: 'Manual',
  SelectByMaterial: 'Select by material',
})

export class WallSolarAbsorptance {
  init(opts) {
    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: makeOptions(AbsorptanceEntryMethod),
      bold: true,
    })
    this.absorptance = new Field({
      name: 'Absorptance',
      type: FieldType.Ratio,
      min: kEpsilon,
    })
    this.absorptance.setVisibility(() => {
      return this.entryMethod.value == AbsorptanceEntryMethod.Manual;
    })
    
    let materialsEnum = WallAndRoofData.instance().MaterialAbsorptance;
    this.material = new Field({
      name: 'Material',
      type: FieldType.Select,
      choices: makeOptions(materialsEnum),
    })
    this.material.setVisibility(() => {
      return this.entryMethod.value == AbsorptanceEntryMethod.SelectByMaterial;
    })

    this.outputAbsorptance = new Field({
      name: 'Absorptance',
      type: FieldType.Ratio,
      isOutput: true
    })
    this.outputAbsorptance.makeUpdater((field) => {
      if (this.entryMethod.value == AbsorptanceEntryMethod.Manual) {
        field.value = this.absorptance.value;
      } else {
        field.value = lookupData(materialsEnum._data, [this.material.value]).value;
      }
    })

    this.serFields = [
      'entryMethod',
      'absorptance',
      'material',
      'outputAbsorptance',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      '_name': 'Solar Absorptance',
    }
  }
}
setupClass(WallSolarAbsorptance)

/*
WallTypeOptions:
Curtain wall
Stud wall
EIFS
Brick wall
Concrete block wall
Precast / Cast-in-place block wall
Unknown
*/
export let WallTypeOption = makeEnum({
  CurtainWall: 'Curtain wall',
  StudWall: 'Stud wall',
  EIFS: 'EIFS',
  BrickWall: 'Brick wall',
  ConcreteBlockWall: 'Concrete block wall',
  PrecastCastInPlaceBlockWall: 'Precast / Cast-in-place block wall',
  Unknown: 'Unknown',
});

/*
Roof type options:
Sloped frame roof
Wood deck roof
Metal deck roof
Concrete roof
Unknown
*/
export let RoofTypeOption = makeEnum({
  SlopedFrameRoof: 'Sloped frame roof',
  WoodDeckRoof: 'Wood deck roof',
  MetalDeckRoof: 'Metal deck roof',
  ConcreteRoof: 'Concrete roof',
  Unknown: 'Unknown',
})

export class WallType {
  init(name, makeId, opts) {
    this.id = makeId ? gApp.proj().makeId('WallType') : 0;
    this.opts = valOr(opts, {});
    
    let isRoof = valOr(this.opts.isRoof, false);
    this.name = Field.makeName(`${isRoof ? 'Roof' : 'Wall'} Name`, name)

    this.type = Field.makeSelect('Type', !isRoof ? WallTypeOption : RoofTypeOption)
    this.thermalResistance = WallThermalResistance.create(this.opts);
    this.solarAbsorptance = WallSolarAbsorptance.create(this.opts);

    this.serFields = [
      'id',
      'name',
      'type',
      'thermalResistance',
      'solarAbsorptance',
    ];

    this.childObjs = '$auto'
    this.objInfo = {
      _name: isRoof ? 'Roof' : 'Wall',
    }
  }

  getRValue() {
    return this.thermalResistance.outputRValue.value;
  }

  getAbsorptance() {
    return this.solarAbsorptance.outputAbsorptance.value;
  }

  getWallWeight() {
    return this.thermalResistance.getWallWeight();
  }
};
setupClass(WallType)

export class RoofType extends WallType {
  init(name, makeId) {
    super.init(name, makeId, {isRoof: true});
  }
};
setupClass(RoofType)

export let NumPanes = makeEnum({
  'N1': '1',
  'N2': '2',
  'N3': '3',
  'N4': '4',
});

export let FrameStyle = makeEnum({
  AluminumWithoutThermalBreak: 'Aluminum without thermal break',
  AluminumWithThermalBreak: 'Aluminum with thermal break',
  WoodSlashVinyl: 'Wood/vinyl',
  StructuralGlazing: 'Structural Glazing',
  ReinforcedVinylSlashAluminumCladWood: 'Reinforced vinyl / aluminum clad wood',
  InsulatedFiberglassSlashVinyl: 'Insulated fiberglass / vinyl',
});

export let Glazing = makeEnum({
  None: 'None',
  E0_6: 'e=0.6',
  E0_4: 'e=0.4',
  E0_2: 'e=0.2',
  E0_1: 'e=0.1',
  E0_05: 'e=0.05',
});

export let PanesAppliedTo = makeEnum({
  Surface2: 'Surface 2',
  Surface3: 'Surface 3',
  Surface2or3: 'Surface 2 or 3',
  Surface4or5: 'Surface 4 or 5',
  Surface2or3and4or5: 'Surface 2 or 3 and 4 or 5',
})

export let Tint = makeEnum({
  Clear: 'Clear',
  Bronze: 'Bronze',
  Green: 'Green',
  Grey: 'Grey',
  BlueGreen: 'Blue-green',
  HighPerfGreen: 'High-performance green',
  StainlessSteelOnClear8p: 'Stainless steel on clear (8%)',
  StainlessSteelOnClear14p: 'Stainless steel on clear (14%)',
  StainlessSteelOnGreen14p: 'Stainless steel on green (14%)',
  StainlessSteelOnClear20p: 'Stainless steel on clear (20%)',
  TitaniumOnClear20p: 'Titanium on clear (20%)',
  TitaniumOnClear30p: 'Titanium on clear (30%)',
});


let OneHalfSymbol = '½'
let OneQuarterSymbol = 'TODO'

export let PaneThickness = makeEnum({
  QuarterInch: '1/4"',
  EighthInch: '1/8"',
})

export let GapType = makeEnum({
  None: 'None',
  QuarterInchAir: '1/4" Air',
  HalfInchAir: `1/2" Air`,
  QuarterInchArgon: '1/4" Argon',
  HalfInchArgon: `1/2" Argon`,
  QuarterInchKrypton: '1/4" Krypton',
})

export let BugScreen = makeEnum({
  None: 'None',
  Interior: 'Interior',
  Exterior: 'Exterior',
})

export class WindowBuilder {
  init(win, opts) {
    opts = valOr(opts, {})
    this.window = win;
    this.isSlidingGlassDoor = valOr(opts.isSlidingGlassDoor, false);

    let builder = this;
    this.numPanes = new Field({
      name: 'Number of Panes',
      type: FieldType.Select,
      choices: [],
    });
    this.numPanes.makeChoicesUpdater(() => {
      if (this.isSlidingGlassDoor) {
        return makeOptions(NumPanes, [NumPanes.N1, NumPanes.N2]);
      }
      if (this.getWinStyle() == WindowStyle.GardenWindow) {
       return makeOptions(NumPanes, [NumPanes.N1, NumPanes.N2]);
      } else {
        return makeOptions(NumPanes,
          [NumPanes.N1, NumPanes.N2, NumPanes.N3, NumPanes.N4]);
      }
    });

    this.frameStyle = new Field({
      name: 'Frame Style',
      type: FieldType.Select,
      choices: [],
    });
    this.frameStyle.makeChoicesUpdater(() => {
      // console.log("Window style: ", win.style.value);
      if (this.isSlidingGlassDoor) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
        ])
      }
      if (this.getWinStyle() == WindowStyle.GardenWindow) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.WoodSlashVinyl,
        ])
      } else if (this.getWinStyle() == WindowStyle.CurtainWall) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      } else if (this.getWinStyle() == WindowStyle.Fixed
        || this.getWinStyle() == WindowStyle.Operable) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
          FrameStyle.InsulatedFiberglassSlashVinyl,
        ]);
      }
      throw new Error("Unrecognized window style: " + this.getWinStyle());
    })

    this.useStdFrameWidth = makeYesNoField('Use default frame width')
    // TODO - should probs more be a 'isEditable' rather than an output
    this.frameWidth = new Field({
      name: 'Frame Width',
      type: FieldType.SmallLength,
      isOutput: true,
    })
    this.frameWidth.makeUpdater(() => {
      if (this.useStdFrameWidth.value == YesNo.Yes) {
        this.frameWidth.value = lookupData(WindowType._getStdFrameWidthMap(), [
          this.getWinStyle(),
          this.frameStyle.value,
        ])
        this.frameWidth.isOutput = true;
      } else {
        this.frameWidth.isOutput = false;
      }
    })

    this.glazing = new Field({
      name: 'Glazing',
      type: FieldType.Select,
      choices: [],
    });
    this.glazing.makeChoicesUpdater(() => {
      if (this.isSlidingGlassDoor) {
        if (builder.numPanes.value == NumPanes.N1) {
          return makeOptions(Glazing, [Glazing.None])
        } else if (builder.numPanes.value == NumPanes.N2) {
          return makeOptions(Glazing, [Glazing.None, Glazing.E0_2])
        } else {
          throw new Error(`Unexpected numPanes for a sliding glass door: ${builder.numPanes.value}`);
        }
      }
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(Glazing, [Glazing.None])
      } else if (builder.numPanes.value == NumPanes.N2) {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_6,
          Glazing.E0_4,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ])
      } else if (builder.numPanes.value == NumPanes.N3 ||
        builder.numPanes.value == NumPanes.N4) {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ]);
      }
    })

    this.panesAppliedTo = new Field({
      name: 'Panes Applied To',
      type: FieldType.Select,
      choices: [],
    });
    this.panesAppliedTo.makeChoicesUpdater((field) => {
      if (this.isSlidingGlassDoor) {
        // Note: we don't want to display this input for Sliding glass doors.
        // For SlidingGlassDoor, N = 1 or 2 and for N = 2 we always use PanesAppliedTo = Surface3
        field.visible = false;
      }
      // console.log("Updating panesAppliedTo");
      if (builder.numPanes.value == NumPanes.N1) {
        return [];
      } else if (builder.numPanes.value == NumPanes.N2) {
        if (builder.glazing.value == Glazing.E0_6) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3]);
        } else if (builder.glazing.value == Glazing.E0_4 || builder.glazing.value == Glazing.E0_2 ||
          builder.glazing.value == Glazing.E0_1 || builder.glazing.value == Glazing.E0_05) {
          if (this.isSlidingGlassDoor) {
            return makeOptions(PanesAppliedTo, [
              PanesAppliedTo.Surface3,
            ]);
          } else {
            return makeOptions(PanesAppliedTo, [
              PanesAppliedTo.Surface2,
              PanesAppliedTo.Surface3,
            ]);
          }
        } else {
          return [];
        }
      } else if (builder.numPanes.value == NumPanes.N3 || builder.numPanes.value == NumPanes.N4) {
        if (builder.glazing.value == Glazing.None) {
          return [];
        } else if (builder.glazing.value == Glazing.E0_2) {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3,
            PanesAppliedTo.Surface4or5,
            PanesAppliedTo.Surface2or3and4or5,
          ])
        } else if (builder.glazing.value == Glazing.E0_1 || builder.glazing.value == Glazing.E0_05) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3and4or5])
        } else {
          return [];
        }
      }
    })

    this.tint = new Field({
      name: 'Tint',
      type: FieldType.Select,
      choices: [],
    });
    this.tint.makeChoicesUpdater(() => {
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(Tint, [
          Tint.Clear,
          Tint.Bronze,
          Tint.Green,
          Tint.Grey,
          Tint.BlueGreen,
          Tint.StainlessSteelOnClear8p,
          Tint.StainlessSteelOnClear14p,
          Tint.StainlessSteelOnGreen14p,
          Tint.StainlessSteelOnClear20p,
          Tint.TitaniumOnClear20p,
          Tint.TitaniumOnClear30p,
        ])
      } else if (builder.numPanes.value == NumPanes.N2) {
        if (elemIn(builder.glazing.value, [Glazing.None, Glazing.E0_6])) {
          return makeOptions(Tint)
        } else if (elemIn(builder.glazing.value, [Glazing.E0_4, Glazing.E0_2, Glazing.E0_1])) {
          if (builder.panesAppliedTo.value == PanesAppliedTo.Surface2) {
            return makeOptions(Tint, [Tint.Clear]);
          } else if (builder.panesAppliedTo.value == PanesAppliedTo.Surface3) {
            return makeOptions(Tint, [
              Tint.Clear,
              Tint.Bronze,
              Tint.Green,
              Tint.Grey,
              Tint.BlueGreen,
              Tint.HighPerfGreen,
            ])
          } else {
            throw new Error("Unexpected PanesAppliedTo in tint updater.");
          }
        } else if (builder.glazing.value == Glazing.E0_05) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.Bronze,
            Tint.Green,
            Tint.Grey,
            Tint.BlueGreen,
            Tint.HighPerfGreen,
          ])
        } else {
          throw new Error("Unexpected glazing: " + builder.glazing.value);
        }
      } else {
        if (builder.glazing.value == Glazing.None) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.HighPerfGreen,
          ])
        } else {
          return makeOptions(Tint, [
            Tint.Clear,
          ])
        }
      }
    })
    this.paneThickness = new Field({
      name: 'Pane Thickness',
      type: FieldType.Select,
      choices: [],
    });
    this.paneThickness.makeChoicesUpdater(() => {
      if (elemIn(builder.tint.value, [Tint.Clear, Tint.Bronze, Tint.Green, Tint.Grey])) {
        return makeOptions(PaneThickness, [
          PaneThickness.QuarterInch,
          PaneThickness.EighthInch,
        ])
      } else {
        return makeOptions(PaneThickness, [PaneThickness.QuarterInch])
      }
    })
    this.gapType = new Field({
      name: 'Gap Type',
      type: FieldType.Select,
      choices: [],
    });
    this.gapType.makeChoicesUpdater(() => {
      //let name = this.isSlidingGlassDoor ? `SlidingGlassDoor` : `${this.window.name.value}`;
      // console.log(`${name || 'noname'}: Updating gapType. NumPanes: ${builder.numPanes.value}`);
      if (this.isSlidingGlassDoor) {
        if (builder.numPanes.value == NumPanes.N1) {
          return makeOptions(GapType, [
            GapType.None
          ])
        } else {
          return makeOptions(GapType, [
            GapType.QuarterInchAir,
            GapType.HalfInchAir,
            GapType.QuarterInchArgon,
            GapType.HalfInchArgon,
          ])
        }
      }
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(GapType, [
          GapType.None
        ])
      } else if (elemIn(builder.numPanes.value, [NumPanes.N2, NumPanes.N3])) {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
        ])
      } else {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
          GapType.QuarterInchKrypton,
        ])
      }
    })

    this.fields = [
      'numPanes',
      'frameStyle',
      'useStdFrameWidth',
      'frameWidth',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ];

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Window Builder',
    }
  }

  getWinStyle() {
    if (!this.isSlidingGlassDoor) {
      return this.window.style.value;
    } else {
      return WindowStyle.Operable;
    }
  }

  /*
  getValues() {
    return {
      frameWidth: this.frameWidth.getValueInUnits(Units.ft),
      numPanes: this.numPanes.value,
      panesAppliedTo: this.panesAppliedTo.value,
      tint: this.tint.value,
      paneThickness: this.paneThickness.value,
      gapType: this.gapType.value,
      glazing: this.glazing.value,
      windowStyle: this.getWinStyle(),
      frameStyle: this.frameStyle.value,
    }
  }
  */
}
setupClass(WindowBuilder)

export let WindowStyle = makeEnum({
  Fixed: 'Fixed',
  Operable: 'Operable',
  GardenWindow: 'GardenWindow',
  CurtainWall: 'CurtainWall',
});

export let WindowInputType = makeEnum({
  Manual: 'Manual',
  BuildWindow: 'Build Window',
})

export class WindowType {
  init(name, makeId) {
    let windowType = this;

    this.id = makeId ? gApp.proj().makeId('WindowType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Height',
      type: FieldType.Length,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
    })

    this.unusualShape = makeNoYesField('Non-rectangular shape', {visible: false})
    this.unusualShapeArea = new Field({
      name: 'Shape Area',
      type: FieldType.Area,
    })
    this.unusualShapePerimeter = new Field({
      name: 'Shape Perimeter',
      type: FieldType.Length,
    })
    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      let unusualShape = this.unusualShape.value == YesNo.Yes;
      this.height.visible = !unusualShape;
      this.width.visible = !unusualShape;
      this.unusualShapeArea.visible = unusualShape;
      this.unusualShapePerimeter.visible = unusualShape;
    })

    this.style = Field.makeSelect('Window Style', WindowStyle)
    this.sealing = Field.makeSelect('Installation Sealing', InstallationSealing)
    this.bugScreen = new Field({
      name: 'Bug screen',
      type: FieldType.Select,
      choices: [],
    });
    this.bugScreen.makeChoicesUpdater(() => {
      if (this.style.value == WindowStyle.Operable) {
        return makeOptions(BugScreen, [BugScreen.Interior, BugScreen.Exterior, BugScreen.None])
      } else {
        return makeOptions(BugScreen, [BugScreen.None])
      }
    })

    this.inputType = Field.makeSelect('Input type', WindowInputType, {bold: true})

    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: kEpsilon,
    })
    this.manualUValue.setVisibility(() => {
      return this.inputType.value == 'Manual';
    })
    this.manualShgc = new Field({
      name: 'SHGC (Total Window)',
      type: FieldType.Ratio,
    })
    this.manualShgc.setVisibility(() => {
      return this.inputType.value == 'Manual';
    })

    this.windowBuilder = WindowBuilder.create(this);
    ObjectUtils.setEnabledWhen(this.windowBuilder, () => {
      this.inputType.value == WindowInputType.BuildWindow;
    })

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = this.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
    })
    this.outputShgc.makeUpdater((field) => {
      // console.log("Updating outputShgc");
      let shgc = this.computeShgc();
      field.value = shgc.TotalWindow;
      field.debugOutput = DebugOn() ? prettyJson(shgc) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'unusualShape',
      'unusualShapePerimeter',
      'style',
      'sealing',
      'bugScreen',
      'inputType',
      'manualUValue',
      'manualShgc',
      'windowBuilder',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: `Window`,
    }
  }

  getObjErrors() {
    let errors = [];
    ObjectUtils.addErrorsFromDict(errors, this.errorsDict);
    return errors;
  }

  getArea() {
    if (this.unusualShape.value == YesNo.No) {
      return this.height.value * this.width.value;
    } else {
      return this.unusualShapeArea.value;
    }
  }

  getPerimeter() {
    if (this.unusualShape.value == YesNo.No) {
      return 2 * this.height.value + 2 * this.width.value;
    } else {
      return this.unusualShapePerimeter.value;
    }
  }

  hasBugScreen() {
    return this.bugScreen.value != BugScreen.None;
  }

  getWindowStyle() {
    return this.style.value;
  }

  getWindowBuilderIfUsing() {
    if (this.inputType.value == WindowInputType.BuildWindow) {
      return this.windowBuilder;
    }
    return null;
  }

  static _getUValuesRow(numPanes, glazing,
    panesAppliedTo, gapType) {
    if (numPanes == NumPanes.N1) {
      return 2;
    } else {
      // Row = startRow + gapIndex
      let startRow = null;
      if (numPanes == NumPanes.N2) {
        let rowIdMap = {
          [Glazing.None]: 5,
          [Glazing.E0_6]: 10,
          [Glazing.E0_4]: 15,
          [Glazing.E0_2]: 20,
          [Glazing.E0_1]: 25,
          [Glazing.E0_05]: 30,
        }
        startRow = lookupData(rowIdMap, [glazing]);
      } else if (numPanes == NumPanes.N3) {
        if (glazing == Glazing.None) {
          startRow = 35;
        } else if (glazing == Glazing.E0_2) {
          let rowIdMap = {
            [PanesAppliedTo.Surface2or3]: 40,
            [PanesAppliedTo.Surface4or5]: 40,
            [PanesAppliedTo.Surface2or3and4or5]: 45,
          };
          startRow = lookupData(rowIdMap, [panesAppliedTo]);
        } else if (glazing == Glazing.E0_1 || glazing == Glazing.E0_05) {
          startRow = 50;
        } else {
          throw new Error("Unexpected glazing: " + glazing);
        }
      } else if (numPanes == NumPanes.N4) {
        startRow = 55;
      } else {
        throw new Error("Unexpected - cannot find U-Value start row");
      }

      let rowGapMap = {
        [GapType.QuarterInchAir]: 1,
        [GapType.HalfInchAir]: 2,
        [GapType.QuarterInchArgon]: 3,
        [GapType.HalfInchArgon]: 4,
        [GapType.QuarterInchKrypton]: 5,
      };
      let gapIndex = lookupData(rowGapMap, [gapType])
      return startRow + gapIndex;
    }
  }

  static computeUValue(inputData) {
    let width = inputData.width; // ft
    let height = inputData.height; // ft
    let frameWidth = inputData.frameWidth; // ft
    let numPanes = inputData.numPanes;
    let glazing = inputData.glazing;
    let panesAppliedTo = inputData.panesAppliedTo;
    let gapType = inputData.gapType;
    // Note: this must be either a WindowStyle of SkylightStyle enum val
    let windowStyle = inputData.windowStyle;
    let frameStyle = inputData.frameStyle;
    let windowsData = inputData.windowsData;
    let isSkylight = valOr(inputData.isSkylight, false);
    let curbHeight = valOr(inputData.curbHeight, 0);

    // TODO - handle unusual shapes
    if (width == 0 || height == 0) {
      console.log("W or H is 0. Returning U-Value=0");
      return {
        uValue: 0,
      }
    }

    let debugVals = {};
    let rowIndex = WindowType._getUValuesRow(numPanes, glazing, panesAppliedTo, gapType);
    let centerUValue = windowsData.uValues.lookupValue(rowIndex, !isSkylight ? 'D' : 'U');
    let edgeUValue = windowsData.uValues.lookupValue(rowIndex, !isSkylight ? 'E' : 'V');
    extendMap(debugVals, {
      rowIndex,
      centerUValue,
      edgeUValue,
    })

    let stdAssemblyCol = lookupData(StdAssemblyColMap, [
      windowStyle,
      frameStyle,
    ]);
    let stdAssemblyUValue = windowsData.uValues.lookupValue(rowIndex, stdAssemblyCol);
    let stdVals = WindowType._getStdValues(windowStyle, frameStyle);
    extendMap(debugVals, {
      stdAssemblyCol,
    });

    let uValueInputs = {
      H: height ,
      W: width,
      Fw: frameWidth,
      Hs: stdVals.Hs,
      Ws: stdVals.Ws,
      Fws: stdVals.Fws,
      C: centerUValue,
      E: edgeUValue,
      Us: stdAssemblyUValue,
      isSkylight,
      curbHeight,
    }
    let uValueOutputs = WindowType._calculateUValue(uValueInputs);
    // Include some intermediate values in the output
    return {
      ...uValueInputs,
      ...uValueOutputs,
      // Main output:
      uValue: uValueOutputs.Uval,
      uValueFrame: uValueOutputs.Uf,
      debugVals,
    }
  }

  static _getStdFrameWidthMap() {
    return {
      [WindowStyle.Operable]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.5,
        [FrameStyle.AluminumWithThermalBreak]: 2.1,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 2.8,
        [FrameStyle.WoodSlashVinyl]: 2.8,
        [FrameStyle.InsulatedFiberglassSlashVinyl]: 3.1,
      },
      [WindowStyle.Fixed]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.3,
        [FrameStyle.AluminumWithThermalBreak]: 1.3,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 1.6,
        [FrameStyle.WoodSlashVinyl]: 1.6,
        [FrameStyle.InsulatedFiberglassSlashVinyl]: 1.8,
      },
      [WindowStyle.GardenWindow]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.75,
        [FrameStyle.WoodSlashVinyl]: 1.75,
      },
      [WindowStyle.CurtainWall]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.25,
      },
      [SkylightStyle.ManufacturedSkylight]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 0.7,
        [FrameStyle.AluminumWithThermalBreak]: 0.7,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 0.9,
        [FrameStyle.WoodSlashVinyl]: 0.9,
      },
      [SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.5,
      },
      [SkylightStyle.SlopedCurtainWall]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.25,
      }
    };
  }

  static _getStdDimensionsMap() {
    return {
      [WindowStyle.Operable]: {Hs: 5, Ws: 4},
      [WindowStyle.Fixed]: {Hs: 5, Ws: 4},
      [WindowStyle.GardenWindow]: {Hs: 5, Ws: 4},
      [WindowStyle.CurtainWall]: {Hs: 6.67, Ws: 6.67},
      [SkylightStyle.ManufacturedSkylight]: {Hs: 4, Ws: 4},
      [SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing]: {Hs: 6.67, Ws: 6.67},
      [SkylightStyle.SlopedCurtainWall]: {Hs: 6.67, Ws: 6.67},
    };
  }

  static _getStdValues(windowStyle, frameStyle) {
    let Fws = lookupData(WindowType._getStdFrameWidthMap(), [
      windowStyle,
      frameStyle,
    ])
    let stdDimensions = lookupData(WindowType._getStdDimensionsMap(), [
      windowStyle,
    ])
    // Return in units of feet
    return {
      Fws: Fws / 12.0,
      Hs: stdDimensions.Hs,
      Ws: stdDimensions.Ws,
    }
  }

  // TODO - unused?
  static _calculateAreaValues(inputs) {
    let W = inputs.W;
    let H = inputs.H;
    let Fw = inputs.Fw;
    let Ew = inputs.Ew;

    let Ac = (H - Fw*2 - Ew*2) * (W - Fw*2 - Ew*2);
    let Ae = (H - Fw*2)*(W - Fw*2) - Ac;
    let A = H * W;
    let Af = A - Ac - Ae;
    return {
      Ac: Ac,
      Ae: Ae,
      A: A,
      Af: Af,
    }
  }

  static _calculateUValue(inputs) {
    /*
    Values used in calculations: (note: named differently!)

    Inputs:
    Fw = Frame width (feet)	** required user input
    C = Center of glass U-value
    E = Edge of glass U-value
    Us = Standard assembly U-value
    H = Height	(ft)	** required user input
    W = Width	(ft)	** required user input
    Hs = Standard window height
    Ws = Standard window width
    Fws  = Standard window frame width

    Other:
    isSkylight
    curbHeight (ft)

    Calc:
    Ew = 2.5 / 12		(constant ; Ew = Edge Width)
    Ac = (H - Fw*2 - Ew*2)*(W - Fw*2 - Ew*2)	(Center area)
    Ae = (H - Fw*2)*(W - Fw*2) - Ac			(Edge area)
    A = H * W						(Area)
    Af = A - Ac - Ae					(Frame area)

    Acs = (Hs - 2*Fws - 2*Ew) * (Ws - 2*Fws - 2*Ew)
    Aes = (Hs - 2*Fws) * (Ws - 2*Fws) - Acs
    As = Hs * Ws
    Afs = As - Acs - Aes

    Uf = (Us*As - C*Acs - E*Aes) / Afs

    Final U-value Calculation:
    Uval = (C*Ac + E*Ae + Uf*Af) / A
    */
    // console.log("Calculating U-Value with inputs: " + prettyJson(inputs));
    let H = inputs.H;
    let W = inputs.W;
    let Fw = inputs.Fw;
    let Hs = inputs.Hs;
    let Ws = inputs.Ws;
    let Fws = inputs.Fws;
    let C = inputs.C;
    let E = inputs.E;
    let Us = inputs.Us;

    if (H == 0 || W == 0) {
      throw new Error("Invalid H or W: 0ft");
    }

    let Ew = 2.5 / 12.0;
    let Ac = (H - Fw*2 - Ew*2) * (W - Fw*2 - Ew*2);
    let Ae = (H - Fw*2)*(W - Fw*2) - Ac;
    let A = H * W;
    let Af = A - Ac - Ae;

    // TODO - account for the unusualShape building types properly
    let Acs = (Hs - 2*Fws - 2*Ew) * (Ws - 2*Fws - 2*Ew);
    let Aes = (Hs - 2*Fws) * (Ws - 2*Fws) - Acs;
    let As = Hs * Ws;
    let Afs = As - Acs - Aes;

    let Uf = (Us*As - C*Acs - E*Aes) / Afs;
    let Uval = (C*Ac + E*Ae + Uf*Af) / A;

    return {
      // Main output
      Uval: Uval,
      // Intermediate outputs
      Ew: Ew,
      Ac: Ac,
      Ae: Ae,
      A: A,
      Af: Af,
      Acs: Acs,
      Aes: Aes,
      As: As,
      Afs: Afs,
      Uf: Uf,
    };
  }

  static _getTotalWindowShgcCol(frameStyle, windowStyle) {
    if (frameStyle == FrameStyle.AluminumWithoutThermalBreak ||
        frameStyle == FrameStyle.AluminumWithThermalBreak) {
      if (windowStyle == WindowStyle.Operable) {
        return 'P';
      } else {
        return 'Q';
      }
    } else {
      if (windowStyle == WindowStyle.Operable) {
        return 'R';
      } else {
        return 'S';
      }
    }
    throw new Error("Could not find Total Window SHGC column.");
  };

  // For use with the manual method
  static _estimateShgcs(shgcTotalWindow, uValue) {
    let multipliers = null;
    if (uValue >= 0.9) {
      multipliers = {
        Deg0: 1, Deg40: 0.98, Deg50: 0.95, Deg60: 0.89, Deg70: 0.77, Deg80: 0.50, Diffuse: 0.90,
      }
    } else if (uValue >= 0.6) {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.92, Deg60: 0.83, Deg70: 0.67, Deg80: 0.38, Diffuse: 0.86,
      }
    } else if (uValue >= 0.50) {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.91, Deg60: 0.83, Deg70: 0.65, Deg80: 0.35, Diffuse: 0.86,
      }
    } else {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.90, Deg60: 0.79, Deg70: 0.57, Deg80: 0.27, Diffuse: 0.84,
      }
    }
    
    // Note: the 'shgc' value is an alias for the TotalWindow value
    let shgcs = {TotalWindow: shgcTotalWindow, shgc: shgcTotalWindow}
    for (const key in multipliers) {
      shgcs[key] = shgcTotalWindow * multipliers[key];
    }
    return shgcs;
  }

  static _estimateSkylightShgcs(shgcTotalWindow) {
    let multipliers = {
      Deg0: 1, Deg40: 0.982, Deg50: 0.939, Deg60: 0.860, Deg70: 0.658, Deg80: 0.368, Diffuse: 0.877,
    }
    // Note: the 'shgc' value is an alias for the TotalWindow value
    let shgcs = {TotalWindow: shgcTotalWindow, shgc: shgcTotalWindow}
    for (const key in multipliers) {
      shgcs[key] = shgcTotalWindow * multipliers[key];
    }
    return shgcs;
  }

  static _getShgcValuesRow(numPanes, panesAppliedTo,
      paneThickness, tint, glazing) {
    let sectionRowMap = {
      [NumPanes.N1]: 1,
      [NumPanes.N2]: 77,
      [NumPanes.N3]: 365,
      [NumPanes.N4]: 365
    }
    let sectionRow = lookupData(sectionRowMap, [numPanes])
    let subSecRow1 = null;
    let subSecRow2 = null;
    let paneAdj = 0;

    if (numPanes == NumPanes.N1) {
      paneAdj = paneThickness == PaneThickness.EighthInch ? -5 : 0;
      subSecRow1 = 5;
      let tintIndexMap = {
        [Tint.Clear]: 0,
        [Tint.Bronze]: 1,
        [Tint.Green]: 2,
        [Tint.Grey]: 3,
        [Tint.BlueGreen]: 4,
        [Tint.StainlessSteelOnClear8p]: 5,
        [Tint.StainlessSteelOnClear14p]: 6,
        [Tint.StainlessSteelOnClear20p]: 7,
        [Tint.StainlessSteelOnGreen14p]: 8,
        [Tint.TitaniumOnClear20p]: 9,
        [Tint.TitaniumOnClear30p]: 10,
      }
      let tintIndex = lookupData(tintIndexMap, [tint])
      subSecRow2 = tintIndex <= 3 ? 10*tintIndex : (30 + 5*(tintIndex - 3));
    } else if (numPanes == NumPanes.N2) {
      paneAdj = paneThickness == PaneThickness.EighthInch ? -6 : 0;
      if (glazing == Glazing.None ||
          glazing == Glazing.E0_6) {
        subSecRow1 = 6;
        let tintIndexMap = {
          [Tint.Clear]: 0,
          [Tint.Bronze]: 1,
          [Tint.Green]: 2,
          [Tint.Grey]: 3,
          [Tint.BlueGreen]: 4,
          [Tint.HighPerfGreen]: 5,
          [Tint.StainlessSteelOnClear8p]: 6,
          [Tint.StainlessSteelOnClear14p]: 7,
          [Tint.StainlessSteelOnClear20p]: 8,
          [Tint.StainlessSteelOnGreen14p]: 9,
          [Tint.TitaniumOnClear20p]: 10,
          [Tint.TitaniumOnClear30p]: 11,
        }
        let tintIndex = lookupData(tintIndexMap, [tint])
        subSecRow2 = tintIndex <= 3 ? 12 * tintIndex : (36 + 6 * (tintIndex - 3));
      } else if (glazing == Glazing.E0_4 || glazing == Glazing.E0_2) {
        subSecRow1 = 97;
        if (panesAppliedTo == PanesAppliedTo.Surface2) {
          subSecRow2 = 6;
        } else if (panesAppliedTo == PanesAppliedTo.Surface3) {
          let tintIndexMap = {
            [Tint.Clear]: 0,
            [Tint.Bronze]: 1,
            [Tint.Green]: 2,
            [Tint.Grey]: 3,
            [Tint.BlueGreen]: 4,
            [Tint.HighPerfGreen]: 5,
          }
          let tintIndex = lookupData(tintIndexMap, [tint])
          subSecRow2 = tintIndex <= 3 ? (19 + 12*tintIndex) : (55 + 6*(tintIndex - 3));
        } else {
          throw new Error(`Unexpected panesAppliedTo: ${panesAppliedTo}`);
        }
      } else if (glazing == Glazing.E0_1) {
        subSecRow1 = 171;
        if (panesAppliedTo == PanesAppliedTo.Surface2) {
          subSecRow2 = 6;
        } else if (panesAppliedTo == PanesAppliedTo.Surface3) {
          let tintIndexMap = {
            [Tint.Clear]: 0,
            [Tint.Bronze]: 1,
            [Tint.Green]: 2,
            [Tint.Grey]: 3,
            [Tint.BlueGreen]: 4,
            [Tint.HighPerfGreen]: 5,
          }
          let tintIndex = lookupData(tintIndexMap, [tint])
          subSecRow2 = tintIndex <= 3 ? (19 + 12*tintIndex) : (55 + 6*(tintIndex - 3));
        } else {
          throw new Error("Unexpected panesAppliedTo");
        }
      } else if (glazing == Glazing.E0_05) {
        subSecRow1 = 251;
        let tintIndexMap = {
          [Tint.Clear]: 0,
          [Tint.Bronze]: 1,
          [Tint.Green]: 2,
          [Tint.Grey]: 3,
          [Tint.BlueGreen]: 4,
          [Tint.HighPerfGreen]: 5,
        }
        let tintIndex = lookupData(tintIndexMap, [tint])
        subSecRow2 = 6*tintIndex
        if (tint != Tint.Clear) {
          paneAdj = 0;
        }
      } else {
        throw new Error(`Unknown glazing : ${glazing}`);
      }
    } else {
      // NumPanes is N3 or N4
      paneAdj = paneThickness == PaneThickness.EighthInch ? -7 : 0;
      if (glazing == Glazing.None ||
        glazing == Glazing.E0_6) {
        subSecRow1 = 0;
        subSecRow2 = tint == Tint.Clear ? 7 : 14;
      } else if (glazing == Glazing.E0_4 || glazing == Glazing.E0_2) {
        if (panesAppliedTo == PanesAppliedTo.Surface2or3) {
          subSecRow1 = 29;
          subSecRow2 = 0;
        } else if (panesAppliedTo == PanesAppliedTo.Surface4or5) {
          subSecRow1 = 44;
          subSecRow2 = 0;
        } else {
          subSecRow1 = 44;
          subSecRow2 = 0;
        }
      } else if (glazing == Glazing.E0_1) {
        subSecRow1 = 59;
        subSecRow2 = 0;
      } else {
        subSecRow1 = 74;
        subSecRow2 = 0;
      }
    }
    if (subSecRow1 == null || !subSecRow2 == null) {
      throw new Error(`subSecRow1 or subSecRow2 is null: ${subSecRow1}, ${subSecRow2}`);
    }
    /*
    console.log(`Row lookup: `, {
      sectionRow: sectionRow,
      subSecRow1: subSecRow1,
      subSecRow2: subSecRow2,
      paneAdj: paneAdj
    });
    */

    let shgcValuesRow = sectionRow + subSecRow1 + subSecRow2 + paneAdj
    return {
      row: shgcValuesRow,
      debugVals: {
        sectionRow,
        subSecRow1,
        subSecRow2,
        paneAdj
      }
    };
  }

  static _lookupShgcs(windowsData, shgcValuesRow, shgcTotalWindowCol) {
    let shgcCols = {
      Deg0: 'H',
      Deg40: 'I',
      Deg50: 'J',
      Deg60: 'K',
      Deg70: 'L',
      Deg80: 'M',
      Diffuse: 'N',
      TotalWindow: shgcTotalWindowCol,
      shgc: shgcTotalWindowCol,
    }
    let shgcs = {};
    for (const key in shgcCols) {
      shgcs[key] = windowsData.shgcValues.lookupValue(shgcValuesRow, shgcCols[key]);
    }
    // Also save the glazingId (used later)
    shgcs.glazingId = windowsData.shgcValues.lookupValue(shgcValuesRow, 'A', String)
    return shgcs;
  }

  static computeShgc(inputData) {
    let width = inputData.width; // ft
    let height = inputData.height; // ft
    let frameWidth = inputData.frameWidth; // ft
    let numPanes = inputData.numPanes;
    let glazing = inputData.glazing;
    let panesAppliedTo = inputData.panesAppliedTo;
    let gapType = inputData.gapType;
    let paneThickness = inputData.paneThickness;
    let tint = inputData.tint;
    let windowStyle = inputData.windowStyle;
    let frameStyle = inputData.frameStyle;
    let windowsData = inputData.windowsData;
    let isSkylight = valOr(inputData.isSkylight, false);
    let curbHeight = valOr(inputData.curbHeight, 0);

    if (width == 0 || height == 0) {
      console.log("W or H is 0. Returning SHGC=0");
      return {
        shgc: 0,
      }
    }

    let debugVals = {};
    let shgcValuesRowRes = WindowType._getShgcValuesRow(numPanes, panesAppliedTo, paneThickness, tint, glazing)
    let shgcValuesRow = shgcValuesRowRes.row;
    let shgcTotalCol = WindowType._getTotalWindowShgcCol(frameStyle, windowStyle);
    // console.log(`SHGC row, SHGC_T col: ${shgcValuesRow}, ${shgcTotalCol}`);
    let shgcs = WindowType._lookupShgcs(windowsData, shgcValuesRow, shgcTotalCol);
    // console.log(`SHGC_T: ${shgcs.TotalWindow}, SHGC_0deg: ${shgcs.Deg0}, GlazingId: ${shgcs.glazingId}`);

    extendMap(debugVals, {
      rowInfo: shgcValuesRowRes,
      shgcTotalCol,
    });

    // Calculate SHGC of frame, and use to revise SHGC_Total
    // SHGC_F = SHGC_T * (Hs * Ws) / Afs - SHGC_0deg * (Hs * Ws - Afs) / Afs
    // SHGC_T_NEW = (SHGC_0deg * (Ac + Ae) + SHGC_F * Af) / (Ac  + Ae + Af)
    let uValueOutputs = WindowType.computeUValue({
      width: width,
      height: height,
      frameWidth: frameWidth,
      numPanes: numPanes,
      glazing: glazing,
      panesAppliedTo: panesAppliedTo,
      gapType: gapType,
      windowStyle: windowStyle,
      frameStyle: frameStyle,
      windowsData: windowsData,
      isSkylight,
      curbHeight,
    });
    let SHGC_T = shgcs.TotalWindow;
    let Hs = uValueOutputs.Hs;
    let Ws = uValueOutputs.Ws;
    let Afs = uValueOutputs.Afs;
    let Ac = uValueOutputs.Ac;
    let Ae = uValueOutputs.Ae;
    let Af_flat = uValueOutputs.Af_flat;
    let Af = uValueOutputs.Af;
    let SHGC_0deg = shgcs.Deg0;
    let SHGC_F = SHGC_T * (Hs * Ws) / Afs - SHGC_0deg * (Hs * Ws - Afs) / Afs
    let SHGC_T_NEW = (SHGC_0deg * (Ac + Ae) + SHGC_F * Af) / (Ac  + Ae + Af)
    let multiplier = SHGC_T_NEW / SHGC_0deg;

    debugVals.multiplier = multiplier;
    debugVals.shgcTOrig = SHGC_T;
    debugVals.shgcTNew = SHGC_T_NEW;

    // Update all SHGC values
    shgcs.Deg0 *= multiplier;
    shgcs.Deg40 *= multiplier;
    shgcs.Deg50 *= multiplier;
    shgcs.Deg60 *= multiplier;
    shgcs.Deg70 *= multiplier;
    shgcs.Deg80 *= multiplier;
    shgcs.Diffuse *= multiplier;
    shgcs.TotalWindow = SHGC_T_NEW;
    shgcs.shgc = shgcs.TotalWindow;

    return {
      ...shgcs,
      debugVals
    };
  }

  computeUValue() {
    if (this.inputType.value == WindowInputType.Manual) {
      return {
        uValue: this.manualUValue.value
      };
    } else {
      let builder = this.windowBuilder;
      let inputData = {
        width: this.width.getValueInUnits(Units.ft),
        height: this.height.getValueInUnits(Units.ft),
        frameWidth: builder.frameWidth.getValueInUnits(Units.ft),
        numPanes: builder.numPanes.value,
        glazing: builder.glazing.value,
        panesAppliedTo: builder.panesAppliedTo.value,
        gapType: builder.gapType.value,
        windowStyle: this.style.value,
        frameStyle: builder.frameStyle.value,
        windowsData: gApp.proj().windowsData,
      }
      return WindowType.computeUValue(inputData);
    }
  }

  computeShgc() {
    if (this.inputType.value == WindowInputType.Manual) {
      return WindowType._estimateShgcs(this.manualShgc.value,
        this.manualUValue.value);
    } else {
      let builder = this.windowBuilder;
      let inputData = {
        width: this.width.getValueInUnits(Units.ft),
        height: this.height.getValueInUnits(Units.ft),
        frameWidth: builder.frameWidth.getValueInUnits(Units.ft),
        numPanes: builder.numPanes.value,
        glazing: builder.glazing.value,
        panesAppliedTo: builder.panesAppliedTo.value,
        gapType: builder.gapType.value,
        paneThickness: builder.paneThickness.value,
        tint: builder.tint.value,
        windowStyle: this.style.value,
        frameStyle: builder.frameStyle.value,
        windowsData: gApp.proj().windowsData,
      }
      return WindowType.computeShgc(inputData);
    }
  }

  static _computeFabricCurtainIACData(winType, inShading) {
    let section = 3;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;

    let openness = inShading.fabricCurtainGroup.getField('openness');
    let opennessDict = {
      [CurtainOpenness.Open]: 6,
      [CurtainOpenness.SemiOpen]: 3,
      [CurtainOpenness.Closed]: 0,
    };
    detail1 = lookupData(opennessDict, [openness.value]);

    let colour = inShading.fabricCurtainGroup.getField('colour').value;
    let colourDict = {
      [CurtainColour.Light]: 2,
      [CurtainColour.Dark]: 0,
      [CurtainColour.Medium]: 1,
    }
    detail2 = lookupData(colourDict, [colour])
    
    let builder = winType.getWindowBuilderIfUsing();
    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 11,
          [Glazing.E0_6]: 11,
          [Glazing.E0_4]: 22,
          [Glazing.E0_2]: 22,
          [Glazing.E0_1]: 33,
          [Glazing.E0_05]: 44,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 44,
          _ELSE: 55,
        },
        [NumPanes.N4]: {
          [Glazing.None]: 44,
          _ELSE: 55,
        }
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value
      ])
    }

    return {section, detail1, detail2, detail3, winRow};
  }

  static _computeLouveredShadesIACData(winType, inShading) {
    let section = 73;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;

    let detail1Dict = {
      [NumPanes.N1]: {
        [SlatsLocation.IndoorSide]: 0,
        [SlatsLocation.BetweenGlazing]: 0,
        [SlatsLocation.OutdoorSide]: 30,
      },
      // For panes 2-4, or if manual input type
      _ELSE: {
        [SlatsLocation.IndoorSide]: 0,
        [SlatsLocation.BetweenGlazing]: 30,
        [SlatsLocation.OutdoorSide]: 60,
      }
    };
    let slatsLot = inShading.louveredShadesGroup.getField('location').value;
    let builder = winType.getWindowBuilderIfUsing();
    let numPanes = builder ? builder.numPanes.value : 'Unknown';
    detail1 = lookupData(detail1Dict, [numPanes, slatsLot]);

    let detail2Dict = {
      [ShadeReflectiveness.R0_15]: 0,
      [ShadeReflectiveness.R0_5]: 10,
      [ShadeReflectiveness.R0_8]: 20,
    }
    let reflectiveness = inShading.louveredShadesGroup.getField('reflectiveness').value;
    detail2 = lookupData(detail2Dict, [reflectiveness]);

    let detail3Dict = {
      [LouverAngle.Unknown]: 0,
      [LouverAngle.ZeroDeg]: 2,
      [LouverAngle.TrackSolarAngle]: 4,
      [LouverAngle.FortyFiveDeg]: 6,
      [LouverAngle.FullyClosed]: 8,
    }
    let louverAngle = inShading.louveredShadesGroup.getField('louverAngle').value;
    detail3 = lookupData(detail3Dict, [louverAngle]);

    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 65,
          [Glazing.E0_6]: 65,
          [Glazing.E0_4]: 158,
          [Glazing.E0_2]: 158,
          [Glazing.E0_1]: 251,
          [Glazing.E0_05]: 344,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 344,
          _ELSE: 437,
        },
        [NumPanes.N4]: {
          _ELSE: 437,
        },
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value,
      ])
    }

    return {section, detail1, detail2, detail3, winRow};
  }

  static _computeRollerShadesIACData(winType, inShading) {
    let section = 604;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;

    let detail1Dict = {
      [RollerShadesDetail.Opaque]: {
        [RollerShadesColour.Light]: 1,
        [RollerShadesColour.LightGrey]: 3,
        [RollerShadesColour.DarkGrey]: 2,
        [RollerShadesColour.ReflectiveWhite]: 5,
      },
      [RollerShadesDetail.Translucent]: {
        [RollerShadesColour.Light]: 0,
        [RollerShadesColour.LightGrey]: 3,
        [RollerShadesColour.DarkGrey]: 4,
        [RollerShadesColour.ReflectiveWhite]: 6,
      },
    }
    // TODO see note about bugScreen or screen door
    detail1 = lookupData(detail1Dict, [
      inShading.rollerShadesGroup.getField('detail').value,
      inShading.rollerShadesGroup.getField('colour').value,
    ])

    let builder = winType.getWindowBuilderIfUsing();
    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 10,
          [Glazing.E0_6]: 10,
          [Glazing.E0_4]: 20,
          [Glazing.E0_2]: 20,
          [Glazing.E0_1]: 30,
          [Glazing.E0_05]: 40,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 40,
          _ELSE: 50,
        },
        [NumPanes.N4]: {
          _ELSE: 50,
        },
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value,
      ])
    }

    return {section, detail1, detail2, detail3, winRow};
  }

  static _getIACColFromGlazingId(glazingId) {
    // TODO - figure out how to do Matches here
    // Maybe '_Matches_...' string, with a _Values item.
    let colDict = {
      [1]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        _ELSE: 'L',
      },
      [5]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        _ELSE: 'L',
      },
      [17]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        j: 'M',
        k: 'N',
        _ELSE: 'N',
      },
      [21]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        j: 'M',
        k: 'N',
        _ELSE: 'N',
      },
      [25]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        _ELSE: 'I',
      },
      [29]: {
        a: 'J',
        b: 'K',
        _ELSE: 'K',
      },
      [32]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        _ELSE: 'G',
      },
      [40]: {
        a: 'H',
        b: 'I',
        c: 'J',
        d: 'K',
        _ELSE: 'K',
      },
    };
    return lookupData(colDict, [glazingId.num, glazingId.letter])
  }

  static _computeManualEntryIdNum(winType) {
    let idNum = null;
    let uValueData = winType.computeUValue();
    // For doors, which have uValueGlass and uValueDoor, use uValueGlass. For windows, we
    // only have 'uValue'.
    let uValue = ('uValueGlass' in uValueData) ? uValueData.uValueGlass : uValueData.uValue;
    let isResidential = true;
    if (winType.getWindowStyle() == WindowStyle.Operable) {
      if (isResidential) {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.5) {
          idNum = 5;
        } else if (uValue > 0.45) {
          idNum = 17;
        } else {
          idNum = 40;
        }
      } else {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.6) {
          idNum = 5;
        } else if (uValue > 0.55) {
          idNum = 17;
        } else {
          idNum = 40;
        }
      }
    } else {
      if (isResidential) {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.6) {
          idNum = 5;
        } else if (uValue > 0.55) {
          idNum = 17;
        } else if (uValue > 0.5) {
          idNum = 21;
        } else {
          idNum = 32;
        }
      } else {
        if (uValue > 1) {
          idNum = 1;
        } else if (uValue > 0.75) {
          idNum = 5;
        } else if (uValue > 0.7) {
          idNum = 17;
        } else if (uValue > 0.6) {
          idNum = 21;
        } else {
          idNum = 32;
        }
      }
    }
    return idNum;
  }

  static _computeWinRowFromIdNum(idNum, inShading) {
    let idNumToWinRowDict = {
      [InteriorShadeType.FabricCurtain]: {
        1: 0,
        5: 11,
        17: 22,
        21: 33,
        25: 44,
        29: 44,
        32: 55,
        40: 55,
      },
      [InteriorShadeType.LouveredShades]: {
        1: 0,
        5: 65,
        17: 158,
        21: 251,
        25: 354,
        29: 354,
        32: 437,
        40: 437,
      },
      [InteriorShadeType.RollerShades]: {
        1: 0,
        5: 10,
        17: 20,
        21: 30,
        25: 40,
        29: 40,
        32: 50,
        40: 50,
      },
    };
    return lookupData(idNumToWinRowDict, [
      inShading.type.value,
      idNum,
    ])
  }

  static _computeColFromIdNum(idNum) {
    let idNumToColDict = {
      1: 'P',
      5: 'P',
      17: 'P',
      21: 'P',
      25: 'P',
      32: 'P',
      29: 'S',
      40: 'S',
    }
    return lookupData(idNumToColDict, [idNum])
  }

  static _parseGlazingIdStr(glazingIdStr) {
    const regex = /^(\d+)([a-zA-Z])$/;
    const match = glazingIdStr.match(regex);
    if (match) {
      const num = parseInt(match[1]);
      const letter = match[2];
      return { num, letter };
    } else {
      throw new Error("Unexpected GlazingId: " + glazingIdStr);
    }
  }

  // See IAC values docs
  // Note: winType may be a WindowType or DoorType (DoorType implements the same interface used for glass doors)
  static computeIAC(winType, inShading, iacValues) {
    let result = {};

    let iacData = null;
    if (inShading.type.value == InteriorShadeType.FabricCurtain) {
      iacData = this._computeFabricCurtainIACData(winType, inShading);
    } else if (inShading.type.value == InteriorShadeType.LouveredShades) {
      iacData = this._computeLouveredShadesIACData(winType, inShading);
    } else if (inShading.type.value == InteriorShadeType.RollerShades) {
      iacData = this._computeRollerShadesIACData(winType, inShading);
    } else {
      throw new Error("Unknown interior shading type: ", inShading.type.value);
    }
    result.iacData = iacData;

    // Set col (and winRow for the manual entry case)
    let shgc = winType.computeShgc();
    if ('glazingId' in shgc) {
      let glazingIdStr = shgc.glazingId;
      if (!glazingIdStr) {
        throw new Error(`Unexpected GlazingId: ${glazingIdStr}`);
      }
      iacData.glazingId = this._parseGlazingIdStr(glazingIdStr);
      console.log("GlazingID: ", iacData.glazingId);
      iacData.col = this._getIACColFromGlazingId(iacData.glazingId);
    } else {
      iacData.idNum = this._computeManualEntryIdNum(winType);
      iacData.winRow = this._computeWinRowFromIdNum(iacData.idNum, inShading);
      iacData.col = this._computeColFromIdNum(iacData.idNum);
    }

    let row = iacData.section + iacData.detail1 + iacData.detail2 + iacData.detail3
      + iacData.winRow;
    let col = iacData.col;
    if (row == null || col == null) {
      throw Error("Unexpected: IAC row or col is null");
    }
    result.row = row;
    result.col = col;

    let iacStr = iacValues.lookupValue(row, col, String);
    if (iacStr.includes(', ')) {
      result.iac = StrictParseNumber(iacStr.split(', ')[0]);
    } else if (iacStr.includes('/')) {
      // Ex. '0.93 (0.05)/0.41'
      let parts = iacStr.split(' ');
      result.iac0 = StrictParseNumber(parts[0]);
      // Ignore other parts for now.
      result.iac = result.iac0;
    } else {
      result.iac = StrictParseNumber(iacStr);
    }

    return result;
  }

  exportAsTestCase() {
    let inputData = ser.writeToJson(this);
    let dataStr = prettyJson({
      input: inputData,
      output: {
        uValue: this.computeUValue(),
        shgc: this.computeShgc(),
      }
    });
    console.log("Exporting test case:\n", dataStr);
    downloadTextFile(dataStr, "WindowTestCase.js");
  }
};
setupClass(WindowType);

export let InteriorShadeType = makeEnum({
  FabricCurtain: 'Fabric Curtain',
  LouveredShades: 'Louvered Shades',
  RollerShades: 'Roller Shades',
})

export let CurtainOpenness = makeEnum({
  Open: 'Open',
  SemiOpen: 'Semi-open',
  Closed: 'Closed',
})

export let CurtainColour = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Dark: 'Dark',
})

export let SlatsLocation = makeEnum({
  IndoorSide: 'Indoor side',
  BetweenGlazing: 'Between glazing',
  OutdoorSide: 'Outdoor side',
})

export let ShadeReflectiveness = makeEnum({
  R0_15: '0.15',
  R0_5: '0.5',
  R0_8: '0.8',
})

export let RollerShadesDetail = makeEnum({
  Opaque: 'Opaque',
  Translucent: 'Translucent',
})

export let RollerShadesColour = makeEnum({
  Light: 'Light',
  LightGrey: 'Light grey',
  DarkGrey: 'Dark grey',
  ReflectiveWhite: 'Reflective white',
})

export let ShadesOrientation = makeEnum({
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
})

export let LouverAngle = makeEnum({
  Unknown: 'Unknown',
  ZeroDeg: '0°',
  TrackSolarAngle: 'Track solar angle',
  FortyFiveDeg: '45°',
  FullyClosed: 'Fully closed',
})

export class InteriorShadingType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('InteriorShadingType') : 0;

    this.name = Field.makeName('Name', name)
    this.type = new Field({
      name: 'Type',
      type: FieldType.Select,
      choices: makeOptions(InteriorShadeType),
      bold: true,
    })

    this.fabricCurtainGroup = new FieldGroup([
      new Field({
        key: 'openness',
        name: 'Openness',
        type: FieldType.Select,
        choices: makeOptions(CurtainOpenness)
      }),
      new Field({
        key: 'colour',
        name: 'Colour',
        type: FieldType.Select,
        choices: makeOptions(CurtainColour)
      }),
    ])
    this.fabricCurtainGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.FabricCurtain;
    })

    this.louveredShadesGroup = new FieldGroup([
      new Field({
        key: 'location',
        name: 'Location',
        type: FieldType.Select,
        choices: makeOptions(SlatsLocation)
      }),
      new Field({
        key: 'reflectiveness',
        name: 'Reflectiveness',
        type: FieldType.Select,
        choices: makeOptions(ShadeReflectiveness)
      }),
      Field.makeSelect('Louver angle', LouverAngle, {key: 'louverAngle'}),
    ])
    this.louveredShadesGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.LouveredShades;
    })

    this.rollerShadesGroup = new FieldGroup([
      new Field({
        key: 'detail',
        name: 'Visibility',
        type: FieldType.Select,
        choices: makeOptions(RollerShadesDetail)
      }),
      new Field({
        key: 'colour',
        name: 'Colour',
        type: FieldType.Select,
        choices: makeOptions(RollerShadesColour)
      })
    ])
    this.rollerShadesGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.RollerShades;
    })

    this.orientation = new Field({
      name: 'Orientation',
      type: FieldType.Select,
      choices: makeOptions(ShadesOrientation, [
        ShadesOrientation.Horizontal,
        ShadesOrientation.Vertical,
      ])
    })

    this.serFields = [
      'id',
      'name',
      'type',
      'fabricCurtainGroup',
      'louveredShadesGroup',
      'rollerShadesGroup',
      'orientation',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Interior Shading',
    }
  }

  getDetailsStr() {
    let details = this.getDetails();
    return `${details.detail1}, ${details.detail2}`
  }

  getDetails() {
    if (this.type.value == InteriorShadeType.FabricCurtain) {
      return {
        detail1: CurtainOpenness._labels[this.fabricCurtainGroup.getField('openness').value],
        detail2: CurtainColour._labels[this.fabricCurtainGroup.getField('colour').value],
      }
    } else if (this.type.value == InteriorShadeType.LouveredShades) {
      return {
        detail1: SlatsLocation._labels[this.louveredShadesGroup.getField('location').value],
        detail2: ShadeReflectiveness._labels[this.louveredShadesGroup.getField('reflectiveness').value],
      }
    } else if (this.type.value == InteriorShadeType.RollerShades) {
      return {
        detail1: RollerShadesDetail._labels[this.rollerShadesGroup.getField('detail').value],
        detail2: RollerShadesColour._labels[this.rollerShadesGroup.getField('colour').value],
      }
    } else {
      return {
        detail1: 'N/A',
        detail2: 'N/A',
      }
    }
  }

  getDetail2Str() {
  }
};
setupClass(InteriorShadingType)


export class ExteriorShadingType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('ExteriorShadingType') : 0;
    this.name = Field.makeName('Name', name)

    this.horizontalFinDepth = new Field({
      name: 'Horizontal fin depth',
      type: FieldType.Length,
    })
    this.horizontalFinDist = new Field({
      name: 'Horizontal fin distance from parallel edge',
      type: FieldType.Length,
    })

    this.leftFinDepth = new Field({
      name: 'Left fin depth',
      type: FieldType.Length,
    })
    this.leftFinDist = new Field({
      name: 'Left fin distance from parallel edge',
      type: FieldType.Length,
    })

    this.rightFinDepth = new Field({
      name: 'Right fin depth',
      type: FieldType.Length,
    })
    this.rightFinDist = new Field({
      name: 'Right fin distance from parallel edge',
      type: FieldType.Length,
    })

    this.fieldNames = [
      'horizontalFinDepth',
      'horizontalFinDist',
      'leftFinDepth',
      'leftFinDist',
      'rightFinDepth',
      'rightFinDist',
    ]
    this.serFields = [
      'id',
      'name',
      ...this.fieldNames,
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Exterior Shading',
    }
  }
};
setupClass(ExteriorShadingType)

export let YesNo = makeEnum({
  Yes: 'Yes',
  No: 'No',
})

export function makeYesNoField(fieldName) {
  // Defaults to Yes
  return Field.makeSelect(fieldName, YesNo)
}

export function makeNoYesField(fieldName, otherOpts) {
  // Shows No first in the list (and defaults to No)
  return new Field({
    name: fieldName,
    type: FieldType.Select,
    choices: makeOptions(YesNo, [YesNo.No, YesNo.Yes]),
    ...otherOpts,
  })
}

export class BasicObject {
  init(uiName, serFields) {
    this.serFields = serFields || [];

    this.childObjs = '$auto'
    this.objInfo = {
      '_name': uiName || null,
    }
  }
}

export let ScreenDoorTypes = makeEnum({
  None: 'None',
  Inside: 'Inside',
  Outside: 'Outside',
})

export let InstallationSealing = makeEnum({
  Tight: 'Tight',
  Average: 'Average',
  Loose: 'Loose',
})

export let DoorColor = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Dark: 'Dark',
})

export let DoorInputType = makeEnum({
  Manual: 'Manual',
  BuildDoor: 'Build door',
})

export let DoorBuildType = makeEnum({
  SwingingDoor: 'Swinging door',
  SlidingGlassDoor: 'Sliding glass door',
  StileAndRail: 'Stile and rail',
  RevolvingDoor: 'Revolving door',
  SteelEmergencyExitDoor: 'Steel emergency exit door',
  SteelSectional: 'Steel section / Tilt-up',
  AircraftHangarDoor: 'Aircraft hangar door',
});

export let SwingingDoorType = makeEnum({
  WoodSlabInWoodFrame: 'Wood slab in wood frame',
  InsulatedSteelSlabWithWoodEdgeInWoodFrame: 'Insulated steel slab with wood edge in wood frame',
  InsulatedSteelSlabWithMetalEdgeInMetalFrame: 'Insulated steel slab with metal edge in metal frame',
  CardboardHoneycombSlab: 'Cardboard honeycomb slab with metal edge in metal frame',
})

export let DoorGlassType = makeEnum({
  SinglePane: 'Single-pane',
  DoublePane: 'Double-pane',
  DoublePaneLowE: 'Double-pane, low-e',
})

export class SwingingDoor {
  init() {
    let door = this;
    this.type = Field.makeSelect('Type', SwingingDoorType)
    this.percentGlazing = new Field({
      name: '% Glass',
      type: FieldType.Percent,
    })
    this.glassType = Field.makeSelect('Glass type', DoorGlassType)
    this.glassType.makeUpdater((field) => {
      field.visible = door.percentGlazing.value > 0;
    })
    this.thermalBreak = Field.makeSelect('Thermal break', YesNo)

    this.fields = [
      'type',
      'percentGlazing',
      'glassType',
      'thermalBreak',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Swinging Door',
    }
  }
}
setupClass(SwingingDoor)

export let AluminumDoorFrameType = makeEnum({
  AluminumWithoutThermalBreak: 'Aluminum without thermal break',
  AluminumWithThermalBreak: 'Aluminum with thermal break',
})

export class SlidingGlassDoor {
  init() {
    this.windowBuilder = WindowBuilder.create(this, {isSlidingGlassDoor: true});

    this.serFields = [
      'windowBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Sliding Glass Door',
    }
  }
}
setupClass(SlidingGlassDoor)

export class StileAndRailDoor {
  init() {
    let door = this;
    this.frameType = Field.makeSelect('Door frame type', AluminumDoorFrameType)
    this.percentGlazing = new Field({
      name: '% Glazing',
      type: FieldType.Percent,
    })
    this.glassType = Field.makeSelect('Glass type', DoorGlassType)
    this.glassType.makeUpdater((field) => {
      field.visible = door.percentGlazing.value > 0;
    })
    // this.thermalBreak = Field.makeSelect('Thermal break', YesNo)

    this.fields = [
      'frameType',
      'percentGlazing',
      'glassType',
      // 'thermalBreak',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Stile and Rail Door',
    }
  }
}
setupClass(StileAndRailDoor)

export let RevolvingDoorType = makeEnum({
  ThreeWing: '3-wing',
  FourWing: '4-wing',
})

export let RevolvingDoorSize = makeEnum({
  n8by7: '8ft x 7ft',
  n10by8: '10ft x 8ft',
  n7by6p5: '7ft x 6.5ft',
  n7by7p5: '7ft by 7.5ft',
})

export class RevolvingDoor {
  init() {
    let door = this;
    this.type = Field.makeSelect('Type', RevolvingDoorType)
    this.size = Field.makeSelect('Size', RevolvingDoorSize);
    this.size.makeChoicesUpdater(() => {
      if (door.type.value == RevolvingDoorType.ThreeWing) {
        return makeOptions(RevolvingDoorSize, [
          RevolvingDoorSize.n8by7,
          RevolvingDoorSize.n10by8,
        ])
      } else if (door.type.value == RevolvingDoorType.FourWing) {
        return makeOptions(RevolvingDoorSize, [
          RevolvingDoorSize.n7by6p5,
          RevolvingDoorSize.n7by7p5,
        ])
      }
      throw new Error("Uknown revolving door type");
    })

    this.fields = [
      'type',
      'size',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'RevolvingDoor',
    }
  }
}
setupClass(RevolvingDoor)

export let SteelEmergExitInsulation = makeEnum({
  HoneycombKraftPaper: 'Honeycomb kraft paper',
  MineralWoolWithSteelRibs: 'Mineral wool with steel ribs',
  PolyurethaneFoam: 'Polyurethane foam',
})

export let SteelEmergExitInsulationThickness = makeEnum({
  One3f8: '1 3/8"',
  One3f4: '1 3/4"',
})

let SteelEmergExitNumDoors = makeEnum({
  Single: 'Single',
  Double: 'Double',
})

export class SteelEmergencyExitDoor {
  init() {
    this.insulation = Field.makeSelect('Insulation', SteelEmergExitInsulation)
    this.insulationThickness = Field.makeSelect('Insulation Thickness',
      SteelEmergExitInsulationThickness)
    this.thermalBreak = Field.makeSelect('Thermal break', YesNo)
    this.numDoors = Field.makeSelect('Number of doors', SteelEmergExitNumDoors)

    this.fields = [
      'insulation',
      'insulationThickness',
      'thermalBreak',
      'numDoors',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Steel Emergency Exit Door',
    }
  }
}
setupClass(SteelEmergencyExitDoor)

export let SteelSectionalInsulationType = makeEnum({
  PolyurethaneThermallyBroken: 'Polyurethane, thermally broken',
  ExtrudedPolystreneWithSteelRibs: 'Extruded polystrene with steel ribs',
  ExpandedPolystreneWithSteelRibs: 'Expanded polystrene with steel ribs',
})

export let SteelSectionalInsulationThickness = makeEnum({
  One_3f4: '1 3/4"',
  One_3f8: '1 3/8"',
  Two: '2"',
  Three: '3"',
  Four: '4"',
  Six: '6"',
})

export class SteelSectionalDoor {
  init() {
    let door = this;
    this.insulationType = Field.makeSelect('Insulation type', SteelSectionalInsulationType)
    this.insulationThickness = Field.makeSelect('Insulation thickness', SteelSectionalInsulationThickness)
    this.insulationThickness.makeChoicesUpdater(() => {
      if (door.insulationType.value == SteelSectionalInsulationType.PolyurethaneThermallyBroken) {
        return makeOptions(SteelSectionalInsulationThickness, [
          SteelSectionalInsulationThickness.One_3f4,
        ]);
      } else {
        return makeOptions(SteelSectionalInsulationThickness, [
          SteelSectionalInsulationThickness.One_3f8,
          SteelSectionalInsulationThickness.Two,
          SteelSectionalInsulationThickness.Three,
          SteelSectionalInsulationThickness.Four,
          SteelSectionalInsulationThickness.Six,
        ])
      }
    })

    this.fields = [
      'insulationType',
      'insulationThickness',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Steel Sectional Door',
    }
  }
}
setupClass(SteelSectionalDoor)

export let AircraftHangarDoorInsulationType = makeEnum({
  ExpandedPolystrene: 'Expanded polystrene',
  MineralWoolWithSteelRibs: 'Mineral wool with steel ribs',
  ExtrudedPolystrene: 'Extruded polystrene',
  NoInsulation: 'No insulation',
})

export let AircraftHangarDoorInsulationThickness  = makeEnum({
  Four: '4"',
  Six: '6"',
})

export let AircraftHangarDoorApproxSize = makeEnum({
  n72by12: '72ft x 12ft (small private planes)',
  n240by50: '240ft x 50ft (commerical jet)',
})

export class AircraftHangarDoor {
  init() {
    let door = this;
    this.insulationType = Field.makeSelect('Insulation type', AircraftHangarDoorInsulationType)
    this.insulationThickness = Field.makeSelect('Insulation thickness', AircraftHangarDoorInsulationThickness)
    this.insulationThickness.makeChoicesUpdater(() => {
      if (door.insulationType.value == AircraftHangarDoorInsulationType.NoInsulation) {
        return [];
      } else {
        return makeOptions(AircraftHangarDoorInsulationThickness)
      }
    })
    this.approxSize = Field.makeSelect('Approximate size', AircraftHangarDoorApproxSize)

    this.fields = [
      'insulationType',
      'insulationThickness',
      'approxSize',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Aircraft Hangar Door',
    }
  }
}
setupClass(AircraftHangarDoor)

export class DoorBuilder {
  init() {
    // Note: for the residential app, only swinging door and sliding glass door
    // will be available
    let choices = []
    if (gApp.proj().isResidential()) {
      choices = makeOptions(DoorBuildType, [DoorBuildType.SwingingDoor, DoorBuildType.SlidingGlassDoor])
    } else {
      choices = makeOptions(DoorBuildType)
    }
    this.type = new Field({
      name: 'Door type',
      type: FieldType.Select,
      choices: choices
    })
    
    this.swingingDoor = SwingingDoor.create();
    this.slidingGlassDoor = SlidingGlassDoor.create();
    this.stileAndRailDoor = StileAndRailDoor.create();
    this.revolvingDoor = RevolvingDoor.create()
    this.steelEmergencyExitDoor = SteelEmergencyExitDoor.create();
    this.steelSectionalDoor = SteelSectionalDoor.create();
    this.aircraftHangarDoor = AircraftHangarDoor.create();

    let objMap = {
      [DoorBuildType.SwingingDoor]: this.swingingDoor,
      [DoorBuildType.SlidingGlassDoor]: this.slidingGlassDoor,
      [DoorBuildType.StileAndRailDoor]: this.stileAndRailDoor,
      [DoorBuildType.RevolvingDoor]: this.revolvingDoor,
      [DoorBuildType.SteelEmergencyExitDoor]: this.steelEmergencyExitDoor,
      [DoorBuildType.SteelSectional]: this.steelSectionalDoor,
      [DoorBuildType.AircraftHangarDoor]: this.aircraftHangarDoor,
    }
    ObjectUtils.setMapItemEnabled(objMap, () => {
      return this.type.value;
    })

    this.serFields = [
      'type',
      'swingingDoor',
      'slidingGlassDoor',
      'stileAndRailDoor',
      'revolvingDoor',
      'steelEmergencyExitDoor',
      'steelSectionalDoor',
      'aircraftHangarDoor',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door Builder',
    }
  }

  getWindowBuilderIfUsing() {
    if (this.type.value == DoorBuildType.SlidingGlassDoor) {
      return this.slidingGlassDoor.windowBuilder;
    }
    return null;
  }
}
setupClass(DoorBuilder)

export class DoorType {
  init(name, makeId) {
    let doorType = this;

    this.id = makeId ? gApp.proj().makeId('DoorType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Height',
      type: FieldType.Length,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
    })
    
    this.installationSealing = Field.makeSelect('Installation Sealing', InstallationSealing)
    this.addScreenDoor = Field.makeSelect('Screen door', ScreenDoorTypes)
    this.colour = Field.makeSelect('Door Colour', DoorColor)
    this.inputType = Field.makeSelect('Input Type', DoorInputType, {bold:true})

    // Manual inputs:
    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: kEpsilon,
    })
    this.percentGlass = new Field({
      name: 'Percent Glass',
      type: FieldType.Percent,
    })
    this.glassDoorShgc = new Field({
      name: 'Glass SHGC (total window)',
      type: FieldType.Ratio,
      min: kEpsilon,
    })
    this.glassDoorShgc.makeUpdater((field) => {
      field.visible = this.percentGlass.value > 0;
    })
    this.glassDoorUValue = new Field({
      name: 'Glass U-Value',
      type: FieldType.UValue,
      min: kEpsilon,
    })
    this.glassDoorUValue.makeUpdater((field) => {
      field.visible = this.percentGlass.value > 0;
    })
    this.manualFields = ['manualUValue', 'percentGlass', 'glassDoorShgc', 'glassDoorUValue']
    ObjectUtils.setFieldsEnabled(this, this.manualFields, () => {
      return this.inputType.value == DoorInputType.Manual;
    })

    this.doorBuilder = DoorBuilder.create()
    ObjectUtils.setEnabledWhen(this.doorBuilder, () => {
      return this.inputType.value == DoorInputType.BuildDoor;
    })

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = doorType.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
    })
    this.outputShgc.makeUpdater((field) => {
      let shgcs = doorType.computeShgc()
      field.value = shgcs.shgc;
      field.debugOutput = DebugOn() ? prettyJson(shgcs) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'installationSealing',
      'addScreenDoor',
      'colour',
      'inputType',
      'manualUValue',
      'percentGlass',
      'glassDoorShgc',
      'glassDoorUValue',
      'doorBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door Type',
    }
  }

  getArea() {
    return this.height.value * this.width.value;
  }

  getPerimeter() {
    return 2 * (this.height.value + this.width.value);
  }

  getOpaqueArea() {
    return this.getArea() * (1 - this.getPercentGlass() / 100);
  }

  getGlassArea() {
    return this.getArea() * (this.getPercentGlass() / 100);
  }

  hasScreenDoor() {
    return this.addScreenDoor.value != ScreenDoorTypes.None;
  }

  getWindowStyle() {
    // We assume that for doors with glass, the window-style is operable
    return WindowStyle.Operable;
  }

  getWindowBuilderIfUsing() {
    if (this.inputType.value == DoorInputType.BuildDoor) {
      return this.doorBuilder.getWindowBuilderIfUsing();
    }
    return null;
  }

  isManualEntry() {
    return this.inputType.value == DoorInputType.Manual;
  }

  getDoorTypeStr() {
    return this.isManualEntry() ? "N/A" : DoorBuildType._labels[this.doorBuilder.type.value];
  }

  // Percent by area, [0-100]
  getPercentGlass() {
    if (this.inputType.value == DoorInputType.Manual) {
      return this.percentGlass.value;
    } else {
      let type = this.doorBuilder.type.value;
      if (type == DoorBuildType.SwingingDoor) {
        return this.doorBuilder.swingingDoor.percentGlazing.value;
      } else if (type == DoorBuildType.SlidingGlassDoor) {
        return 100.0;
      } else if (type == DoorBuildType.StileAndRailDoor) {
        return this.doorBuilder.stileAndRailDoor.percentGlazing.value;
      } else {
        // Assume other types have no glass
        return 0;
      }
    }
  }

  computeUValue() {
    if (this.inputType.value == DoorInputType.Manual) {
      let percentGlass = this.percentGlass.value / 100.0;
      let uValueDoor = this.manualUValue.value;
      let uValueGlass = this.glassDoorUValue.value;
      let uValue = uValueDoor * (1 - percentGlass) + uValueGlass * percentGlass;
      return {uValue, uValueDoor, uValueGlass};
    } else {
      let builder = this.doorBuilder;
      let doorType = builder.type.value;
      if (doorType == DoorBuildType.SwingingDoor) {
        let door = builder.swingingDoor;
        let uValueDoor = lookupData(DoorsData, ['SlabDoor', door.type.value])
        let percentGlass = door.percentGlazing.value / 100.0;
        if (percentGlass == 0) {
          return {uValue: uValueDoor, uValueDoor};
        } else {
          // Note: these are row nums, not row ids
          let glassRowMap = {
            SinglePane: 2,
            DoublePane: 7,
            DoublePaneLowE: 24,
          };
          let glassRow = lookupData(glassRowMap, [door.glassType.value])

          let glassCol = null;
          let detailedDoorType = door.type.value;
          let thermalBreak = door.thermalBreak.value == YesNo.Yes;
          if (detailedDoorType == 'WoodSlabInWoodFrame') {
            glassCol = 'I';
          } else if (detailedDoorType == 'InsulatedSteelSlabWithWoodEdgeInWoodFrame') {
            glassCol = 'I';
          } else if (detailedDoorType == 'InsulatedSteelSlabWithMetalEdgeInMetalFrame' ||
            detailedDoorType == 'CardboardHoneycombSlab' || detailedDoorType == 'StileAndRailDoor') {
            if (thermalBreak) {
              glassCol = 'G';
            } else  {
              glassCol = 'F';
            }
          }
          if (!glassCol) {
            throw new Error("Unexpected: glassCol not found for door type: " + detailedDoorType);
          }

          let uValueGlass = gApp.proj().windowsData.uValues.lookupValue(glassRow, glassCol);
          console.log(`U-Value Door: ${uValueDoor}, U-Value Glass: ${uValueGlass}`);
          let uValue = uValueDoor * (1 - percentGlass) + uValueGlass * percentGlass;
          return {uValue, uValueDoor, uValueGlass, debug: {uValueDoor, uValueGlass, percentGlass, glassRow, glassCol}}
        }
      } else if (doorType == DoorBuildType.SlidingGlassDoor) {
        // Treat like a window type. WindowStyle is assumed to be Operable.
        let door = builder.slidingGlassDoor;
        let winBuilder = door.windowBuilder;

        // console.log("WinBuilder: ", winBuilder);
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: winBuilder.frameWidth.getValueInUnits(Units.ft),
          numPanes: winBuilder.numPanes.value,
          glazing: winBuilder.glazing.value,
          panesAppliedTo: winBuilder.panesAppliedTo.value,
          gapType: winBuilder.gapType.value,
          windowStyle: WindowStyle.Operable,
          frameStyle: winBuilder.frameStyle.value,
          windowsData: gApp.proj().windowsData,
        };
        let res = WindowType.computeUValue(inputData);
        return {...res, uValueGlass: res.uValue};
      } else if (doorType == DoorBuildType.StileAndRail) {
        let door = builder.stileAndRailDoor;
        let uValueDoor = lookupData(DoorsData, ['StileAndRail', door.frameType.value])
        let percentGlass = door.percentGlazing.value / 100.0;
        if (percentGlass == 0) {
          return {uValue: uValueDoor, uValueDoor};
        } else {
          // Note: these are row-nums, not ids
          let glassRowMap = {
            SinglePane: 2,
            DoublePane: 12,
            DoublePaneLowE: 29,
          };
          let glassRow = lookupData(glassRowMap, [door.glassType.value])

          let glassCol = null;
          let frameType = door.frameType.value;
          if (frameType == AluminumDoorFrameType.AluminumWithoutThermalBreak) {
            glassCol = 'F';
          } else if (frameType == AluminumDoorFrameType.AluminumWithThermalBreak) {
            glassCol = 'G';
          }
          if (!glassCol) {
            throw new Error("Unexpected: glassCol not found for frame type: " + frameType);
          }

          let uValueGlass = gApp.proj().windowsData.uValues.lookupValue(glassRow, glassCol);
          console.log(`U-Value Door: ${uValueDoor}, U-Value Glass: ${uValueGlass}`);
          let uValue = uValueDoor * (1 - percentGlass) + uValueGlass * percentGlass;
          return {uValue, uValueDoor, uValueGlass, debug: {uValueDoor, uValueGlass, percentGlass, glassRow, glassCol}}
        }
      } else if (doorType == DoorBuildType.RevolvingDoor) {
        let door = builder.revolvingDoor;
        let uValueDoor = lookupData(DoorsData,
          ['RevolvingDoor', door.type.value, door.size.value])
        return {uValue: uValueDoor, uValueDoor};
      } else if (doorType == DoorBuildType.SteelEmergencyExitDoor) {
        let door = builder.steelEmergencyExitDoor;
        let thermalBreakField = door.thermalBreak.value == YesNo.Yes ? 'WithThermalBreak' : 'WithoutThermalBreak';
        let uValueDoor = lookupData(DoorsData,
          ['SteelEmergencyExitDoor', thermalBreakField, door.insulationThickness.value,
          door.insulation.value, door.numDoors.value]);
        return {uValue: uValueDoor, uValueDoor};
      } else if (doorType == DoorBuildType.SteelSectional) {
        let door = builder.steelSectionalDoor;
        let uValueDoor = lookupData(DoorsData, [
          'SteelSectional',
          door.insulationThickness.value,
          door.insulationType.value,
        ]);
        return {uValue: uValueDoor, uValueDoor};
      } else if (doorType == DoorBuildType.AircraftHangarDoor) {
        let door = builder.aircraftHangarDoor;
        let uValueDoor = lookupData(DoorsData, [
          'AircraftHangarDoor',
          door.insulationThickness.value,
          door.insulationType.value,
          door.approxSize.value,
        ]);
        return {uValue: uValueDoor, uValueDoor};
      } else {
        throw new Error("Unknown door type: " + doorType);
      }
    }
  }

  computeShgc() {
    if (this.inputType.value == DoorInputType.Manual) {
      if (this.percentGlass.value > 0) {
        let uValue = this.computeUValue().uValue;
        let shgcs = WindowType._estimateShgcs(this.glassDoorShgc.value, uValue);
        return {...shgcs, isNA: false};
      } else {
        // NA
        return {shgc: 0, isNA: true};
      }
    } else {
      let rowsMap = {
        SinglePane: '1b',
        DoublePane: '5b',
        DoublePaneLowE: '17d',
      };
      let builder = this.doorBuilder;
      let doorType = builder.type.value;
      if (doorType == DoorBuildType.SwingingDoor) {
        let door = builder.swingingDoor;
        if (door.percentGlazing.value == 0) {
          // NA
          console.log("Percent glazing: 0");
          return {shgc: 0, isNA: true};
        }
        let colChar = null;
        let detailedDoorType = door.type.value;
        if (detailedDoorType == SwingingDoorType.WoodSlabInWoodFrame ||
            detailedDoorType == SwingingDoorType.InsulatedSteelSlabWithWoodEdgeInWoodFrame) {
          colChar = 'R';
        } else {
          colChar = 'P';
        }
        let rowId = lookupData(rowsMap, [door.glassType.value])
        console.log(`Looking up SHGC cell (${rowId}, ${colChar})`);
        let shgcs = WindowType._lookupShgcs(gApp.proj().windowsData, rowId, colChar);
        console.log(`SHGCs: ${prettyJson(shgcs)}`);
        return {...shgcs, isNA: false};
      } else if (doorType == DoorBuildType.StileAndRail) {
        let door = builder.stileAndRailDoor;
        if (door.percentGlazing.value == 0) {
          // NA
          return {shgc: 0, isNA: true};
        }
        let colChar = 'P';
        let rowId = lookupData(rowsMap, [door.glassType.value]);
        let shgcs = WindowType._lookupShgcs(gApp.proj().windowsData, rowId, colChar);
        return {...shgcs, isNA: false};
      } else if (doorType == DoorBuildType.SlidingGlassDoor) {
        // Do the same way as for windows. WindowStyle is assumed to be Operable.
        let door = builder.slidingGlassDoor;
        let winBuilder = door.windowBuilder;

        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: winBuilder.frameWidth.getValueInUnits(Units.ft),
          numPanes: winBuilder.numPanes.value,
          glazing: winBuilder.glazing.value,
          panesAppliedTo: winBuilder.panesAppliedTo.value,
          paneThickness: winBuilder.paneThickness.value,
          tint: winBuilder.tint.value,
          gapType: winBuilder.gapType.value,
          windowStyle: WindowStyle.Operable,
          frameStyle: winBuilder.frameStyle.value,
          windowsData: gApp.proj().windowsData,
        };
        return {...WindowType.computeShgc(inputData), isNA: false};
      } else {
        // NA
        return {shgc: 0, isNA: true};
      }
    }
  }
};
setupClass(DoorType);

export let SkylightStyle = makeEnum({
  ManufacturedSkylight: 'Manufactured skylight',
  SiteAssembledSlopedSlashOverheadGlazing: 'Site-assembled sloped/overhead glazing',
  SlopedCurtainWall: 'Sloped curtain wall (> 20° from horizontal)',
  DomedSkylight: 'Domed skylight',
  TubularDaylightingDevice: 'Tubular daylighting device',
})

export let SkylightInputType = makeEnum({
  Manual: 'Manual',
  BuildSkylight: 'Build skylight',
})

let DomedSkylightOpacity = makeEnum({
  Clear: 'Clear',
  ClearWithLightDiffuser: 'Clear with light diffuser',
  MildyTranslucent: 'Mildy translucent',
  VeryTranslucent: 'Very translucent',
})

let DomedSkylightGlazing = makeEnum({
  Single: 'Single',
  Double: 'Double',
  DoubleLowE: 'Double, low-e',
  Triple: 'Triple',
  TripleLowE: 'Triple, low-e',
})

let DomedSkylightShgcs = {
  [DomedSkylightOpacity.ClearWithLightDiffuser]: {
    [0]: 0.53,
    [9]: 0.5,
    [12]: 0.44,
  },
  [DomedSkylightOpacity.Clear]: {
    [0]: 0.86,
    [9]: 0.77,
    [12]: 0.7,
  },
  [DomedSkylightOpacity.MildyTranslucent]: {
    [0]: 0.5,
    [12]: 0.4,
  },
  [DomedSkylightOpacity.VeryTranslucent]: {
    [0]: 0.3,
    [9]: 0.26,
    [12]: 0.24,
  }
};

let TubularSkylightCollectorLayers = makeEnum({
  N1: '1',
  N2: '2',
})

let TubularSkylightDiffuserLayers = makeEnum({
  N1: '1',
  N2: '2',
  N3: '3',
})

let RoofInsulationLocation = makeEnum({
  BelowRoof: 'Below roof',
  AboveCeiling: 'Above ceiling',
})

let TubularSkylightData = {
  [TubularSkylightCollectorLayers.N1]: {
    [TubularSkylightDiffuserLayers.N1]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.62, shgc: 0.32},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.34, shgc: 0.34},
    },
    [TubularSkylightDiffuserLayers.N2]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.38, shgc: 0.28},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.34, shgc: 0.35},
    },
    [TubularSkylightDiffuserLayers.N3]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.27, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.33, shgc: 0.34},
    },
  },
  [TubularSkylightCollectorLayers.N2]: {
    [TubularSkylightDiffuserLayers.N1]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.62, shgc: 0.27},
      [RoofInsulationLocation.BelowRoof]: {uValue: 0.83, shgc: 0.32},
    },
    [TubularSkylightDiffuserLayers.N2]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.38, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 0.83, shgc: 0.38},
    },
    [TubularSkylightDiffuserLayers.N3]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.27, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.33, shgc: 0.34},
    },
  },
}

export class SkylightType {
  init(name, makeId) {
    let skylight = this;

    this.id = makeId ? gApp.proj().makeId('SkylightType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Length',
      type: FieldType.Length,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
    })
    this.curbHeight = new Field({
      name: 'Curb Height',
      type: FieldType.SmallLength,
      min: 0,
    })
    this.unusualShape = makeNoYesField('Non-rectangular shape', {visible: false})
    this.unusualShapeArea = new Field({
      name: 'Shape Area',
      type: FieldType.Area,
    })
    this.unusualShapePerimeter = new Field({
      name: 'Shape Perimeter',
      type: FieldType.Length,
    })
    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      let unusualShape = this.unusualShape.value == YesNo.Yes;
      this.height.visible = !unusualShape;
      this.width.visible = !unusualShape;
      this.unusualShapeArea.visible = unusualShape;
      this.unusualShapePerimeter.visible = unusualShape;
    })

    this.style = Field.makeSelect('Style', SkylightStyle)
    this.installationSealing = Field.makeSelect('Installation sealing',
      InstallationSealing)
    this.inputType = Field.makeSelect('Input type', SkylightInputType, {bold: true})

    this.curbHeight.makeUpdater((field) => {
      if (this.style.value == SkylightStyle.SlopedCurtainWall) {
        field.isOutput = true;
        field.value = 0;
      } else {
        field.isOutput = false;
      }
    })

    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: kEpsilon,
    })
    this.manualShgc = new Field({
      name: 'SHGC (total window)',
      type: FieldType.Ratio,
    })
    this.manualInputFields = ['manualUValue', 'manualShgc']
    ObjectUtils.setFieldsEnabled(this, this.manualInputFields, () => {
      return this.inputType.value == SkylightInputType.Manual;
    })

    this.domedSkylightInputs = new FieldGroup([
      Field.makeSelect('Dome Opacity', DomedSkylightOpacity,
        {key: 'domeOpacity'}),
      new Field({
        key: 'frameStyle',
        name: 'Frame Style',
        type: FieldType.Select,
        choices: makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
        ])
      }),
      Field.makeSelect('Glazing', DomedSkylightGlazing, {key: 'glazing'}),
    ])
    this.domedSkylightInputs.setVisibility(() => {
      return this.inputType.value == SkylightInputType.BuildSkylight &&
        this.style.value == SkylightStyle.DomedSkylight;
    })

    this.tubularSkylightInputs = new FieldGroup([
      Field.makeSelect('# of glazing layers on collector',
        TubularSkylightCollectorLayers, {key: 'collectorLayers'}),
      Field.makeSelect('# of glazing layers on diffuser',
        TubularSkylightDiffuserLayers, {key: 'diffuserLayers'}),
      Field.makeSelect('Approximate location of roof insulation',
        RoofInsulationLocation, {key: 'insulationLocation'}),
    ])
    this.tubularSkylightInputs.setVisibility(() => {
      return this.inputType.value == SkylightInputType.BuildSkylight &&
        this.style.value == SkylightStyle.TubularDaylightingDevice;
    })

    /*
    TODO - add notes to the user
    ***Note to the user: if unknown, select “Below roof”
    ***Note to the user: thermal performance will be greatly improved if the insulation is located above the ceiling.
    ***Note to the user: only one layer on the diffuser will result in poor thermal performance.
    */
    
    // Other styles: {
    let isOtherStyle = () => {
      return !elemIn(this.style.value, [
        SkylightStyle.DomedSkylight, SkylightStyle.TubularDaylightingDevice])
    }
    this.frameStyle = new Field({
      name: 'Frame Style',
      type: FieldType.Select,
      choices: makeOptions(FrameStyle, [
        FrameStyle.AluminumWithoutThermalBreak,
        FrameStyle.AluminumWithThermalBreak,
        FrameStyle.ReinforcedVinylSlashAluminumCladWood,
        FrameStyle.WoodSlashVinyl,
      ])
    })
    this.frameStyle.makeChoicesUpdater((field) => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.style.value == SkylightStyle.ManufacturedSkylight) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
        ])
      } else if (this.style.value == SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      } else if (this.style.value == SkylightStyle.SlopedCurtainWall) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      }
    })
    this.useDefaultFrameWidth = Field.makeSelect('Use default frame width', YesNo)
    this.frameWidth = new Field({
      name: 'Frame width',
      type: FieldType.SmallLength,
    })
    this.frameWidth.makeUpdater((field) => {
      if (!isOtherStyle()) {
        return;
      }
      if (this.useDefaultFrameWidth.value == YesNo.Yes) {
        field.isOutput = true;
        field.value = lookupData(WindowType._getStdFrameWidthMap(), [
          this.style.value,
          this.frameStyle.value,
        ])
      } else {
        field.isOutput = false;
      }
    })
    this.numPanes = Field.makeSelect('Number of panes', NumPanes)
    this.glazing = Field.makeSelect('Glazing', Glazing)
    this.glazing.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(Glazing, [Glazing.None])
      } else if (this.numPanes.value == NumPanes.N2) {
        return makeOptions(Glazing)
      } else {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ])
      }
    })
    this.panesAppliedTo = Field.makeSelect('Panes applied to', PanesAppliedTo)
    this.panesAppliedTo.makeChoicesUpdater((field) => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return [];
      } else if (this.numPanes.value == NumPanes.N2) {
        if (this.glazing.value == Glazing.None) {
          return [];
        } else if (elemIn(this.glazing.value, [Glazing.E0_6])) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3])
        } else {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2,
            PanesAppliedTo.Surface3,
          ])
        }
      } else {
        if (this.glazing.value == Glazing.None) {
          return [];
        } else if (this.glazing.value == Glazing.E0_2) {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3,
            PanesAppliedTo.Surface4or5,
            PanesAppliedTo.Surface2or3and4or5,
          ])
        } else {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3and4or5
          ])
        }
      }
    })
    this.tint = Field.makeSelect('Tint / reflective coating', Tint)
    this.tint.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return []
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(Tint, [
          Tint.Clear,
          Tint.Bronze,
          Tint.Green,
          Tint.Grey,
          Tint.BlueGreen,
          Tint.StainlessSteelOnClear8p,
          Tint.StainlessSteelOnClear14p,
          Tint.StainlessSteelOnGreen14p,
          Tint.StainlessSteelOnClear20p,
          Tint.TitaniumOnClear20p,
          Tint.TitaniumOnClear30p,
        ])
      } else if (this.numPanes.value == NumPanes.N2) {
        if (elemIn(this.glazing.value, [Glazing.None, Glazing.E0_6])) {
          return makeOptions(Tint)
        } else if (elemIn(this.glazing.value, [Glazing.E0_4, Glazing.E0_2, Glazing.E0_1])) {
          if (this.panesAppliedTo.value == PanesAppliedTo.Surface2) {
            return makeOptions(Tint, [Tint.Clear])
          } else {
            return makeOptions(Tint, [
              Tint.Clear,
              Tint.Bronze,
              Tint.Green,
              Tint.Grey,
              Tint.BlueGreen,
              Tint.HighPerfGreen,
            ])
          }
        } else if (this.glazing.value == Glazing.E0_05) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.Bronze,
            Tint.Green,
            Tint.Grey,
            Tint.BlueGreen,
            Tint.HighPerfGreen,
          ])
        }
      } else {
        if (this.glazing.value == Glazing.None) {
          return makeOptions(Tint, [Tint.Clear, Tint.HighPerfGreen])
        } else {
          return makeOptions(Tint, [Tint.Clear])
        }
      }
    })
    this.paneThickness = Field.makeSelect('Pane thickness', PaneThickness)
    this.paneThickness.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return []
      }
      if (elemIn(this.tint.value, [Tint.Clear, Tint.Bronze, Tint.Green, Tint.Grey])) {
        return makeOptions(PaneThickness)
      } else {
        return makeOptions(PaneThickness, [PaneThickness.QuarterInch])
      }
    })
    this.gapType = Field.makeSelect('Gap type', GapType)
    this.gapType.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(GapType, [GapType.None])
      } else if (elemIn(this.numPanes.value, [NumPanes.N2, NumPanes.N3])) {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
        ])
      } else {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
          GapType.QuarterInchKrypton,
        ])
      }
    })
    this.builderFields = [
      'frameStyle',
      'useDefaultFrameWidth',
      'frameWidth',
      'numPanes',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ]
    ObjectUtils.setFieldsEnabled(this, this.builderFields, () => {
      return this.inputType.value == SkylightInputType.BuildSkylight && isOtherStyle();
    })
    // }

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = this.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
    })
    this.outputShgc.makeUpdater((field) => {
      let shgcs = this.computeShgc();
      field.value = shgcs.shgc;
      field.debugOutput = DebugOn() ? prettyJson(shgcs) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'curbHeight',
      'unusualShape',
      'unusualShapeArea',
      'unusualShapePerimeter',
      'style',
      'installationSealing',
      'inputType',
      'manualUValue',
      'manualShgc',
      'domedSkylightInputs',
      'tubularSkylightInputs',
      'frameStyle',
      'useDefaultFrameWidth',
      'frameWidth',
      'numPanes',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Skylight',
    }
  }

  getObjErrors() {
    let errors = []
    ObjectUtils.addErrorsFromDict(errors, this.errorsDict)
    return errors;
  }

  getArea() {
    // This is the area of the glass part
    if (this.unusualShape.value == YesNo.No) {
      return this.height.value * this.width.value;
    } else {
      return this.unusualShapeArea.value;
    }
  }

  getPerimeter() {
    if (this.unusualShape.value == YesNo.No) {
      return 2*this.height.value + 2*this.width.value;
    } else {
      return this.unusualShapePerimeter.value;
    }
  }

  getCurbArea() {
    // Area of the opaque curb
    let A_FC = 2*this.curbHeight.getValueInUnits(Units.ft)*(this.height.value + this.width.value)
    return A_FC;
  }

  /*
  isSpecialStyle() {
    return elemIn(this.style.value, [
      SkylightStyle.DomedSkylight
      SkylightStyle.TubularDaylightingDevice,
    ])
  }
  */

  computeUValue() {
    if (this.inputType.value == SkylightInputType.Manual) {
      return {
        uValue: this.manualUValue.value,
      }
    } else {
      if (this.style.value == SkylightStyle.DomedSkylight) {
        // Treated the same as a ManufacturedSkylight but the area ratio is different
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          windowStyle: SkylightStyle.ManufacturedSkylight,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeUValue(inputData);

      } else if (this.style.value == SkylightStyle.TubularDaylightingDevice) {
        let uValue = lookupData(TubularSkylightData, [
          this.tubularSkylightInputs.getField('collectorLayers').value,
          this.tubularSkylightInputs.getField('diffuserLayers').value,
          this.tubularSkylightInputs.getField('insulationLocation').value,
          'uValue',
        ])
        return {
          uValue: uValue,
        }
      } else {
        // Similar method to windows.
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          // Note: we pass in the skylight style here. The func handles this fine.
          windowStyle: this.style.value,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeUValue(inputData);
      }
    }
  }

  computeShgc() {
    let uValue = this.computeUValue().uValue;
    if (this.inputType.value == SkylightInputType.Manual) {
      return WindowType._estimateSkylightShgcs(this.manualShgc.value);
    } else {
      if (this.style.value == SkylightStyle.DomedSkylight) {
        let opacity = this.domedSkylightInputs.getField('domeOpacity').value;
        let curbHeight = this.curbHeight.getValueInUnits(Units.inches);
        let interpMap = lookupData(DomedSkylightShgcs, [opacity]);
        let shgc = interpolateInMap(interpMap, curbHeight);
        return WindowType._estimateSkylightShgcs(shgc);
      } else if (this.style.value == SkylightStyle.TubularDaylightingDevice) {
        let shgc = lookupData(TubularSkylightData, [
          this.tubularSkylightInputs.getField('collectorLayers').value,
          this.tubularSkylightInputs.getField('diffuserLayers').value,
          this.tubularSkylightInputs.getField('insulationLocation').value,
          'shgc',
        ])
        return WindowType._estimateSkylightShgcs(shgc);
      } else {
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          paneThickness: this.paneThickness.value,
          tint: this.tint.value,
          windowStyle: this.style.value,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeShgc(inputData);
      }
    }
  }
}
setupClass(SkylightType)

export let AdjacentTemperature = makeEnum({
  OutdoorTemp: 'Outdoor temperature',
  IndoorTemp: 'Indoor temperature',
  Other: 'Other',
})

export class BufferSpaceWall {
  init() {
    // TODO - Clean up the UI here. Mixing Manual with wallType chooser.
    this.type = Field.makeTypeSelect('Type', gApp.proj().wallTypes, 'Manual')
    this.rValue = new Field({
      name: 'R-Value',
      type: FieldType.RValue,
      min: kEpsilon,
    })
    this.rValue.makeUpdater((field) => {
      if (this.type.value == 'Manual') {
        field.isOutput = false;
      } else {
        field.isOutput = true;

        let wallType = this.type.lookupValue();
        if (!wallType) {
          throw new Error(`Could not find wallType '${this.type.value}`);
        }
        field.value = wallType.getRValue();
      }
    })
    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
    })
    this.adjacentTemp = new Field({
      name: 'Adjacent Temperature',
      type: FieldType.Select,
      choices: makeOptions(AdjacentTemperature)
    })
    this.summerAdjacentTemp = new Field({
      name: 'Summer adjacent temperature',
      type: FieldType.Temp,
    })
    this.winterAdjacentTemp = new Field({
      name: 'Summer adjacent temperature',
      type: FieldType.Temp,
    })

    this.serFields = [
      'type',
      'rValue',
      'area',
      'adjacentTemp',
      'summerAdjacentTemp',
      'winterAdjacentTemp',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space Wall'
    }
  }

  getArea() {
    return this.area.value;
  }

  getRValue() {
    if (this.type.value == 'Manual') {
      return this.rValue.value;
    } else {
      let wallType = this.type.lookupValue();
      return wallType.getRValue();
    }
  }

  getAdjTemp(isHeating, t_o, t_i) {
    if (this.adjacentTemp.value == AdjacentTemperature.OutdoorTemp) {
      return t_o;
    } else if (this.adjacentTemp.value == AdjacentTemperature.IndoorTemp) {
      return t_i;
    } else if (this.adjacentTemp.value == AdjacentTemperature.Other) {
      return isHeating ? this.winterAdjacentTemp.value : this.summerAdjacentTemp.value;
    } else {
      throw new Error("Unexpected adjacentTemp: " + this.adjacentTemp.value);
    }
  }
}
setupClass(BufferSpaceWall)

export class BufferSpaceBuilder {
  init() {
    this.walls = [];
    this.estimatedInfiltration = new Field({
      name: 'Estimated infiltration',
      type: FieldType.AirFlow,
    })
    this.internalHeatGain = new Field({
      name: 'Internal heat generation',
      type: FieldType.Load,
    })

    this.outputSummerTemp = new Field({
      name: 'Buffer Space Summer Temperature',
      type: FieldType.Temp,
      isOutput: true,
    })
    this.outputSummerTemp.makeUpdater((field) => {
      field.value = this._calcTempForUI(false);
    })

    this.outputWinterTemp = new Field({
      name: 'Buffer Space Winter Temperature',
      type: FieldType.Temp,
      isOutput: true,
    })
    this.outputWinterTemp.makeUpdater((field) => {
      field.value = this._calcTempForUI(true);
    })

    this.serFields = [
      ser.arrayField('walls', () => { return BufferSpaceWall.create(); }),
      'estimatedInfiltration',
      'internalHeatGain',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space Builder',
    }
  }

  addWall() {
    this.walls.push(BufferSpaceWall.create());
  }

  removeWall(wall) {
    removeElem(this.walls, wall);
  }

  _calcTempForUI(isHeating) {
    let ctx = CalcContext.create();
    gApp.proj().toplevelData.calcOutputs(ctx);
    gApp.proj().designTempInputs.calcOutputs(ctx);
    let weatherData = ctx.toplevelData.locationData;
    ctx.elevation = weatherData.elevation;
    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    return this.calcTemp(ctx, ctx.C_s, ctx.t_o, ctx.t_i, isHeating);
  }

  calcTemp(ctx, C_s, t_o, t_i, isHeating) {
    ctx.startContext();

    let wallData = [];
    for (let i = 0; i < this.walls.length; ++i) {
      let wall = this.walls[i];
      wallData.push({
        A: wall.getArea(),
        R: wall.getRValue(),
        U: 1.0 / wall.getRValue(),
        t: wall.getAdjTemp(isHeating, t_o, t_i),
      });
    }
    ctx.wallData = wallData

    ctx.X_1 = ctx.evalSum(wallData, 'A*U*t', 'X_1')
    ctx.X_2 = ctx.evalSum(wallData, 'A*U', 'X_2');

    ctx.Q = this.estimatedInfiltration.value;
    ctx.q = this.internalHeatGain.value;
    let t_b = ctx.eval('(C_s*Q*t_o + X_1 + q) / (C_s*Q + X_2)',
      {C_s, t_o}, 't_b');
    ctx.endContext();
    return t_b;
  }
}
setupClass(BufferSpaceBuilder)

export let BufferSpaceEntryMethod = makeEnum({
  Manual: 'Manual',
  BuildBufferSpace: 'Build buffer space',
})

export class BufferSpaceType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('BufferSpaceType') : 0;

    this.name = Field.makeName('Name', name)

    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: makeOptions(BufferSpaceEntryMethod),
      bold: true,
    })

    this.manualGroup = new FieldGroup([
      new Field({
        key: 'summerTemperature',
        name: 'Buffer Space Summer Temperature',
        type: FieldType.Temp,
      }),
      new Field({
        key: 'winterTemperature',
        name: 'Buffer Space Winter Temperature',
        type: FieldType.Temp,
      })
    ])
    this.manualGroup.setVisibility(() => {
      return this.entryMethod.value == BufferSpaceEntryMethod.Manual;
    })

    this.builder = BufferSpaceBuilder.create();
    ObjectUtils.setEnabledWhen(this.builder, () => {
      return this.entryMethod.value == BufferSpaceEntryMethod.BuildBufferSpace;
    });

    this.serFields = [
      'id',
      'name',
      'entryMethod',
      'manualGroup',
      'builder',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space',
    }
  }

  calcTemps(ctx, C_s, t_o, t_i, isHeating) {
    if (this.entryMethod.value == BufferSpaceEntryMethod.Manual) {
      return isHeating ? this.manualGroup.getField('winterTemperature').value :
        this.manualGroup.getField('summerTemperature').value;
    } else {
      return this.builder.calcTemp(ctx, C_s, t_o, t_i, isHeating);
    }
  }

  getSummaryData() {
    if (this.entryMethod.value == BufferSpaceEntryMethod.Manual) {
      return {
        summerTemp: this.manualGroup.getField('summerTemperature').value,
        winterTemp: this.manualGroup.getField('winterTemperature').value,
      }
    } else {
      return {
        summerTemp: this.builder.outputSummerTemp.value,
        winterTemp: this.builder.outputWinterTemp.value,
      }
    }
  }
}
setupClass(BufferSpaceType)

export class WallWindow {
  init() {
    this.windowType = Field.makeTypeSelect('Type', gApp.proj().windowTypes, null, {
      errorWhenEmpty: `You must create a Window Type`,
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
    })
    this.interiorShadingType = Field.makeTypeSelect('Interior Shading',
      gApp.proj().interiorShadingTypes, 'None')
    this.exteriorShadingType = Field.makeTypeSelect('Exterior Shading',
      gApp.proj().exteriorShadingTypes, 'None')

    this.serFields = [
      'windowType',
      'quantity',
      'interiorShadingType',
      'exteriorShadingType',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Window',
    }
  }

  getWindowType() {
    return this.windowType.lookupValue();
  }

  getInteriorShadingType() {
    return this.interiorShadingType.lookupValue();
  }

  getExteriorShadingType() {
    return this.exteriorShadingType.lookupValue();
  }
}
setupClass(WallWindow)

export class WallDoor {
  init() {
    this.doorType = Field.makeTypeSelect('Type', gApp.proj().doorTypes, null, {
      errorWhenEmpty: `You must create a Door Type.`,
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
    })
    this.interiorShadingType = Field.makeTypeSelect(
      'Interior Shading', gApp.proj().interiorShadingTypes, 'None')
    this.exteriorShadingType = Field.makeTypeSelect(
      'Exterior Shading', gApp.proj().exteriorShadingTypes, 'None')

    this.serFields = [
      'doorType',
      'quantity',
      'interiorShadingType',
      'exteriorShadingType',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door',
    }
  }

  getDoorType() {
    return this.doorType.lookupValue();
  }

  getInteriorShadingType() {
    return this.interiorShadingType.lookupValue();
  }

  getExteriorShadingType() {
    return this.exteriorShadingType.lookupValue();
  }
}
setupClass(WallDoor)

export class Wall {
  init() {
    this.wallType = Field.makeTypeSelect('Type', gApp.proj().wallTypes, null, {
      errorWhenEmpty: 'You must create a Wall Type.',
    })
    this.area = new Field({
      name: 'area',
      defaultValue: 0,
      type: FieldType.Area,
    });
    this.direction = new Field({
      name: 'Direction',
      type: FieldType.Select,
      choices: kDirectionChoices,
      defaultValue: 'N',
    })
    this.isMostlyShaded = makeNoYesField("Wall is mostly shaded?");
    this.windows = [];
    this.doors = [];

    // Only relevant for commercial buildings:
    this.hasCeilingPlenum = makeYesNoField('Has ceiling plenum?');
    this.portionOfWallLoadToPlenum = new Field({
      name: 'Portion of wall load to plenum',
      type: FieldType.Percent,
    })
    this.portionOfWallLoadToPlenum.setVisibility(() => {
      return this.hasCeilingPlenum.value == YesNo.Yes;
    })

    this.expandedInUi = false;

    this.serFields = [
      'wallType',
      'area',
      'direction',
      'isMostlyShaded',
      ser.arrayField('windows', () => { return WallWindow.create(); }),
      ser.arrayField('doors', () => { return WallDoor.create(); }),
      'hasCeilingPlenum',
      'portionOfWallLoadToPlenum',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Wall',
    }
  }

  getNumWindows() {
    let num = 0;
    for (const win of this.windows) {
      num += win.quantity.value;
    }
    return num;
  }

  getNumDoors() {
    let num = 0;
    for (const door of this.doors) {
      num += door.quantity.value;
    }
    return num;
  }

  getWallType() {
    let wallType = this.wallType.lookupValue();
    if (!wallType) {
      console.log(`Could not find wallType '${this.wallType.value}' in wallTypes:\n${prettyJson(gApp.proj().wallTypes)}`)
    }
    return wallType;
  }

  getArea() {
    return this.area.value;
  }

  getStrictlyWallArea() {
    // Wall area = total area - window area - door area
    let totalWindowArea = 0;
    for (const win of this.windows) {
      totalWindowArea += win.getWindowType().getArea() * win.quantity.value;
    }
    let totalDoorArea = 0;
    for (const door of this.doors) {
      totalDoorArea += door.getDoorType().getArea() * door.quantity.value;
    }
    return this.area.value - totalWindowArea - totalDoorArea;
  }

  getTiltAngleDegs() {
    return 0
  }

  getRValue() {
    return this.getWallType().getRValue();
  }

  getPlenumLoadFraction() {
    return this.hasCeilingPlenum.value == YesNo.Yes ?
      this.portionOfWallLoadToPlenum.value / 100.0 : 0;
  }

  _calcLoads(ctx, isHeating) {
    ctx.startContext(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;

    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;

    // Calc opaque area
    ctx.A = this.area.value;
    for (const win of this.windows) {
      ctx.A = ctx.eval('A - A_win*N_win', {
        A_win: win.getWindowType().getArea(),
        N_win: win.quantity.value,
      }, 'A');
    }
    for (const door of this.doors) {
      ctx.A = ctx.eval('A - A_door*N_door', {
        A_door: door.getDoorType().getArea(),
        N_door: door.quantity.value,
      }, 'A');
    }

    let wallType = this.getWallType();
    ctx.R = wallType.getRValue();
    ctx.alpha = wallType.getAbsorptance();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');

    let surfaceType = this.isMostlyShaded.value == YesNo.Yes ?
      'WallOrDoorMostlyShaded' : 'WallOrDoorNotMostlyShaded';
    ctx.q_wall = calc.calcOpaqueQ(ctx, ctx.A,
      ctx.U, ctx.t_i, ctx.t_o, ctx.alpha,
      isHeating, surfaceType, weatherData);

    // Windows:
    let q_windows = 0;
    for (let i = 0; i < this.windows.length; ++i) {
      ctx.startContext(`Win${i + 1}`)
      let wallWin = this.windows[i];
      let win = wallWin.getWindowType();

      ctx.A_win = ctx.eval('A*N', {A: win.getArea(),
        N: wallWin.quantity.value}, 'A_win');
      ctx.U_win = win.computeUValue().uValue;
      ctx.direction = this.direction.value;
      ctx.hasBugScreen = win.hasBugScreen();
      // TODO - what about non-rectangular shape?
      ctx.H = win.height.value;
      ctx.W = win.width.value;
      let shgcValues = win.computeShgc();
      let interiorShading = wallWin.getInteriorShadingType();
      let exteriorShading = wallWin.getExteriorShadingType();
      if (interiorShading) {
        ctx.iacData = WindowType.computeIAC(win, interiorShading,
            gApp.proj().windowsData.iacValues);
        ctx.IAC = ctx.iacData.iac;
      } else {
        ctx.IAC = 1;
      }
      ctx.q_win = calc.calcWindowQ(ctx, ctx.A_win, ctx.U_win,
        ctx.t_i, ctx.t_o, ctx.hasBugScreen, ctx.direction,
        ctx.H, ctx.W,
        isHeating, shgcValues, ctx.IAC, interiorShading,
        exteriorShading, weatherData, gApp.proj().windowsData);
      q_windows += ctx.q_win;

      ctx.endContext();
    }

    // Doors
    let q_doors = 0;
    for (let i = 0; i < this.doors.length; ++i) {
      ctx.startContext(`Door${i + 1}`)
      let wallDoor = this.doors[i];
      let doorType = wallDoor.getDoorType();

      ctx.doorType = wallDoor.doorType.value;
      ctx.inputType = doorType.inputType.value;
      if (ctx.inputType == DoorInputType.BuildDoor) {
        ctx.buildDoorType = doorType.doorBuilder.type.value;
      }
      ctx.surfaceType = this.isMostlyShaded.value == YesNo.Yes ?
        'WallOrDoorMostlyShaded' : 'WallOrDoorNotMostlyShaded';

      ctx.percentGlass = doorType.getPercentGlass();
      ctx.pushMsg('\nOpaque part:')
      if (ctx.percentGlass < 100) {
        ctx.A_opq = ctx.eval('N*A*percentOpq',
          {N: wallDoor.quantity.value, A: doorType.getArea(),
            percentOpq: (100 - ctx.percentGlass) / 100.0}, 'A_opq');
        // Note: alpha is not used for Walls/Doors, just set to 0
        let absorptance = null;
        ctx.q_opq = calc.calcOpaqueQ(ctx, ctx.A_opq,
          doorType.computeUValue().uValueDoor, ctx.t_i, ctx.t_o, absorptance,
          isHeating, ctx.surfaceType, weatherData);
      } else {
        ctx.q_opq = 0;
      }

      ctx.pushMsg('\nGlass part:')
      if (ctx.percentGlass > 0) {
        ctx.A_win = ctx.eval('N*A*percentGlass', {
          A: doorType.getArea(), N: wallDoor.quantity.value,
          percentGlass: ctx.percentGlass / 100.0,
        }, 'A_win');
        // Note: this is the same as in the opaque case
        ctx.U_win = doorType.computeUValue().uValueGlass;
        ctx.direction = this.direction.value;
        ctx.hasBugScreen = doorType.hasScreenDoor();
        ctx.H = doorType.height.value;
        ctx.W = doorType.width.value;
        let shgcValues = doorType.computeShgc();
        let interiorShading = wallDoor.getInteriorShadingType();
        let exteriorShading = wallDoor.getExteriorShadingType();
        if (interiorShading) {
          ctx.iacData = WindowType.computeIAC(doorType, interiorShading,
              gApp.proj().windowsData.iacValues);
          ctx.IAC = ctx.iacData.iac;
        } else {
          ctx.IAC = 1;
        }
        ctx.q_glass = calc.calcWindowQ(ctx, ctx.A_win, ctx.U_win,
          ctx.t_i, ctx.t_o, ctx.hasBugScreen, ctx.direction,
          ctx.H, ctx.W,
          isHeating, shgcValues, ctx.IAC, interiorShading,
          exteriorShading, weatherData, gApp.proj().windowsData);
      } else {
        ctx.q_glass = 0;
      }

      ctx.pushMsg('\nTotal:')
      ctx.q_door = ctx.eval('q_opq + q_glass', {}, 'q_door')
      q_doors += ctx.q_door;
        
      ctx.endContext();
    }

    let res = {
      q: ctx.q_wall,
      q_windows: q_windows,
      q_doors: q_doors,
    }
    ctx.endContext();

    return res;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes.q,
      q_cooling: coolingRes.q,
      windows: {
        q_heating: heatingRes.q_windows,
        q_cooling: coolingRes.q_windows,
      },
      doors: {
        q_heating: heatingRes.q_doors,
        q_cooling: coolingRes.q_doors,
      }
    }
    return outputs;
  }
};
setupClass(Wall)

export class RoofSkylight {
  init() {
    this.skylightType = Field.makeTypeSelect('Type', gApp.proj().skylightTypes, null, {
      errorWhenEmpty: 'You must create a Skylight Type.',
    })

    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
    })

    this.serFields = [
      'skylightType',
      'quantity',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Skylight',
    }
  }

  getSkylightType() {
    return this.skylightType.lookupValue();
  }
}
setupClass(RoofSkylight)

export class Roof {
  init() {
    this.roofType = Field.makeTypeSelect('Type', gApp.proj().roofTypes, null, {
      errorWhenEmpty: `You must create a Roof Type.`
    })
    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
    })
    this.slope = new Field({
      name: 'Slope',
      type: FieldType.Percent,
    })
    this.direction = new Field({
      name: 'Direction',
      type: FieldType.Select,
      choices: kDirectionChoices,
    })
    /*
    this.direction.makeUpdater((field) => {
      field.isNA = this.slope.value != 0;
    })
    */
    this.adjacentToAttic = makeNoYesField('Adjacent to Attic');
    this.skylights = [];

    // Only relevant for commercial buildings:
    this.hasCeilingPlenum = makeYesNoField('Has ceiling plenum?');
    this.portionOfRoofLoadToPlenum = new Field({
      name: 'Portion of roof load to plenum',
      type: FieldType.Percent,
    })
    this.portionOfRoofLoadToPlenum.setVisibility(() => {
      return this.hasCeilingPlenum.value == YesNo.Yes;
    })

    this.expandedInUi = false;

    this.serFields = [
      'roofType',
      'area',
      'slope',
      'direction',
      'adjacentToAttic',
      ser.arrayField('skylights', () => { return RoofSkylight.create(); }),
      'hasCeilingPlenum',
      'portionOfRoofLoadToPlenum',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Roof',
    }
  }

  getRoofType() {
    return this.roofType.lookupValue();
  }

  getArea() {
    return this.area.value;
  }

  getStrictlyRoofArea() {
    // Roof area = total area - skylight area
    let totalSkylightArea = 0;
    for (const skylight of this.skylights) {
      totalSkylightArea += skylight.getSkylightType().getArea() * skylight.quantity.value;
    }
    return this.area.value - totalSkylightArea;
  }

  getTiltAngleDegs() {
    return toDegs(Math.atan(this.slope.value / 100.0));
  }

  getRValue() {
    return this.roofType.lookupValue().getRValue();
  }

  getNumSkylights() {
    let num = 0;
    for (const skylight of this.skylights) {
      num += skylight.quantity.value;
    }
    return num;
  }

  _calcLoads(ctx, isHeating) {
    ctx.startContext(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;

    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    /*
    if (!isHeating) {
      ctx.t_wb_F = weatherData.cooling0p4PerDryBulbMCWB;
    }
    */

    // Calc opaque area
    ctx.A = this.area.value;
    for (let i = 0; i < this.skylights.length; ++i) {
      let skylight = this.skylights[i];
      ctx.A = ctx.eval('A - A_skylight*N_skylight', {
        A_skylight: skylight.getSkylightType().getArea(),
        N_skylight: skylight.quantity.value,
      }, 'A');
    }

    let roofType = this.roofType.lookupValue();
    ctx.R = roofType.getRValue();
    ctx.alpha = roofType.getAbsorptance();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');

    let adjToAttic = this.adjacentToAttic.value == YesNo.Yes;
    let surfaceType = adjToAttic ? 'RoofAdjToAttic' : 'RoofNoAttic';
    ctx.q_roof = calc.calcOpaqueQ(ctx, ctx.A, ctx.U, ctx.t_i, ctx.t_o,
      ctx.alpha, isHeating, surfaceType, weatherData);

    // Skylights:
    let q_skylights = 0;
    for (let i = 0; i < this.skylights.length; ++i) {
      ctx.startContext(`SL${i + 1}`)
      let roofSl = this.skylights[i];
      let sl = roofSl.getSkylightType();
      let uValueData = sl.computeUValue();

      ctx.pushMsg('\nOpaque part (curb):');
      // Note: we approximate here and assume the U-value of the curb is the same as the
      // given U-Value/glass U-value (even though a manually given U-value is for the glass+curb)
      if (sl.getCurbArea() > 0) {
        ctx.A_opq = ctx.eval('N*A_curb',
          {N: roofSl.quantity.value, A_curb: sl.getCurbArea()}, 'A_opq');
        // Note: alpha is not used for Walls/Doors/curbs, just set to null
        // Note: we try to use the uValueFrame, which is available when the skylight uses the
        // WindowBuilder. Fall back to regular u-value if not available.
        let uValueCurb = valOr(uValueData.uValueFrame, uValueData.uValue);
        let absorptance = null;
        ctx.q_opq = calc.calcOpaqueQ(ctx, ctx.A_opq,
          uValueCurb, ctx.t_i, ctx.t_o, absorptance,
          isHeating, 'WallOrDoorNotMostlyShaded', weatherData);
      } else {
        ctx.A_curb = 0;
        ctx.q_opq = 0;
      }

      ctx.pushMsg('\nGlass part:')
      ctx.A_sl = ctx.eval('A*N', {A: sl.getArea(),
        N: roofSl.quantity.value}, 'A_sl');
      if (isHeating) {
        ctx.HF = ctx.eval('U_sl*(t_i - t_o)', {
          U_sl: uValueData.uValue}, 'HF');
        ctx.q_glass = ctx.eval('A_sl*HF', {}, 'q_glass')
      } else {
        let DR = weatherData.meanDailyDryBulbRange;
        let E_t = cool.calc_E_t_skylight(ctx, weatherData.latitude);
        let PXI = ctx.eval('T_x*E_t', {T_x: 1,
          E_t: E_t}, 'PXI');
        let SHGC_0 = sl.computeShgc().Deg0;
        let IAC = 1;
        let FF_s = lookupData(FFsVals, ['Horizontal', 'Single-family']);
        ctx.CF = ctx.call(cool.calc_CF_full,
          uValueData.uValue, ctx.t_o, ctx.t_i,
          DR, PXI, SHGC_0, IAC, FF_s);
        ctx.q_glass = ctx.eval('A_sl*CF', {}, 'q_glass')
      }
      ctx.q_sl = ctx.eval('q_glass + q_opq', {}, 'q_sl');
      q_skylights += ctx.q_sl;
      ctx.endContext();
    }

    let res = {
      q: ctx.q_roof,
      q_skylights: q_skylights,
    }
    ctx.endContext();

    return res;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes.q,
      q_cooling: coolingRes.q,
      skylights: {
        q_heating: heatingRes.q_skylights,
        q_cooling: coolingRes.q_skylights,
      }
    }
    return outputs;
  }
}
setupClass(Roof)

export class Partition {
  init() {
    this.wallType = Field.makeTypeSelect('Wall Type', gApp.proj().wallTypes, null, {
      errorWhenEmpty: `You must create a Wall Type.`
    })
    this.size = new Field({
      name: 'Size',
      type: FieldType.Area,
    })
    this.bufferSpaceType = Field.makeTypeSelect('Buffer Space', gApp.proj().bufferSpaceTypes, null, {
      errorWhenEmpty: `You must create a Buffer Space Type`,
    })

    this.serFields = [
      'wallType',
      'size',
      'bufferSpaceType',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Partition',
    }
  }

  getWallType() {
    return this.wallType.lookupValue();
  }

  getBufferSpaceType() {
    return this.bufferSpaceType.lookupValue();
  }

  _calcLoads(ctx, isHeating) {
    ctx.startContext(`${isHeating ? 'Heating' : 'Cooling'}`)
    ctx.wallType = this.wallType.value;
    ctx.A = this.size.value;
    ctx.bufferSpaceType = this.bufferSpaceType.value;

    let wallType = this.getWallType();
    let bufferSpaceType = this.getBufferSpaceType();

    let weatherData = ctx.toplevelData.locationData;
    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating ? ctx.designTemps.heating : ctx.designTemps.cooling;


    ctx.elevation = weatherData.elevation;
    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    ctx.t_b = bufferSpaceType.calcTemps(ctx, ctx.C_s, ctx.t_o, ctx.t_i, isHeating);

    ctx.R = wallType.getRValue();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');
    ctx.q = calc.calcPartitionOpaqueQ(ctx, ctx.A,
      ctx.U, ctx.t_i, ctx.t_b, isHeating);

    let q = ctx.q;
    ctx.endContext();
    return q;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes,
      q_cooling: coolingRes,
    }
    return outputs;
  }
}
setupClass(Partition)

export let RecoveryType = makeEnum({
  None: 'None',
  HRV: 'HRV',
  ERV: 'ERV',
})

export let BuildingQuality = makeEnum({
  Tight: 'Tight',
  Good: 'Good',
  Average: 'Average',
  Leaky: 'Leaky',
  VeryLeaky: 'VeryLeaky',
})

export class VentilationInfiltrationData {
  init() {
    this.entryMethod = Field.makeSelect('Entry Method', AutomaticOrManual, {bold: true})
    // Manual:
    this.totalVentilation = new Field({
      name: 'Total Ventilation',
      type: FieldType.AirFlow,
      defaultValue: 100,
      min: 0,
    });
    this.totalVentilation.makeUpdater((field) => {
      field.visible = this.entryMethod.value == AutomaticOrManual.Manual;
    });
    
    this.continuousExhaust = new Field({
      name: 'Continuous Exhaust',
      type: FieldType.AirFlow,
      defaultValue: 0,
      min: 0,
    });

    // Heat recovery:
    this.recoveryType = Field.makeSelect('Recovery Type', RecoveryType, {bold: true})
    this.hrvGroup = new FieldGroup([
      new Field({
        key: 'flow',
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
      }),
      new Field({
        key: 'summerEfficiency',
        name: 'Summer Efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'winterEfficiency',
        name: 'Winter Efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      })
    ]);
    this.hrvGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.HRV;
    })

    this.ervGroup = new FieldGroup([
      new Field({
        key: 'flow',
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
      }),
      new Field({
        key: 'summerSensibleEfficiency',
        name: 'Summer sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'summerTotalEfficiency',
        name: 'Summer total efficiency',
        type: FieldType.Percent,
        defaultValue: 60,
      }),
      new Field({
        key: 'winterSensibleEfficiency',
        name: 'Winter sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      }),
      new Field({
        key: 'winterTotalEfficiency',
        name: 'Winter total efficiency',
        type: FieldType.Percent,
        defaultValue: 80,
      }),
    ]);
    this.ervGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.ERV;
    })

    this.otherBalancedAirflows = new Field({
      name: "Other Balanced Airflows",
      type: FieldType.AirFlow,
      defaultValue: 0,
      min: 0,
    });

    this.infiltrationEntryMethod = Field.makeSelect('Entry Method', AutomaticOrManual, {bold: true})
    this.infiltrationManual = new FieldGroup([
      new Field({
        key: 'summerInfiltration',
        name: 'Summer Infiltration',
        type: FieldType.AirFlow,
        min: 0,
      }),
      new Field({
        key: 'winterInfiltration',
        name: 'Winter Infiltration',
        type: FieldType.AirFlow,
        min: 0,
      })
    ]);
    this.infiltrationManual.setVisibility(() => {
      return this.infiltrationEntryMethod.value == ManualOrAutomatic.Manual;
    })
    this.infiltrationAutomatic = new FieldGroup([
      new Field({
        key: 'totalExposedArea',
        name: 'Total Exposed Building Area',
        type: FieldType.Area,
        defaultValue: 3500,
        min: 0,
      }),
      new Field({
        key: 'buildingQuality',
        name: 'Building Construction Quality',
        type: FieldType.Select,
        choices: makeOptions(BuildingQuality),
        defaultValue: BuildingQuality.Good,
        min: 0,
      }),
      new Field({
        key: 'fluePipeLeakageArea',
        name: 'Flue pipe effective leakage area',
        type: FieldType.SmallArea,
        defaultValue: 0,
        min: 0,
      })
    ]);
    this.infiltrationAutomatic.setVisibility(() => {
      return this.infiltrationEntryMethod.value == ManualOrAutomatic.Automatic; 
    })

    this.serFields = [
      'entryMethod',
      'totalVentilation',
      'continuousExhaust',
      'recoveryType',
      'hrvGroup',
      'ervGroup',
      'otherBalancedAirflows',
      'infiltrationEntryMethod',
      'infiltrationManual',
      'infiltrationAutomatic',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Ventilation & Infiltration',
    }
  }

  calcOutputs(ctx) {
    ctx.startSection("Ventilation&Infiltration");
    let results = {
      heating: this._calcLoads(ctx, true),
      cooling: this._calcLoads(ctx, false),
    }
    ctx.log("");
    ctx.res.ventilationInfiltration = results;
    ctx.endSection();
  }

  _calcLoads(ctx, isHeating) {
    ctx.startContext(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;
    ctx.elevation = weatherData.elevation;

    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    ctx.C_t = 4.5 * ctx.P_loc / psy.P_std;
    ctx.C_l = 4840 * ctx.P_loc / psy.P_std;
    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    if (!isHeating) {
      ctx.t_wb_F = ctx.designTemps.coolingMCWB;
    }
    ctx.A_floor = ctx.toplevelData.totalFloorArea;
    ctx.avgCeilH = ctx.toplevelData.averageCeilingHeight;
    ctx.N_stories = ctx.toplevelData.numberStoreys;
   
    if (isHeating) {
      [ctx.h_i, ctx.W_i] = ctx.call(psy.calcIFull, ctx.t_i, ctx.toplevelData.indoorWinterHumidity, ctx.P_loc);
      [ctx.h_o, ctx.W_o] = ctx.call(psy.calcIFull, ctx.t_o, psy.RH_winter_outdoor, ctx.P_loc);
    } else {
      [ctx.h_i, ctx.W_i] = ctx.call(psy.calcIFull, ctx.t_i, ctx.toplevelData.indoorSummerHumidity, ctx.P_loc);
      [ctx.h_o, ctx.W_o] = ctx.call(psy.calcIFullKnownTwb, ctx.t_o, ctx.t_wb_F, ctx.P_loc);
    }

    // TODO - is this automatic vs manual method correct?
    if (this.entryMethod.value == ManualOrAutomatic.Manual) {
      ctx.Q_sup = this.totalVentilation.value;
    } else {
      ctx.N_br = ctx.toplevelData.numberBedrooms;
      ctx.Q_sup = calc.calcQ_V(ctx, ctx.A_floor, ctx.N_br);
    }
    ctx.Q_exh = this.continuousExhaust.value;
    ctx.Q_bal = ctx.eval('min(Q_sup, Q_exh)',
      {Q_sup: ctx.Q_sup, Q_exh: ctx.Q_exh}, 'Q_bal');
    ctx.Q_unbal = ctx.eval('max(Q_sup, Q_exh) - Q_bal',
        {Q_sup:ctx.Q_sup, Q_exh:ctx.Q_exh, Q_bal:ctx.Q_bal}, 'Q_unbal');

    ctx.infiltrationEntryMethod = this.infiltrationEntryMethod.value;
    if (this.infiltrationEntryMethod.value == ManualOrAutomatic.Manual) {
      // Manual calculation of Q_i
      ctx.Q_i = isHeating ? this.infiltrationManual.getField('winterInfiltration').value
        : this.infiltrationManual.getField('summerInfiltration').value;
    } else {
      // Automatic calculation of Q_i
      let A_es = this.infiltrationAutomatic.getField('totalExposedArea').value;
      let A_ul = lookupData(calc.A_ul_Table, [
        this.infiltrationAutomatic.getField('buildingQuality').value,
      ]);
      ctx.A_L = ctx.eval('A_es * A_ul', {A_es, A_ul}, 'A_L');
      ctx.A_L_flue = this.infiltrationAutomatic.getField('fluePipeLeakageArea').value;
      ctx.IDF = calc.calcIDF(ctx, ctx.A_L_flue, ctx.A_L, ctx.avgCeilH*ctx.N_stories, ctx.t_i, ctx.t_o, isHeating);
      ctx.Q_i = ctx.eval('IDF * A_L', {IDF:ctx.IDF, A_L:ctx.A_L}, 'Q_i');
    }
    ctx.Q_vi = ctx.eval('max(Q_unbal, Q_i + 0.5*Q_unbal)',
      {Q_unbal:ctx.Q_unbal,Q_i:ctx.Q_i}, 'Q_vi');

    let heatingSign = isHeating ? 1 : -1;
    if (this.recoveryType.value == RecoveryType.None) {
      ctx.q_A_s = ctx.eval('Q_vi*C_s*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A_l = ctx.eval('Q_vi*C_l*heatingSign*(W_i - W_o)',
        {heatingSign}, 'q_A_l');
    } else if (this.recoveryType.value == RecoveryType.HRV) {
      ctx.Eff_s = this.hrvGroup.getField(
        isHeating ? 'winterEfficiency' : 'summerEfficiency').value;
      ctx.Q_HRV = this.hrvGroup.getField('flow').value;
      ctx.Q_bal_other = this.otherBalancedAirflows.value;
      ctx.q_A_s = ctx.eval('C_s*(Q_vi + (1 - Eff_s/100.0)*Q_HRV + Q_bal_other)*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A_l = ctx.eval('C_l*(Q_vi + Q_bal_other)*heatingSign*(W_i - W_o)',
        {heatingSign}, 'q_A_l');
    } else if (this.recoveryType.value == RecoveryType.ERV) {
      ctx.Eff_s = this.ervGroup.getField(
        isHeating ? 'winterSensibleEfficiency' : 'summerSensibleEfficiency').value;
      ctx.Eff_t = this.ervGroup.getField(
        isHeating ? 'winterTotalEfficiency' : 'summerTotalEfficiency').value;
      ctx.Q_HRV = this.ervGroup.getField('flow').value;
      ctx.Q_bal_other = this.otherBalancedAirflows.value;

      ctx.q_A_s = ctx.eval('C_s*(Q_vi + (1 - Eff_s/100.0)*Q_HRV + Q_bal_other)*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A = ctx.eval('C_t*(Q_vi + (1 - Eff_t/100.0)*Q_HRV + Q_bal_other)*heatingSign*(h_i - h_o)',
        {heatingSign}, 'q_A');
      ctx.q_A_l = ctx.eval('q_A - q_A_s', {}, 'q_A_l');
    }

    let q_A_s = ctx.q_A_s;
    let q_A_l = ctx.q_A_l;
    ctx.endContext();

    return {
      sensible: q_A_s,
      latent: q_A_l,
    };
  }
};
setupClass(VentilationInfiltrationData)

export class InternalsData {
  init() {
    this.useStdMethod = new Field({
      name: 'Use standard internal gain calculation?',
      type: FieldType.Select,
      choices: makeOptions(YesNo),
      bold: true,
    })
    // For YES:
    this.additionalOccupants = new Field({
      name: 'Number occupants',
      type: FieldType.Count,
      defaultValue: 3,
    })
    this.additionalOccupants.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })
    this.additionalSensibleLoads = new Field({
      name: 'Additional sensible loads',
      type: FieldType.Load,
      defaultValue: 100,
    })
    this.additionalSensibleLoads.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })
    this.additionalLatentLoads = new Field({
      name: 'Additional latent loads',
      type: FieldType.Load,
      defaultValue: 50,
    })
    this.additionalLatentLoads.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })

    this.serFields = [
      'useStdMethod',
      'additionalOccupants',
      'additionalSensibleLoads',
      'additionalLatentLoads',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Internals',
    }
  }

  calcOutputs(ctx) {
    ctx.startSection("Internals")
    ctx.isManual = this.useStdMethod.value == YesNo.No;
    let q_occ_s = null;
    let q_occ_l = null;
    let q_I_s = null;
    let q_I_l = null;
    let q_I_s_tot = null;
    let q_I_l_tot = null;
    if (ctx.isManual) {
      ctx.N_occ = this.additionalOccupants.value;
      ctx.q_occ_s = ctx.eval('75*N_occ', {}, 'q_occ_s');
      ctx.q_occ_l = ctx.eval('41*N_occ', {}, 'q_occ_l');
      ctx.q_I_s = this.additionalSensibleLoads.value;
      ctx.q_I_l = this.additionalLatentLoads.value;
    } else {
      ctx.N_occ = ctx.eval('N_br + 1', {N_br: ctx.toplevelData.numberBedrooms}, 'N_occ');
      ctx.A_floor = ctx.toplevelData.totalFloorArea;
      ctx.q_occ_s = ctx.eval('75*N_occ', {}, 'q_occ_s');
      ctx.q_occ_l = ctx.eval('41*N_occ', {}, 'q_occ_l');
      ctx.q_I_s = ctx.eval('464 + 0.7*A_floor', {}, 'q_I_s');
      ctx.q_I_l = ctx.eval('68 + 0.07*A_floor', {}, 'q_I_l');
    }
    ctx.q_I_s_tot = ctx.eval('q_occ_s + q_I_s', {}, 'q_I_s_tot');
    ctx.q_I_l_tot = ctx.eval('q_occ_l + q_I_l', {}, 'q_I_l_tot');

    ctx.res.internals = {
      people: {
        cooling: {sensible: ctx.q_occ_s, latent: ctx.q_occ_l},
        heating: {},
      },
      other: {
        cooling: {sensible: ctx.q_I_s, latent: ctx.q_I_l},
        heating: {},
      },
      total: {
        cooling: {sensible: ctx.q_I_s_tot, latent: ctx.q_I_l_tot},
        heating: {},
      },
    }

    ctx.endSection();
  }
}
setupClass(InternalsData);

export let FloorType = makeEnum({
  SlabOnGrade: 'Slab on grade',
  SlabBelowGrade: 'Slab below grade (basement)',
  FloorAboveCrawlSpace: 'Floor above crawl space or unconditioned space',
  FloorAboveConditionedSpace: 'Floor above conditioned space',
  FloorRaisedOffGround: 'Floor raised off ground',
})

export let ManualOrAutomatic = makeEnum({
  Manual: 'Manual',
  Automatic: 'Automatic',
})

export let AutomaticOrManual = makeEnum({
  Automatic: 'Automatic',
  Manual: 'Manual',
})

export let OuterWallConstruction = makeEnum({
  EightInchBlockWall: '8" block wall, brick facing',
  FourInchBlockWall: '4" block wall, brick facing',
  MetalStudWall: 'Metal stud wall with stucco and dry wall',
  PouredConcreteWall: 'Poured concrete wall',
})

export let PerimeterInsulation = makeEnum({
  Insulated: 'Insulated',
  Uninsulated: 'Uninsulated',
})

export let ManualOrSelectFromList = makeEnum({
  Manual: 'Manual',
  SelectFromList: 'Select from List',
})

let FpTable = {
  [OuterWallConstruction.EightInchBlockWall]: {
    [PerimeterInsulation.Uninsulated]: 0.68,
    [PerimeterInsulation.Insulated]: 0.50,
  },
  [OuterWallConstruction.FourInchBlockWall]: {
    [PerimeterInsulation.Uninsulated]: 0.84,
    [PerimeterInsulation.Insulated]: 0.49,
  },
  [OuterWallConstruction.MetalStudWall]: {
    [PerimeterInsulation.Uninsulated]: 1.20,
    [PerimeterInsulation.Insulated]: 0.53,
  },
  [OuterWallConstruction.PouredConcreteWall]: {
    [PerimeterInsulation.Uninsulated]: 2.12,
    [PerimeterInsulation.Insulated]: 0.72,
  }
};

export class HouseFloor {
  init() {
    this.floorType = new Field({
      name: 'Floor Type',
      type: FieldType.Select,
      choices: makeOptions(FloorType),
      bold: true,
    })

    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
    })
    this.area.makeUpdater((field) => {
      field.visible = this.floorType.value !== FloorType.FloorAboveConditionedSpace
    })

    this.onGradeGroup = new FieldGroup([
      new Field({
        key: 'outerWall',
        name: 'Outer wall construction',
        type: FieldType.Select,
        choices: makeOptions(OuterWallConstruction)
      }),
      new Field({
        key: 'perimeterInsulation',
        name: 'Perimeter Insulation',
        type: FieldType.Select,
        choices: makeOptions(PerimeterInsulation),
      }),
      new Field({
        key: 'exposedPerimeter',
        name: 'Exposed perimeter',
        type: FieldType.Area,
        min: 0,
      })
    ])

    this.raisedGroup = new FieldGroup([
      new Field({
        key: 'floorRValue',
        name: 'Floor R-Value',
        type: FieldType.RValue,
      })
    ])
    this.belowGradeGroup = new FieldGroup([
      new Field({
        key: 'depth',
        name: 'Depth',
        type: FieldType.Length,
      }),
      new Field({
        key: 'floorInsulationRValue',
        name: 'R-Value of Floor Insulation',
        type: FieldType.RValue,
      }),
      new Field({
        key: 'wallInsulationRValue',
        name: 'R-Value of Wall Insulation',
        type: FieldType.RValue,
      }),
      new Field({
        key: 'shortestWidth',
        name: 'Shortest Width of Basement',
        type: FieldType.Length,
      }),
      new Field({
        key: 'belowGradeWallArea',
        name: 'Below-grade wall area',
        type: FieldType.Area,
      }),
      new Field({
        key: 'tempVariationEntryType',
        name: 'Amplitude of ground surface temp. variation entry',
        type: FieldType.Select,
        choices: makeOptions(ManualOrAutomatic, [ManualOrAutomatic.Automatic, ManualOrAutomatic.Manual]),
        bold: true,
      }),
      new Field({
        key: 'tempVariation',
        name: 'Amplitude of ground surface temp. variation',
        type: FieldType.Temp,
      }),
      new Field({
        key: 'avgGroundTempEntryType',
        name: 'Average ground temperature entry',
        type: FieldType.Select,
        choices: makeOptions(ManualOrAutomatic),
        bold:  true,
      }),
      new Field({
        key: 'avgGroundTemp',
        name: 'Average ground temperature',
        type: FieldType.Temp,
      }),
    ])
    this.belowGradeGroup.getField('tempVariation').makeUpdater((field) => {
      let entryType = this.belowGradeGroup.getField('tempVariationEntryType').value;
      if (entryType == ManualOrAutomatic.Manual) {
        field.isOutput = false;
      } else {
        let weatherData = gApp.proj().toplevelData.locationData.getOutputs();
        field.value = GroundSurfaceTempAmplitudes.lookupValue(weatherData.latitude, weatherData.longitude);
        console.log(`Setting ${field.name} value to: ${field.value}`);
        field.isOutput = true;
      }
    })
    this.belowGradeGroup.getField('avgGroundTemp').makeUpdater((field) => {
      let entryType = this.belowGradeGroup.getField('avgGroundTempEntryType').value;
      field.visible = entryType == ManualOrAutomatic.Manual;
      /*
      if (entryType == ManualOrAutomatic.Manual) {
        field.isOutput = false;
      } else {
        let weatherData = gApp.proj().toplevelData.locationData.getOutputs();
        field.value = weatherData.avgAnnualTemp;
        field.isOutput = true;
      }
      */
    })

    this.aboveCrawlSpaceGroup = new FieldGroup([
      new Field({
        key: 'floorRValue',
        name: 'Floor R-Value',
        type: FieldType.RValue,
      }),
      new Field({
        key: 'winterTemp',
        name: 'Winter temperature of space',
        type: FieldType.Temperature,
      }),
      new Field({
        key: 'summerTemp',
        name: 'Summer temperature of space',
        type: FieldType.Temperature,
      }),
      // TODO - implement the temperature calculator
    ])

    this.additionalCoveringOption = new Field({
      name: 'Entry type',
      type: FieldType.Select,
      choices: makeOptions(ManualOrSelectFromList),
      bold: true,
    })
    this.manualCovering = new Field({
      name: 'R-value',
      type: FieldType.RValue,
    })
    this.listCovering = new Field({
      name: 'Material types',
      type: FieldType.Select,
      choices: HouseFloor.getFloorCoveringOptions(),
    })
    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      let showCovering = this.floorType.value !== FloorType.FloorAboveConditionedSpace;
      this.additionalCoveringOption.visible = showCovering;
      this.manualCovering.visible = showCovering;
      this.listCovering.visible = showCovering;
    })

    this.serFields = [
      'floorType',
      'area',
      'onGradeGroup',
      'raisedGroup',
      'belowGradeGroup',
      'aboveCrawlSpaceGroup', 
      'additionalCoveringOption',
      'manualCovering',
      'listCovering',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Floor',
    }
  }

  isFloorRaised() {
    return this.floorType.value == FloorType.FloorRaisedOffGround;
  }

  getArea() {
    return this.area.value;
  }

  getObjErrors() {
    let errors = []
    ObjectUtils.addErrorsFromDict(errors, this.errorsDict);
    return errors;
  }

  static getFloorCoveringOptions() {
    let options = []
    for (const coveringName in kFloorCoverings) {
      let option = {
        label: `${coveringName} (R-Value=${kFloorCoverings[coveringName]})`,
        value: coveringName,
      }
      options.push(option);
    }
    return options;
  }

  getFloorCoveringRValue() {
    if (this.additionalCoveringOption.value == ManualOrSelectFromList.Manual) {
      return this.manualCovering.value;
    } else {
      let coveringName = this.listCovering.value;
      if (!(coveringName in kFloorCoverings)) {
        throw new Error(`Unknown floor covering: ${coveringName}`);
      }
      return kFloorCoverings[coveringName]
    }
  }

  _calcLoads(ctx, isHeating) {
    ctx.startContext(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;
    ctx.floorType = this.floorType.value;
    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;

    ctx.A = this.area.value;
    ctx.R_cvr = this.getFloorCoveringRValue();
    if (this.floorType.value == FloorType.SlabOnGrade) {
      if (isHeating) {
        ctx.wallConstruction = this.onGradeGroup.getField('outerWall').value;
        ctx.perimeterInsulation = this.onGradeGroup.getField('perimeterInsulation').value;
        ctx.P = this.onGradeGroup.getField('exposedPerimeter').value;
        ctx.F_p = lookupData(FpTable, [ctx.wallConstruction, ctx.perimeterInsulation]);
        // TODO - should t_o be t_gr here?
        ctx.HF = ctx.eval('F_p*(t_i - t_o)', {}, 'HF');
        ctx.q = ctx.eval('HF*P', {}, 'q')
      } else {
        // Note: should have -ve net cooling load
        ctx.h_srf = ctx.eval('1.0/(R_cvr + 0.68)', {}, 'h_srf');
        ctx.CF = ctx.eval('0.59 - 2.5*h_srf', {}, 'CF');
        ctx.q = ctx.eval('CF*A', {}, 'q');
      }
    } else if (this.floorType.value == FloorType.SlabBelowGrade) {
      if (isHeating) {
        ctx.groundTempEntryType = this.belowGradeGroup.getField('avgGroundTempEntryType').value; 
        ctx.t_gr_avg = ctx.groundTempEntryType == ManualOrAutomatic.Manual ?
          this.belowGradeGroup.getField('avgGroundTemp').value : weatherData.avgAnnualTemp;
        ctx.A_gr = this.belowGradeGroup.getField('tempVariation').value;
        ctx.t_gr = ctx.eval('t_gr_avg - A_gr', {}, 't_gr');

        ctx.k_soil = weatherData.k_soil;
        ctx.d = this.belowGradeGroup.getField('depth').value;
        ctx.R_other = ctx.eval('R_wall_insul + 1.47', {
          R_wall_insul: this.belowGradeGroup.getField('wallInsulationRValue').value
        }, 'R_other');

        // Wall
        ctx.U_avg_bw = ctx.eval(
          '((2*k_soil)/(pi*d))*(ln(d + 2*k_soil*R_other/pi) - ln(2*k_soil*R_other/pi))',
          {pi: Math.PI, ln: Math.log,}, 'U_avg_bw')
        ctx.HF_bw = ctx.eval('U_avg_bw*(t_i - t_gr)', {}, 'HF_bw');
        ctx.A_wall = this.belowGradeGroup.getField('belowGradeWallArea').value;
        ctx.q_bw = ctx.eval('HF_bw*A_wall', {}, 'q_bw')

        // Floor
        ctx.w_b = this.belowGradeGroup.getField('shortestWidth').value;
        ctx.R_other = ctx.eval('R_floor_insul + R_cvr + 1.47', {
          R_floor_insul: this.belowGradeGroup.getField('floorInsulationRValue').value,
        }, 'R_other')
        ctx.U_avg_bf = ctx.eval(
          '(2*k_soil/(pi*w_b))*(ln(w_b/2 + d/2 + k_soil*R_other/pi) - ln(d/2 + k_soil*R_other/pi))',
          {pi: Math.PI, ln: Math.log,}, 'U_avg_bf')
        ctx.HF_bf = ctx.eval('U_avg_bf*(t_i - t_gr)', {}, 'HF_bf');
        ctx.q_bf = ctx.eval('HF_bf*A', {}, 'q_bf')

        ctx.q = ctx.q_bw + ctx.q_bf;
      } else {
        // Note: should have -ve net cooling load
        ctx.R_floor_insul = this.belowGradeGroup.getField('floorInsulationRValue').value;
        ctx.h_srf = ctx.eval('1.0/(R_cvr + R_floor_insul + 0.68)', {}, 'h_srf');
        ctx.CF = ctx.eval('0.59 - 2.5*h_srf', {}, 'CF');
        ctx.q = ctx.eval('CF*A', {}, 'q');
      }
    } else if (this.floorType.value == FloorType.FloorAboveCrawlSpace) {
      // Treat as a partition
      ctx.t_b = isHeating ? this.aboveCrawlSpaceGroup.getField('winterTemp').value
        : this.aboveCrawlSpaceGroup.getField('summerTemp').value;
      ctx.R = ctx.eval('R_floor + R_cvr', {
        R_floor: this.aboveCrawlSpaceGroup.getField('floorRValue').value,
      }, 'R')
      ctx.U = 1.0 / ctx.R;
      ctx.q = calc.calcPartitionOpaqueQ(ctx, ctx.A,
        ctx.U, ctx.t_i, ctx.t_b, isHeating);
    } else if (this.floorType.value == FloorType.FloorAboveConditionedSpace) {
      // No heating/cooling load
      ctx.q = 0;
    } else if (this.floorType.value == FloorType.FloorRaisedOffGround) {
      // Treat as a wall
      ctx.R = ctx.eval('R_floor + R_cvr', {
        R_floor: this.raisedGroup.getField('floorRValue').value,
      }, 'R');
      ctx.U = 1.0 / ctx.R;
      let surfaceType = 'FloorRaisedOffGround';
      ctx.q = calc.calcOpaqueQ(ctx, ctx.A,
        ctx.U, ctx.t_i, ctx.t_o, null,
        isHeating, surfaceType, weatherData);
    } else {
      throw new Error("Unknown FloorType: " + this.floorType.value);
    }

    let q = ctx.q;
    ctx.endContext();
    return q;
  }

  calcOutputs(ctx) {
    let result = {};
    result.q_heating = this._calcLoads(ctx, true);
    result.q_cooling = this._calcLoads(ctx, false);
    return result;
  }
}
setupClass(HouseFloor)

export let DuctRunLocation = makeEnum({
  Attic: 'Attic',
  Basement: 'Basement',
  CrawlSpace: 'Crawl space',
  ConditionedSpace: 'Conditioned space',
  Unknown: 'Unknown',
  DuctsNotUsed: 'Ducts are not used',
})

export let SystemType = makeEnum({
  Furnace: 'Furnace',
  HeatPump: 'Heat pump',
})

export let UnknownOrEnter = makeEnum({
  Unknown: 'Unknown',
  Enter: 'Enter',
})

let DuctLossFactors = {
  [DuctRunLocation.Attic]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.49, [4]: 0.29, [8]: 0.25},
        [5]: {[0]: 0.34, [4]: 0.16, [8]: 0.13},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.41, [4]: 0.26, [8]: 0.24},
        [5]: {[0]: 0.27, [4]: 0.14, [8]: 0.12},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.56, [4]: 0.37, [8]: 0.34},
        [5]: {[0]: 0.34, [4]: 0.19, [8]: 0.16},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.49, [4]: 0.35, [8]: 0.33},
        [5]: {[0]: 0.28, [4]: 0.17, [8]: 0.15},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
  [DuctRunLocation.Basement]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.28, [4]: 0.18, [8]: 0.16},
        [5]: {[0]: 0.19, [4]: 0.10, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.24, [4]: 0.17, [8]: 0.15},
        [5]: {[0]: 0.16, [4]: 0.09, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.23, [4]: 0.17, [8]: 0.16},
        [5]: {[0]: 0.14, [4]: 0.09, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.20, [4]: 0.16, [8]: 0.15},
        [5]: {[0]: 0.12, [4]: 0.08, [8]: 0.07},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
  [DuctRunLocation.CrawlSpace]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.49, [4]: 0.29, [8]: 0.25},
        [5]: {[0]: 0.34, [4]: 0.16, [8]: 0.13},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.41, [4]: 0.26, [8]: 0.24},
        [5]: {[0]: 0.27, [4]: 0.14, [8]: 0.12},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.56, [4]: 0.37, [8]: 0.34},
        [5]: {[0]: 0.34, [4]: 0.19, [8]: 0.16},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.49, [4]: 0.35, [8]: 0.33},
        [5]: {[0]: 0.28, [4]: 0.17, [8]: 0.15},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
};

let DuctGainFactors = {
  [DuctRunLocation.Attic]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 1.26, [4]: 0.71, [8]: 0.63},
        [5]: {[0]: 0.68, [4]: 0.33, [8]: 0.27},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 1.02, [4]: 0.66, [8]: 0.6},
        [5]: {[0]: 0.53, [4]: 0.29, [8]: 0.25},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 1.26, [4]: 0.71, [8]: 0.63},
        [5]: {[0]: 0.68, [4]: 0.33, [8]: 0.27},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 1.02, [4]: 0.66, [8]: 0.6},
        [5]: {[0]: 0.53, [4]: 0.29, [8]: 0.25},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
  [DuctRunLocation.Basement]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.12, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.07, [4]: 0.05, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.11, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.06, [4]: 0.04, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.12, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.07, [4]: 0.05, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.11, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.06, [4]: 0.04, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
  [DuctRunLocation.CrawlSpace]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.16, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.10, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.14, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.08, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.16, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.10, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.14, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.08, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
};

export class HouseMiscDetails {
  init() {
    this.ductRunLocation = new Field({
      name: 'Primary location of duct runs',
      type: FieldType.Select,
      choices: makeOptions(DuctRunLocation),
    })
    this.typicalLeakageRateEntry = new Field({
      name: 'Typical leakage rate',
      type: FieldType.Select,
      choices: makeOptions(UnknownOrEnter),
    })
    this.typicalLeakageRate = new Field({
      name: 'Typical leakage rate',
      type: FieldType.Percent,
      showName: false,
      defaultValue: 8,
    })
    this.typicalLeakageRate.makeUpdater((field) => {
      field.visible = this.typicalLeakageRateEntry.value == UnknownOrEnter.Enter;
    })
    this.ductInsulation = new Field({
      name: 'Duct insulation',
      type: FieldType.Insulation,
      defaultValue: 0,
    })
    this.systemType = new Field({
      name: 'System type',
      type: FieldType.Select,
      choices: makeOptions(SystemType),
    })

    this.serFields = [
      'ductRunLocation',
      'typicalLeakageRateEntry',
      'typicalLeakageRate',
      'ductInsulation',
      'systemType',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'System Details',
    }
  }

  _calcF_dl(ctx, isHeating) {
    ctx.startContext();

    let ductFactors = isHeating ? DuctLossFactors : DuctGainFactors;

    if (ctx.ductRunLocation == DuctRunLocation.DuctsNotUsed) {
      ctx.F_dl = 0;
    } else if (ctx.ductRunLocation == DuctRunLocation.ConditionedSpace) {
      ctx.F_dl = 0;
    } else if (ctx.ductRunLocation == DuctRunLocation.Unknown) {
      // When the location is unknown, take the average of
      // the results of all 3 locations.
      let locations = [
        DuctRunLocation.Attic,
        DuctRunLocation.Basement,
        DuctRunLocation.CrawlSpace,
      ]
      let F_dl_arr = [];
      for (let loc of locations) {
        ctx.loc = loc;
        ctx.factors = lookupData(ductFactors, [
          ctx.loc,
          ctx.systemType,
          ctx.N_stories <= 1 ? 1 : 2,
        ]);
        ctx.F_dl = doubleInterpolateInMap(ctx.factors,
          ctx.typicalLeakageRate, ctx.ductInsulation);
        F_dl_arr.push({F_dl: ctx.F_dl});
      }
      ctx.F_dl_sum = ctx.evalSum(F_dl_arr, 'F_dl', 'F_dl_sum')
      ctx.F_dl = ctx.eval('F_dl_sum / cnt', {cnt: locations.length}, 'F_dl')
    } else {
      ctx.factors = lookupData(ductFactors, [
        ctx.ductRunLocation,
        ctx.systemType,
        ctx.N_stories <= 1 ? 1 : 2,
      ]);
      ctx.F_dl = doubleInterpolateInMap(ctx.factors,
        ctx.typicalLeakageRate, ctx.ductInsulation);
    }
    let F_dl = ctx.F_dl;
    ctx.endContext();
    return F_dl;
  }

  calcOutputs(ctx) {
    ctx.startSection('System Details')

    ctx.ductRunLocation = this.ductRunLocation.value;
    ctx.typicalLeakageRate = this.typicalLeakageRateEntry.value ==
        UnknownOrEnter.Enter ? this.typicalLeakageRate.value : 8.0;
    ctx.ductInsulation = this.ductInsulation.value;
    ctx.systemType = this.systemType.value;
    ctx.N_stories = ctx.toplevelData.numberStoreys;

    let totalSpaceLoad = ctx.res.totalSpaceLoad;
    ctx.startSection('Heating')
    ctx.F_dl_heating = this._calcF_dl(ctx, true);
    let res_heating_sensible = ctx.eval('SpaceLoad * F_dl_heating',
      {SpaceLoad: totalSpaceLoad.heating.sensible},
      'res_cooling_sensible');
    let res_heating_latent = ctx.eval('SpaceLoad * F_dl_heating',
      {SpaceLoad: totalSpaceLoad.heating.latent},
      'res_cooling_latent');
    ctx.endSection();

    ctx.startSection('Cooling')
    ctx.F_dl_cooling = this._calcF_dl(ctx, false);
    let res_cooling_sensible = ctx.eval('SpaceLoad * F_dl_cooling',
      {SpaceLoad: totalSpaceLoad.cooling.sensible},
      'res_cooling_sensible');
    let res_cooling_latent = ctx.eval('SpaceLoad * F_dl_cooling',
      {SpaceLoad: totalSpaceLoad.cooling.latent},
      'res_cooling_latent');
    ctx.endSection();

    ctx.res.distributionLoads = {
      cooling: {
        sensible: res_cooling_sensible,
        latent: res_cooling_latent,
      },
      heating: {
        sensible: res_heating_sensible,
        latent: res_heating_latent,
      },
    };
    
    ctx.endSection();
  }
}
setupClass(HouseMiscDetails)

export function lookupLocationData(locPath) {
  console.log("LocPath: ", locPath);
  let locList = WeatherDataMap;
  for (const part of locPath) {
    let nextElem = null;
    for (const locElem of locList.children) {
      if (locElem.name == part) {
        nextElem = loc;
        break;
      }
    }
  }
  return locList;
}

export class LocationData {
  init() {
    this.locPath = null;
    this.locData = null;

    this.timezone = new Field({
      name: 'Time zone',
      type: FieldType.Count,
      min: -16,
    })
    this.latitude = new Field({
      name: 'Latitude',
      type: FieldType.Angle,
      min: -90,
      max: 90,
    })
    this.longitude = new Field({
      name: 'Longitude',
      type: FieldType.Angle,
      min: -180,
      max: 180,
    })
    this.elevation = new Field({
      name: 'Elevation',
      type: FieldType.Length,
      min: 0,
    })
    this.stdPressure = new Field({
      name: 'Pressure',
      type: FieldType.Pressure,
    })
    this.heating99p6PerDryBulb = new Field({
      name: 'Heating - 99.6% DB',
      type: FieldType.Temperature,
    })
    this.heating99PerDryBulb = new Field({
      name: 'Heating - 99% DB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerDryBulb = new Field({
      name: 'Cooling - 0.4% DB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerDryBulbMCWB = new Field({
      name: 'Cooling - 0.4% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling1PerDryBulb = new Field({
      name: 'Cooling - 1% DB',
      type: FieldType.Temperature,
    })
    this.cooling1PerDryBulbMCWB = new Field({
      name: 'Cooling - 1% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling2PerDryBulb = new Field({
      name: 'Cooling - 2% DB',
      type: FieldType.Temperature,
    })
    this.cooling2PerDryBulbMCWB = new Field({
      name: 'Cooling - 2% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerWetBulb = new Field({
      name: 'Cooling - 0.4% WB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerWetBulbMCDB = new Field({
      name: 'Cooling - 0.4% WB MCDB',
      type: FieldType.Temperature,
    })
    this.avgAnnualTemp = new Field({
      name: 'Average Annual Dry Bulb',
      type: FieldType.Temperature,
    })
    this.meanDailyDryBulbRange = new Field({
      name: 'Mean Daily Dry Bulb Range',
      type: FieldType.Temperature,
    })
    this.Tau_b = new Field({
      name: 'Solar Irradiance (Tau b)',
      type: FieldType.Count,
    })
    this.Tau_d = new Field({
      name: 'Solar Irradiance (Tau d)',
      type: FieldType.Count,
    })
    this.k_soil = new Field({
      name: 'Soil Conductivity',
      type: FieldType.SoilConductivity,
      // This is the standard value
      value: 0.8,
    })

    this.fields = [
      'timezone',
      'latitude',
      'longitude',
      'elevation',
      'stdPressure',

      'heating99p6PerDryBulb',
      'heating99PerDryBulb',
      'cooling0p4PerDryBulb',
      'cooling0p4PerDryBulbMCWB',
      'cooling1PerDryBulb',
      'cooling1PerDryBulbMCWB',
      'cooling2PerDryBulb',
      'cooling2PerDryBulbMCWB',
      'cooling0p4PerWetBulb',
      'cooling0p4PerWetBulbMCDB',

      'avgAnnualTemp',
      'meanDailyDryBulbRange',
      'Tau_b',
      'Tau_d',
      'k_soil',
    ]
    for (const fieldName of this.fields) {
      // Add aux data to track defaults
      let field = this[fieldName];
      field.data = {
        defaultValue: field.value,
        useDefault: true,
      }
    }

    this.errorsDict = {};
    ObjectUtils.makeUpdater(this, () => {
      if (!this.locData) {
        return;
      }
      let hottestMonth = this.locData.Cooling['Hottest Month'];
      let monthName = LocationData._getMonthName(hottestMonth);

      this.timezone.data.defaultValue = this.locData['Location']['Time Zone']
      this.latitude.data.defaultValue = this.locData['Location']['Latitude']
      this.longitude.data.defaultValue = this.locData['Location']['Longitude']
      this.elevation.data.defaultValue = this.locData['Location']['Elevation']
      this.stdPressure.data.defaultValue = this.locData['Location']['Std. Pres']
      
      this.heating99p6PerDryBulb.data.defaultValue = this.locData['Heating']['99.6 Dry Bulb']
      this.heating99PerDryBulb.data.defaultValue = this.locData['Heating']['99% Dry Bulb']

      this.cooling0p4PerDryBulb.data.defaultValue = this.locData['Cooling']['0.4% Dry Bulb']
      this.cooling0p4PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['0.4% Dry Bulb MCWB']
      this.cooling1PerDryBulb.data.defaultValue = this.locData['Cooling']['1% Dry Bulb']
      this.cooling1PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['1% Dry Bulb MCWB']
      this.cooling2PerDryBulb.data.defaultValue = this.locData['Cooling']['2% Dry Bulb']
      this.cooling2PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['2% Dry Bulb MCWB']
      this.cooling0p4PerWetBulb.data.defaultValue = this.locData['Cooling']['0.4% Wet Bulb']
      this.cooling0p4PerWetBulbMCDB.data.defaultValue = this.locData['Cooling']['0.4% Wet Bulb MCDB']

      this.avgAnnualTemp.data.defaultValue = this.locData.Temperatures['Average Dry Bulb']['Annual'];
      this.meanDailyDryBulbRange.data.defaultValue = this.locData.MeanDailyTempRange['Mean Dry Bulb Range'][monthName];
      this.Tau_b.data.defaultValue = this.locData.SolarIrradiance['Tau,b'][monthName];
      this.Tau_d.data.defaultValue = this.locData.SolarIrradiance['Tau,d'][monthName];
    })

    this.serFields = [
      'locPath',
      'locData',
      ...this.fields,
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Location Info',
    }
  }

  async setLocation(locPath) {
    if (!locPath) {
      return;
    }
    console.log("Reloading location data!: ", locPath);
    await this.reloadData(locPath);
  }

  getObjErrors() {
    let errors = [];
    ObjectUtils.addErrorsFromDict(errors, this.errorsDict);
    if (this.locData === null) {
      errors.push('Please set a location.');
    }
    return errors;
  }

  static _getMonthName(monthNum) {
    let months =  [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'November',
      'December',
    ]
    return months[monthNum - 1]
  }

  getOutputs() {
    let hottestMonth = this.locData.Cooling['Hottest Month'];
    let outputs = {
      hottestMonth,
    }
    for (const fieldName of this.fields) {
      let field = this[fieldName];
      outputs[fieldName] = field.data.useDefault ? field.data.defaultValue : field.value;
    }
    return outputs;
  }

  async reloadData(locPath) {
    console.log("Reloading weather data CSV. LocPath: ", locPath);
    let filePath = 'WeatherData/' + locPath.path.join("/") + ".csv"
    console.log("FilePath: " + filePath)
    let res = await fetch(filePath);
    let text = await res.text();
    this.locData = LocationData.parseWeatherDataCSV(text);
    this.locPath = locPath;
  }

  static _findFirstRow(csv, tableName) {
    for (let i = 0; i < csv.data.length; ++i) {
      if (csv.data[i][0] == tableName) {
        return i + 1;
      }
    }
    throw new Error(`Could not find table ${tableName} in the WeatherData`);
  }

  // Returns one row past the end of the table
  static _findRowAfterEnd(csv, tableName) {
    let firstRow = this._findFirstRow(csv, tableName)
    let curRow = firstRow + 1;
    while (csv.data[curRow][0] != "") {
      curRow++;
    }
    return curRow;
  }

  static _findFirstNonEmptyRow(csv, startRow) {
    let curRow = startRow;
    while (csv.data[curRow][0] == "") {
      curRow++;
    }
    return curRow;
  }

  static _readCell(strValue) {
    // Will return NaN if the string is not a number
    let numVal = Number(strValue)
    return isNaN(numVal) ? strValue : numVal;
  }

  static _parseSimpleTable(csv, tableName, opts) {
    opts = valOr(opts, {})
    let firstRow = this._findFirstRow(csv, tableName)
    let obj = {}
    // The full table may be split into multiple tables if there are many columns (see Cooling)
    let numTables = valOr(opts.numTables, 1)
    let tableStartRow = firstRow;
    for (let tableNum = 0; tableNum < numTables; ++tableNum) {
      let colNames = []
      for (let i = 0; i < csv.data[tableStartRow].length; ++i) {
        let colName = csv.data[tableStartRow][i];
        if (!colName) {
          break;
        }
        colNames.push(colName);
      }
      // console.log(`Row: ${tableStartRow}, Columns: ${prettyJson(colNames)}`)
      for (let i = 0; i < colNames.length; ++i) {
        obj[colNames[i]] = this._readCell(csv.data[tableStartRow + 1][i])
      }
      
      tableStartRow = this._findFirstNonEmptyRow(csv, tableStartRow + 2);
    }
    // console.log(`Parsed ${tableName}:\n${prettyJson(obj)}`);
    return obj;
  }

  static _parseRowColTable(csv, tableName, opts) {
    let firstRow = this._findFirstRow(csv, tableName)
    let colNames = []
    for (let i = 1; i < csv.data[firstRow].length; ++i) {
      let colName = csv.data[firstRow][i];
      if (!colName) {
        break;
      }
      colNames.push(colName);
    }
    // console.log(`Columns: ${prettyJson(colNames)}`)
    let obj = {}
    let curRow = firstRow + 1;
    while (csv.data[curRow][0] != "") {
      let rowName = csv.data[curRow][0];
      obj[rowName] = {};
      for (let i = 0; i < colNames.length; ++i) {
        obj[rowName][colNames[i]] = this._readCell(csv.data[curRow][i + 1])
      }
      curRow++;
    }
    // console.log(`Parsed ${tableName}:\n${prettyJson(obj)}`);
    return obj;
  }

  static _parseTable(csv, tableName, rowColTable, opts) {
    if (!rowColTable) {
      return this._parseSimpleTable(csv, tableName, opts);
    } else {
      return this._parseRowColTable(csv, tableName, opts);
    }
  }

  static parseWeatherDataCSV(rawCsv) {
    let csv = Papa.parse(rawCsv);
    let weatherData = {}
    weatherData.Location = this._parseTable(csv, 'Location')
    weatherData.Heating = this._parseTable(csv, 'Heating')
    weatherData.Cooling = this._parseTable(csv, 'Cooling', false, {numTables: 2})
    weatherData.ExtremeDesignConditions = this._parseTable(csv, 'Extreme Design Conditions')
    // TODO - skipping the untitled table under ExtremeDesignConditions for now
    weatherData.Temperatures = this._parseTable(csv, 'Temperature, Degree-days, Degree-hours', true)
    weatherData.Wind = this._parseTable(csv, 'Wind', true)
    weatherData.Precipitation = this._parseTable(csv, 'Precipitation', true)
    weatherData.DryBulb = this._parseTable(csv, 'Dry Bulb and Mean Coincident Wet Bulb', true)
    weatherData.WetBulb = this._parseTable(csv, 'Wet Bulb and Mean Coincident Dry Bulb', true)
    weatherData.MeanDailyTempRange = this._parseTable(csv, 'Mean Daily Temperature Range', true)
    weatherData.SolarIrradiance = this._parseTable(csv, 'Solar Irradiance', true)

    // console.log(`Read weather data:\n${prettyJson(weatherData)}`);
    return weatherData;
  }
};
setupClass(LocationData)


export class ToplevelData {
  init() {
    this.locationData = LocationData.create()
    this.projectUnits = Field.makeSelect('Project Units', ProjectUnits)

    this.numberBedrooms = new Field({
      name: 'Number Bedrooms',
      type: FieldType.Count,
      defaultValue: 2,
      min: 0,
    });
    this.numberStoreys = new Field({
      name: 'Number Storeys',
      type: FieldType.Count,
      defaultValue: 1,
      min: 0,
    });
    this.totalFloorArea = new Field({
      name: 'Total Floor Area',
      type: FieldType.Area,
      defaultValue: 1000,
    });
    this.averageCeilingHeight = new Field({
      name: 'Average Ceiling Height',
      type: FieldType.Length,
      defaultValue: 9,
    });
    this.totalBuildingVolume = new Field({
      name: 'Total Building Volume',
      type: FieldType.Volume,
      defaultValue: 9000,
    });
    this.indoorWinterTemp = new Field({
      name: 'Indoor Winter Temperature',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.indoorWinterHumidity = new Field({
      name: 'Indoor Winter Humidity',
      type: FieldType.Percent,
      defaultValue: 30,
    })
    this.indoorSummerTemp = new Field({
      name: 'Indoor Summer Temperature',
      type: FieldType.Temp,
      defaultValue: 75,
    });
    this.indoorSummerHumidity = new Field({
      name: 'Indoor Summer Humidity',
      type: FieldType.Percent,
      defaultValue: 50,
    })

    this.basicFields = [
      'numberBedrooms',
      'numberStoreys',
      'totalFloorArea',
      'averageCeilingHeight',
      'totalBuildingVolume',
    ]
    this.tempFields = [
      'indoorWinterTemp',
      'indoorWinterHumidity',
      'indoorSummerTemp',
      'indoorSummerHumidity',
    ]

    this.fields = [
      ...this.basicFields,
      ...this.tempFields,
    ];

    this.serFields = [
      'locationData',
      'projectUnits',
      ...this.fields,
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Project',
    }
  }

  calcOutputs(ctx) {
    ctx.startSection('ToplevelData');
    let data = {
      locationData: this.locationData.getOutputs(),
    };
    for (const fieldName of this.fields) {
      if (!(fieldName in data)) {
        data[fieldName] = this[fieldName].value;
      }
    }
    ctx.toplevelData = data;
    ctx.endSection();
  }
}
setupClass(ToplevelData)

export let HeatingDesignTemps = makeEnum({
  Temp99p6: '99.6% Dry Bulb',
  Temp99p0: '99% Dry Bulb',
})

export let CoolingDesignTemps = makeEnum({
  Temp0p4: '0.4% Dry Bulb',
  Temp1p0: '1% Dry Bulb',
  Temp2p0: '2% Dry Bulb',
})

/*
Used to toggle the design temperatures used to calculate the outputs
*/
export class DesignTempInputs {
  init() {
    this.heatingDesignTemp = Field.makeSelect('Heating Design Temp.', HeatingDesignTemps)
    this.coolingDesignTemp = Field.makeSelect('Cooling Design Temp.', CoolingDesignTemps)

    this.serFields = [
      'heatingDesignTemp',
      'coolingDesignTemp',
    ]
  }

  calcOutputs(ctx) {
    ctx.startSection('Design Temps');

    let weatherData = ctx.toplevelData.locationData;

    let heatingTempMap = {
      [HeatingDesignTemps.Temp99p6]: {dryBulb: weatherData.heating99p6PerDryBulb},
      [HeatingDesignTemps.Temp99p0]: {dryBulb: weatherData.heating99PerDryBulb},
    }
    let heatingTemps = lookupData(heatingTempMap, [this.heatingDesignTemp.value])

    let coolingTempMap = {
      [CoolingDesignTemps.Temp0p4]: {
        dryBulb: weatherData.cooling0p4PerDryBulb,
        mcwb: weatherData.cooling0p4PerDryBulbMCWB,
      },
      [CoolingDesignTemps.Temp1p0]: {
        dryBulb: weatherData.cooling1PerDryBulb,
        mcwb: weatherData.cooling1PerDryBulbMCWB,
      },
      [CoolingDesignTemps.Temp2p0]: {
        dryBulb: weatherData.cooling2PerDryBulb,
        mcwb: weatherData.cooling2PerDryBulbMCWB,
      }
    }
    let coolingTemps = lookupData(coolingTempMap, [this.coolingDesignTemp.value])

    ctx.designTemps = {
      heating: heatingTemps.dryBulb,
      cooling: coolingTemps.dryBulb,
      coolingMCWB: coolingTemps.mcwb,
    }
    ctx.endSection();
  }
}
setupClass(DesignTempInputs)

