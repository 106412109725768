import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, } from './Field.js'
import { YesNo, makeNoYesField } from './Components.js'

import { gApp, DebugOn } from './State.js'

export class ZoneSpace {
  init() {
    this.spaceType = Field.makeTypeSelect('Type', gApp.proj().spaces, null, {
      errorWhenEmpty: `You must create a Space`,
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
    })

    this.serFields = [
      'spaceType',
      'quantity',
    ]
    this.childObjs = '$auto'
  }
}
setupClass(ZoneSpace)

export class Zone {
  init(name) {
    this.name = Field.makeName(`Zone Name`, name)
    this.spaces = []

    this.winterTempOccupied = new Field({
      name: 'Winter Temp (Occupied)',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.winterTempUnoccupied = new Field({
      name: 'Winter Temp (Unoccupied)',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.summerTempOccupied = new Field({
      name: 'Summer Temp (Occupied)',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.summerTempUnoccupied = new Field({
      name: 'Summer Temp (Unoccupied)',
      type: FieldType.Temp,
      defaultValue: 70,
    });

    this.humiditySetpoint = new Field({
      name: 'Humidity Setpoint',
      type: FieldType.Percent,
    });
    this.addWinterHumidification = makeNoYesField('Add Winter Humidification');
    this.winterHumiditySetpoint = new Field({
      name: 'Winter Humidity Setpoint',
      type: FieldType.Percent,
    });
    this.winterHumiditySetpoint.setVisibility(() => {
      return this.addWinterHumidification.value == YesNo.Yes;
    })

    this.tempFields = [
      'winterTempOccupied',
      'winterTempUnoccupied',
      'summerTempOccupied',
      'summerTempUnoccupied',
      'humiditySetpoint',
      'addWinterHumidification',
      'winterHumiditySetpoint',
    ]

    this.serFields = [
        'name',
        ser.arrayField('spaces', () => { return ZoneSpace.create(); }),
        'winterTempOccupied',
        'winterTempUnoccupied',
        'summerTempOccupied',
        'summerTempUnoccupied',
        'humiditySetpoint',
        'addWinterHumidification',
        'winterHumiditySetpoint',
    ]
    this.childObjs = '$auto'
  }
}
setupClass(Zone)