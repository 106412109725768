import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'

import { prettyJson, clearArray, valOr, getElemNames,
  getElemWithNameValue,
  addElem, removeElem, elemIn,
  extendArray, extendMap, isObject,
  downloadTextFile,
  runAfterDelay, waitMillis, awaitAll,
  valueOrFuncRes,
} from './SharedUtils.js'
export { Units } from './Units.js'

import {
  kEpsilon,
  FieldType,
  kFieldTypesData,
  FieldInputType,
  Field,
  FieldGroup,
  ProjectUnits,
  SelectOrManualInput,
} from './Field.js'

import { LocationData } from './Components.js'

import { gApp, DebugOn, kDirectionChoices } from './State.js'

export let ShelterType = makeEnum({
    LargeCityCenter: 'Large city center',
    DenseAreas: 'Dense areas',
    OpenTerrain: 'Open terrain',
    Unobstructed: 'Unobstructed areas',
})

export let GroundType = makeEnumWithDataAndLabels({
  Water: {
      label: 'Water - 0.07',
      value: 0.07
  },
  ConiferousForestSnowy: {
      label: 'Coniferous forest (snowy) - 0.07',
      value: 0.07
  },
  AsphaltNew: {
      label: 'Asphalt, new - 0.05',
      value: 0.05
  },
  AsphaltWeathered: {
      label: 'Asphalt, weathered - 0.1',
      value: 0.1
  },
  BituminousAndGravelRoof: {
      label: 'Bituminous and gravel roof - 0.13',
      value: 0.13
  },
  DryBareGround: {
      label: 'Dry bare ground - 0.2',
      value: 0.2
  },
  WeatheredConcrete: {
      label: 'Weathered concrete - 0.3',
      value: 0.3
  },
  GreenGrass: {
      label: 'Green Grass - 0.26',
      value: 0.26
  },
  DryGrassland: {
      label: 'Dry grassland - 0.3',
      value: 0.3
  },
  DesertSand: {
      label: 'Desert sand - 0.4',
      value: 0.4
  },
  LightBuildingSurfaces: {
      label: 'Light building surfaces - 0.6',
      value: 0.6
  },
  SnowCoveredCityCenter: {
      label: 'Snow-covered city center - 0.2',
      value: 0.2
  },
  SnowCoveredUrbanSite: {
      label: 'Snow-covered urban site - 0.4',
      value: 0.4
  },
  SnowCoveredRuralSite: {
      label: 'Snow-covered rural site - 0.5',
      value: 0.5
  },
  SnowCoveredIsolatedRuralSite: {
      label: 'Snow-covered isolated rural site - 0.7',
      value: 0.7
  },
  Manual: {
      label: 'Enter manually',
      value: 0
  },
});

export class BuildingGroundTypes {
    init() {
        // We want a ground type for North, East, South, West
        let makeGroundInput = (name) => {
            return SelectOrManualInput.create(name, GroundType, FieldType.Ratio)
        };
        this.northType = makeGroundInput('North')
        this.eastType = makeGroundInput('East')
        this.southType = makeGroundInput('South')
        this.westType = makeGroundInput('West')

        this.fields = [
            'northType',
            'eastType',
            'southType',
            'westType',
        ]

        this.serFields = [
            'northType',
            'eastType',
            'southType',
            'westType',
        ]
        this.childObjs = '$auto'
    }

    getOutputs() {
        /*
        Make sure we have a reflectance for each possible value:
        'N', 'NNE', 'NE',
        'ENE', 'E', 'ESE',
        'SE', 'SSE', 'S',
        'SSW', 'SW', 'WSW',
        'W', 'WNW', 'NW',
        'NNW',
        */
        let directionWeights = {
            N: {N: 1.0},
            NNE: {N: 0.5, E: 0.5},
            NE: {N: 0.5, E: 0.5},
            ENE: {E: 0.5, N: 0.5},
            E: {E: 1.0},
            ESE: {E: 0.5, S: 0.5},
            SE: {S: 0.5, E: 0.5},
            SSE: {S: 0.5, E: 0.5},
            S: {S: 1.0},
            SSW: {S: 0.5, W: 0.5},
            SW: {S: 0.5, W: 0.5},
            W: {W: 1.0},
            WNW: {W: 0.5, N: 0.5},
            NW: {N: 0.5, W: 0.5},
            NNW: {N: 0.5, W: 0.5},
        }
        // When the direction is between two values, we interpolate the reflectance
        let reflectances = {}
        let cardinalValues = {
            N: this.northType.value,
            E: this.eastType.value,
            S: this.southType.value,
            W: this.westType.value,
        }
        for (const [dir, weights] of Object.entries(directionWeights)) {
            let reflectance = 0.0
            for (const [cardinalDir, weight] of Object.entries(weights)) {
                reflectance += weight * cardinalValues[cardinalDir]
            }
            reflectances[dir] = reflectance
        }
        return reflectances
    }
}
setupClass(BuildingGroundTypes)

export class BuildingAndEnv {
  init() {
    this.locationData = LocationData.create()

    this.shelterType = Field.makeSelect('Shelter Type', ShelterType)

    this.summerGroundTypes = BuildingGroundTypes.create()
    this.winterGroundTypes = BuildingGroundTypes.create()

    this.serFields = [
        'locationData',
        'shelterType',
        'summerGroundTypes',
        'winterGroundTypes',
    ]
    this.childObjs = '$auto'
  }

  getLocationData() {
    return this.locationData
  }

  getOutputs() {
    return {
        summerGroundReflectances: this.summerGroundTypes.getOutputs(),
    }
  }
}
setupClass(BuildingAndEnv)