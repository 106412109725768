
export const ActivityLevelData = {
    "Seated": {
        "Location": "Theater",
        "Total Heat, Adult Male, Btu/h": 390,
        "Total Heat, Adjusted, M/F, Btu/h": 350,
        "Sensible Heat, Btu/h": 245,
        "Latent Heat, Btu/h": 105,
        "% Sensible Heat that is Radiant, Low Velocity air": 60,
        "% Sensible Heat that is Radiant, High Velocity air": 27,
        "Adjusted, M/F": 43.5
    },
    "Seated, very light work": {
        "Location": "Offices, hotels, apartments",
        "Total Heat, Adult Male, Btu/h": 450,
        "Total Heat, Adjusted, M/F, Btu/h": 400,
        "Sensible Heat, Btu/h": 245,
        "Latent Heat, Btu/h": 155,
        "% Sensible Heat that is Radiant, Low Velocity air": 60,
        "% Sensible Heat that is Radiant, High Velocity air": 27,
        "Adjusted, M/F": 43.5
    },
    "Moderately active office work": {
        "Location": "Offices, hotels, apartments",
        "Total Heat, Adult Male, Btu/h": 475,
        "Total Heat, Adjusted, M/F, Btu/h": 450,
        "Sensible Heat, Btu/h": 250,
        "Latent Heat, Btu/h": 200,
        "% Sensible Heat that is Radiant, Low Velocity air": 60,
        "% Sensible Heat that is Radiant, High Velocity air": 27,
        "Adjusted, M/F": 43.5
    },
    "Standing, light work; walking": {
        "Location": "Department store; retail store",
        "Total Heat, Adult Male, Btu/h": 550,
        "Total Heat, Adjusted, M/F, Btu/h": 450,
        "Sensible Heat, Btu/h": 250,
        "Latent Heat, Btu/h": 200,
        "% Sensible Heat that is Radiant, Low Velocity air": 58,
        "% Sensible Heat that is Radiant, High Velocity air": 38,
        "Adjusted, M/F": 48
    },
    "Walking, standing": {
        "Location": "Drug store, bank",
        "Total Heat, Adult Male, Btu/h": 550,
        "Total Heat, Adjusted, M/F, Btu/h": 500,
        "Sensible Heat, Btu/h": 250,
        "Latent Heat, Btu/h": 250,
        "% Sensible Heat that is Radiant, Low Velocity air": 58,
        "% Sensible Heat that is Radiant, High Velocity air": 38,
        "Adjusted, M/F": 48
    },
    "Sedentary work": {
        "Location": "Restaurantc",
        "Total Heat, Adult Male, Btu/h": 490,
        "Total Heat, Adjusted, M/F, Btu/h": 550,
        "Sensible Heat, Btu/h": 275,
        "Latent Heat, Btu/h": 275,
        "% Sensible Heat that is Radiant, Low Velocity air": 58,
        "% Sensible Heat that is Radiant, High Velocity air": 38,
        "Adjusted, M/F": 48
    },
    "Light bench work": {
        "Location": "Factory",
        "Total Heat, Adult Male, Btu/h": 800,
        "Total Heat, Adjusted, M/F, Btu/h": 750,
        "Sensible Heat, Btu/h": 275,
        "Latent Heat, Btu/h": 475,
        "% Sensible Heat that is Radiant, Low Velocity air": 58,
        "% Sensible Heat that is Radiant, High Velocity air": 38,
        "Adjusted, M/F": 48
    },
    "Moderate dancing": {
        "Location": "Dance hall",
        "Total Heat, Adult Male, Btu/h": 900,
        "Total Heat, Adjusted, M/F, Btu/h": 850,
        "Sensible Heat, Btu/h": 305,
        "Latent Heat, Btu/h": 545,
        "% Sensible Heat that is Radiant, Low Velocity air": 49,
        "% Sensible Heat that is Radiant, High Velocity air": 35,
        "Adjusted, M/F": 42
    },
    "Walking 3 mph; light machine work": {
        "Location": "Factory",
        "Total Heat, Adult Male, Btu/h": 1000,
        "Total Heat, Adjusted, M/F, Btu/h": 1000,
        "Sensible Heat, Btu/h": 375,
        "Latent Heat, Btu/h": 625,
        "% Sensible Heat that is Radiant, Low Velocity air": 49,
        "% Sensible Heat that is Radiant, High Velocity air": 35,
        "Adjusted, M/F": 42
    },
    "Bowling": {
        "Location": "Bowling alley",
        "Total Heat, Adult Male, Btu/h": 1500,
        "Total Heat, Adjusted, M/F, Btu/h": 1450,
        "Sensible Heat, Btu/h": 580,
        "Latent Heat, Btu/h": 870,
        "% Sensible Heat that is Radiant, Low Velocity air": 49,
        "% Sensible Heat that is Radiant, High Velocity air": 35,
        "Adjusted, M/F": 42
    },
    "Heavy work": {
        "Location": "Factory",
        "Total Heat, Adult Male, Btu/h": 1500,
        "Total Heat, Adjusted, M/F, Btu/h": 1450,
        "Sensible Heat, Btu/h": 580,
        "Latent Heat, Btu/h": 870,
        "% Sensible Heat that is Radiant, Low Velocity air": 54,
        "% Sensible Heat that is Radiant, High Velocity air": 19,
        "Adjusted, M/F": 36.5
    },
    "Heavy machine work; lifting": {
        "Location": "Factory",
        "Total Heat, Adult Male, Btu/h": 1600,
        "Total Heat, Adjusted, M/F, Btu/h": 1600,
        "Sensible Heat, Btu/h": 635,
        "Latent Heat, Btu/h": 965,
        "% Sensible Heat that is Radiant, Low Velocity air": 54,
        "% Sensible Heat that is Radiant, High Velocity air": 19,
        "Adjusted, M/F": 36.5
    },
    "Athletics": {
        "Location": "Gymnasium",
        "Total Heat, Adult Male, Btu/h": 2000,
        "Total Heat, Adjusted, M/F, Btu/h": 1800,
        "Sensible Heat, Btu/h": 710,
        "Latent Heat, Btu/h": 1090,
        "% Sensible Heat that is Radiant, Low Velocity air": 54,
        "% Sensible Heat that is Radiant, High Velocity air": 19,
        "Adjusted, M/F": 36.5
    }
};
