import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { Season, Wall, Roof, Partition, HouseFloor,
  AutomaticOrManual, YesNo, makeNoYesField, makeYesNoField } from './Components.js'
import { FieldWithVariableUnits } from './FieldUtils.js'
import { Units } from './Units.js'

import { gApp, DebugOn } from './State.js'

import { SpaceTypes, SpaceTypeCategory, GetSpaceTypesForCategory, DummySpaceData } from './SpaceTypes.js'
import { VentilationEffectivenessData, } from './VentilationEffectivenessData.js'
import { InfiltrationHours } from './Space.js'
import { SpaceInternals } from './SpaceInternals.js'
import { DataTable } from './DataTable.js'
import { CalcContext } from './CalcContext.js'
import { CalcPsychrometrics, PsyCalcMethod } from './Psychrometrics.js'
import * as solar from './SolarCalculations.js'

import { ActivityLevelData } from './ActivityLevelData.js'
import { MatrixUtils, makeVector, makeMonthVector, makeHourVector, } from './Math.js'
import * as math from 'mathjs'
import { optionsToEndpoint } from 'firebase-functions'

function getReversed(arr) {
  let copy = math.clone(arr)
  copy.reverse()
  return copy
}

// Make a true mod (unlike JS % operator, which handles -ves weird
// because it is a remainder operator, not a mod operator)
function mod(n, m) {
  return ((n % m) + m) % m;
}

export function reverseAboutStart(arr) {
  let copy = math.clone(arr)
  for (let i = 0; i < arr.length; ++i) {
    copy[i] = arr[mod(-i, arr.length)]
  }
  return copy
}

export class TimeSeriesUtils {
  static transformVec(ctx, vecToTransform, timeSeriesVec) {
    let numHrs = vecToTransform.length
    let reversedTimeSeriesVec = reverseAboutStart(timeSeriesVec)
    let res = makeVector(numHrs)
    for (let hr = 0; hr < numHrs; ++hr) {
      let adjustmentVec = makeVector(numHrs)
      for (let j = 0; j < numHrs; ++j) {
        adjustmentVec[(hr + j) % numHrs] = reversedTimeSeriesVec[j]
      }
      console.log("Adjustment vec:", hr, adjustmentVec)
      res[hr] = math.dot(vecToTransform, adjustmentVec)
    }
    return res
  }

  static transformMatrix(ctx, matrixToTransform, timeSeriesVec) {
    ctx.startSection("timeSeriesTransformMatrix")
    ctx.res = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      let adjustedVec = this.transformVec(ctx, MatrixUtils.getRow(matrixToTransform, i), timeSeriesVec)
      MatrixUtils.setRow(ctx.res, i, adjustedVec)
    }
    let res = ctx.res
    ctx.logLoadMatrix('res', res)
    ctx.endSection()
    return res
  }
}

export class SpaceCoolingCalculator {
  init(space, ctx) {
    this.space = space
    this.ctx = ctx
  }

  async calcInfiltrationLatentLoads() {
    let ctx = this.ctx
    ctx.setProgressText("Calculating infiltration latent loads")
    console.log("Calc infiltration latent loads")
    ctx.startSection("Infiltration Latent Loads")
    ctx.I_lat = math.zeros(12, 24)
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Summer);
    // TODO - what is the equation to adjust for altitude?
    ctx.C_l = 4840
    let onlyDuringOccupiedHours = this.space.infiltrationHours.value == InfiltrationHours.OccupiedHours;
    ctx.log(`Only during occupied hours: ${onlyDuringOccupiedHours}`)
    ctx.occupancySchedule = this.space.internals.people.getSchedule().getData();
    for (let month = 0; month < 12; month++) {
      ctx.startSection(`Month ${month}`)
      for (let hour = 0; hour < 24; hour++) {
        console.log(`Month ${month}, Hour ${hour}`)
        ctx.setProgressText(`Infiltration latent loads - Month ${month}, Hour ${hour}`)
        await ctx.briefWait()
        ctx.startSection(`Hour ${hour}`)
        if (onlyDuringOccupiedHours && ctx.occupancySchedule[hour] == 0) {
          ctx.log("Unoccupied hour")
          ctx.I_lat_item = 0;
        } else {
          ctx.W_in = ctx.call(CalcPsychrometrics, ctx.t_i, ctx.altitude,
            PsyCalcMethod.CalcWithRelativeHumidity, {
              RH: ctx.summerIndoorRH
            }
          ).W;
          // TODO - not yet impl
          ctx.log("W_out not yet implemented")
          ctx.W_out = -1
          ctx.I_lat_item = ctx.eval('Q_inf*C_l*(W_out - W_in)', {}, 'I_lat_item')
        }
        ctx.I_lat.set([month, hour], ctx.I_lat_item)
        ctx.endSection()
      }
      ctx.endSection()
    }
    ctx.logLoadMatrix('I_lat', ctx.I_lat)
    let res = ctx.I_lat
    ctx.endSection()
    return res
  }

  async calcApplianceLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating appliance latent loads")
    let applianceLoads = this.space.internals.appliances;
    ctx.startSection(`Appliance Latent Loads`)
    ctx.A_lat_row = new Array(24).fill(0);
    for (let i = 0; i < applianceLoads.appliances.length; i++) {
      ctx.log(`Appliance ${i}:`)
      let app = applianceLoads.appliances[i];
      ctx.quantity = app.getQuantity();
      ctx.A_lat_app = app.getLatentLoad(ctx);
      ctx.appSched = app.getSchedule().getData();
      for (let hr = 0; hr < 24; hr++) {
        let schedFactor = ctx.appSched[hr] > 0 ? 1 : 0;
        ctx.A_lat_row[hr] += ctx.quantity * ctx.A_lat_app * schedFactor * app.getDiversityFactor();
      }
    }
    // Same for each month:
    ctx.A_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.A_lat, i, ctx.A_lat_row)
    }
    ctx.logLoadMatrix('A_lat', ctx.A_lat)
    let res = ctx.A_lat
    ctx.endSection()
    return res;
  }

  async calcPeopleLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating people latent loads")
    ctx.startSection(`People Latent Loads`)
    let people = this.space.internals.people;
    ctx.sched = people.getSchedule().getData();
    ctx.M = people.getNumOccupants()
    ctx.activityLevel = people.activityLevel.value
    ctx.L = lookupData(ActivityLevelData, [ctx.activityLevel, 'Latent Heat, Btu/h'])
    ctx.P_lat_mo0 = ctx.eval(`sched * M * L`, {}, 'P_lat')
    // Same for each month:
    ctx.P_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.P_lat, i, ctx.P_lat_mo0)
    }
    ctx.logLoadMatrix('P_lat', ctx.P_lat)
    let res = ctx.P_lat
    ctx.endSection()
    return res
  }

  async calcMiscLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating misc latent loads")
    ctx.startSection(`Misc Latent Loads`)
    ctx.M_lat_row = new Array(24).fill(0);

    // Note: there is only one misc load entry for now
    let miscLoads = [this.space.internals.miscLoads];
    for (let i = 0; i < miscLoads.length; i++) {
      let misc = miscLoads[i];
      ctx.log(`Misc ${i}:`)
      ctx.M_entry = misc.getLatentLoad(ctx);
      ctx.entrySched = misc.getSchedule().getData();
      for (let hr = 0; hr < 24; hr++) {
        ctx.M_lat_row[hr] += ctx.M_entry * ctx.entrySched[hr] * misc;
      }
    }

    // Same for each month
    ctx.M_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.M_lat, i, ctx.M_lat_row)
    }

    ctx.logLoadMatrix('M_lat', ctx.M_lat)
    let res = ctx.M_lat
    ctx.endSection()
    return res
  }

  async calcWallLoads(wall) {
    let ctx = this.ctx
    ctx.startSection("Wall Loads")
    ctx.U = 1.0 / wall.getWallType().getRValue()
    ctx.alpha = wall.getWallType().getAbsorptance()
    ctx.A = wall.getStrictlyWallArea()
    ctx.W_loads = math.zeros(12, 24)
    for (let monthIndex = 0; monthIndex < 12; ++monthIndex) {
      for (let hrIndex = 0; hrIndex < 24; ++hrIndex) {
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hrIndex)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, monthIndex, hrIndex)
        let locData = ctx.toplevelData.locationData;
        ctx.groundReflectance = lookupData(ctx.buildingAndEnv.summerGroundReflectances, [wall.direction.value])
        ctx.E_t = ctx.call(solar.calc_E_t,
          locData.timeZone,
          hrIndex,
          ctx.toplevelData.dayOfYear,
          locData.latitude,
          locData.longitude, 
          locData.Tau_b,
          locData.Tau_d,
          wall.direction.value,
          wall.getTiltAngleDegs(),
          ctx.groundReflectance,
        );
        ctx.t_e = ctx.eval('t_out + alpha*E_t/h_o + epsilon*deltaR/h_o', {
          h_o: 3.0,
          epsilon: 1,
          deltaR: 0,
        }, 't_e')
        ctx.W_mo_hr = ctx.eval('U*A*(t_e - t_in)', {}, 'W_mo_hr')
        ctx.W_loads.set([monthIndex, hrIndex], ctx.W_mo_hr)
      }
    }
    let res = ctx.W_loads
    ctx.logLoadMatrix('W_loads', ctx.W_loads)
    ctx.endSection()
    return res
  }

  calc_Wall_P_mo(wall, monthIndex) {
    ctx.startSection("calc_Wall_P_mo")
    let ctx = this.ctx;
    ctx.Wall_P_mo = math.zeros(24, 24)
    for (let j = 0; j < 24; ++j) {
      //ctx.log(`Hour ${j}`)
      ctx.h_j = makeHourVector(j)
      //ctx.Wall_P_mo_j = ctx.eval('M_i*H_i', {}, 'Wall_P_mo_i')
      //ctx.Wall_P_mo.set([i, i], ctx.Wall_P_mo_i[monthIndex])
      // TODO - impl
    }
    let res = ctx.Wall_P_mo
    ctx.endSection()
    return res
  }

  lookupCTSValuesForWallType(wallType) {
    let ctx = this.ctx;

  }

  async calcWallCTS(wall) {
    let ctx = this.ctx;
    ctx.startSection("Calc CTS")
    ctx.Wall_CTS = math.zeros(12, 24)

    for (let i = 0; i < 12; ++i) {
      ctx.startSection(`Month ${i}`)
      ctx.M_i = makeMonthVector(i)
      ctx.Wall_P_i = this.calc_Wall_P_mo(wall, i)
      // TODO - where these come from?
      ctx.CTS = makeVector(24)
      ctx.Wall_CTS_i = ctx.eval('Wall_P_i*CTS', {}, 'Wall_CTS_i')
      ctx.Wall_CTS = ctx.Wall_CTS.add(ctx.Wall_CTS_i)
      ctx.endSection()
    }

    ctx.logLoadMatrix('Wall_CTS', ctx.Wall_CTS)
    let res = ctx.Wall_CTS
    ctx.endSection()
    return res
  }

  async calcWallSensibleLoads() {
    let ctx = this.ctx;
    ctx.startSection("Walls")

    let walls = this.space.walls;
    ctx.Walls_conv = math.zeros(12, 24)
    ctx.Walls_rad_nonsolar = math.zeros(12, 24)
    ctx.Walls_plenum = math.zeros(12, 24)
    for (let i = 0; i < walls.length; ++i) {
      let wall = walls[i];
      ctx.startSection(`Wall ${i}`)
      ctx.F_plenum = wall.getPlenumLoadFraction();
      ctx.Wall_CTS = this.calcWallCTS(wall);

      ctx.Wall_conv = ctx.eval('(1 - F_plenum)*Wall_CTS*F_conv_wall', {
        F_conv_wall: 0.54,
      }, 'W_conv')
      ctx.Walls_conv = ctx.Walls_conv.add(ctx.Wall_conv)

      ctx.Wall_rad_nonsolar = ctx.eval('(1 - F_plenum)*Wall_CTS*(1 - F_conv_wall)', {
        F_conv_wall: 0.54,
      }, 'W_rad_nonsolar')
      ctx.Walls_rad_nonsolar = ctx.Walls_rad_nonsolar.add(ctx.Wall_rad_nonsolar)

      ctx.Wall_plenum = ctx.eval('F_plenum*Wall_CTS', {}, 'W_plenum')
      ctx.Walls_plenum = ctx.Walls_plenum.add(ctx.Wall_plenum)

      ctx.endSection()
    }

    // TODO - Walls_rad_nosolar must go through the RTS transform

    ctx.logLoadMatrix('Walls_conv', ctx.Walls_conv)
    ctx.logLoadMatrix('Walls_rad_nonsolar', ctx.Walls_rad_nonsolar)
    ctx.logLoadMatrix('Walls_plenum', ctx.Walls_plenum)

    ctx.endSection()
  }

  async calcLatentLoads() {
    console.log("Calculating latent loads")
    let ctx = this.ctx;
    ctx.I_lat = await this.calcInfiltrationLatentLoads()
    await ctx.briefWait()
    ctx.A_lat = await this.calcApplianceLatentLoads()
    await ctx.briefWait()
    ctx.P_lat = await this.calcPeopleLatentLoads()
    await ctx.briefWait()
    ctx.M_lat = await this.calcMiscLatentLoads()
    await ctx.briefWait()
    ctx.space_lat = ctx.eval('I_lat + A_lat + P_lat + M_lat', {
    }, 'space_lat')
    ctx.logLoadMatrix('space_lat', ctx.space_lat)
  }

  async calcSensibleLoads() {
    let ctx = this.ctx;
    await this.calcWallSensibleLoads()
  }

  async calcOutputs() {
    let ctx = this.ctx;
    ctx.startSection('Space Cooling')

    // TODO - 
    //await this.calcLatentLoads()
    await this.calcSensibleLoads()

    ctx.endSection()
  }
};
setupClass(SpaceCoolingCalculator)
