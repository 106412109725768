import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, interpolateInMap, } from './Base.js'
import { prettyJson, valOr, } from './SharedUtils.js'

export let Units = makeEnumWithData({
  None: {
    name: 'None',
    label: '',
  },
  inches: {
    name: 'inches',
    label: 'in',
    conversions: {
      ft: 1.0 / 12.0,
      cm: 2.54,
    },
    metric: 'cm',
  },
  in2: {
    name: 'in2',
    label: 'in²',
    conversions: {
      ft2: 1.0 / (12.0 * 12.0),
      cm2: 2.54*2.54,
    },
    metric: 'cm2',
  },
  in3: {
    name: 'in3',
    label: 'in³',
    conversions: {
      ft3: 1.0 / (12.0*12.0*12.0),
      cm3: 2.54*2.54*2.54,
    },
    metric: 'cm3',
  },
  ft: {
    name: 'ft',
    label: 'ft',
    conversions: {
      m: 0.3048,
    },
    metric: 'm',
  },
  ft2: {
    name: 'ft2',
    label: 'ft²',
    conversions: {
      m2: 0.3048 * 0.3048,
    },
    metric: 'm2',
  },
  ft3: {
    name: 'ft3',
    label: 'ft³',
    conversions: {
      m3: 0.3048 * 0.3048 * 0.3048,
    },
    metric: 'm3',
  },
  cm: {
    name: 'cm',
    label: 'cm',
    conversions: {
      m: 1.0 / 100.0,
    },
  },
  cm2: {
    name: 'cm2',
    label: 'cm²',
    conversions: {
      m2: 1.0 / (100*100),
    },
  },
  cm3: {
    name: 'cm3',
    label: 'cm³',
    conversions: {
      m3: 1.0 / (100.0*100.0*100.0),
    },
  },
  m: {
    name: 'm',
    label: 'm',
  },
  m2: {
    name: 'm2',
    label: 'm²',
  },
  m3: {
    name: 'm3',
    label: 'm³',
  },
  F: {
    name: 'F',
    label: 'F',
    conversions: {
      C: (val) => {
        return (val - 32) * 5/9.0;
      },
    },
    metric: 'C',
  },
  C: {
    name: 'C',
    label: 'C',
    conversions: {
      F: (val) => {
        return val * 9/5.0 + 32;
      },
    },
  },
  // Rankine temp
  R: {
    name: 'R',
    label: 'R',
    conversions: {
      K: 5.0/9.0,
    },
    metric: 'K',
  },
  // Kelvin temp
  K: {
    name: 'K',
    label: 'K',
  },
  // Angle degrees
  Degrees: {
    name: 'degrees',
    label: '°',
  },
  Percent: {
    name: 'percent',
    label: '%',
  },
  Ratio: {
    name: 'ratio',
    label: '',
  },
  AirFlow: {
    name: 'cfm',
    label: 'cfm',
    conversions: {
      AirFlowMetric: 1.0/2.11888,
    },
    metric: 'AirFlowMetric',
  },
  AirFlowPerPerson: {
    name: 'cfm/person',
    label: 'cfm/person',
  },
  AirFlowPerArea: {
    name: 'cfm/ft2',
    label: 'cfm/ft²',
  },
  // Air changes / hr
  ACH: {
    name: 'ACH',
    label: 'ACH',
  },
  AirFlowMetric: {
    name: 'AirFlowMetric',
    label: 'L/s',
  },
  CfmPerFt2WallArea: {
    name: 'cfm/ft2_wallarea',
    label: 'cfm/ft² of wall area',
  },
  CfmPerFtCrackArea: {
    name: 'cfm/ft_crackarea',
    label: 'cfm/ft of crack area',
  },
  Load: {
    name: 'Btu/h',
    label: 'Btu/h',
    conversions: {
      LoadMetric: 1.0/3.415179,
    },
    metric: 'LoadMetric',
  },
  LoadPerArea: {
    name: 'Btu/h/ft2',
    label: 'Btu/h/ft²',
    conversions: {
      WattsPerFt2: 1.0/3.415179,
    },
    metric: 'WattsPerFt2',
  },
  WattsPerFt2: {
    name:  'W/ft2',
    label: 'W/ft²',
  },
  BtuPerFt2: {
    name: 'Btu/ft2',
    label: 'Btu/ft²',
  },
  LoadMetric: {
    name: 'LoadMetric',
    label: 'W',
  },
  // Gallons per minute
  GPM: {
    name: 'gpm',
    label: 'GPM',
  },
  PoundsPerMin: {
    name: 'lb/min',
    label: 'lb/min',
  },
  PoundsPerFt2: {
    name: 'lb/ft2',
    label: 'lb/ft²',
  },
  RValue: {
    name: 'RValue',
    label: 'F·ft²·h/Btu',
  },
  UValue: {
    name: 'UValue',
    label: 'Btu/F·ft²·h',
  },
  psi: {
    name: 'psi',
    label: 'psi',
  },
  Insulation: {
    name: 'Insulation',
    label: 'F·ft²·h/Btu',
  },
  SoilConductivity: {
    name: 'Conductivity',
    label: 'Btu/h/ft/F',
  },
  Power: {
    name: 'Power',
    label: 'HP'
  },
  HumidityRatio: {
    name: 'HumidityRatio',
    // TODO - do subscripts proper
    label: 'lb_w/lb_da',
  },
  SpecificVolume: {
    name: 'SpecificVolume',
    // TODO - do subscripts proper
    label: 'ft³/lb_da',
  },
  Enthalpy: {
    name: 'Enthalpy',
    // TODO - do subscripts proper
    label: 'Btu/lb_da',
  },
  People: {
    name: 'People',
    label: 'People',
  },
  // People per area
  PeoplePer1000ft2: {
    name: 'PeoplePerArea',
    label: 'People/1000ft²',
  },
});

function makeUnitConversionsMap(unitsEnum) {
  let conversions = {};
  for (const key of unitsEnum._keys) {
    conversions[key] = {
      // Self-conversion
      [key]: 1.0,
    };
  }
  for (const key of unitsEnum._keys) {
    let keyConversions = unitsEnum._data[key].conversions;
    if (keyConversions) {
      for (const dstKey in keyConversions) {
        if (typeof keyConversions[dstKey] == 'number') {
          conversions[key][dstKey] = keyConversions[dstKey];
          conversions[dstKey][key] = 1.0 / keyConversions[dstKey];
        } else {
          // Assume func
          conversions[key][dstKey] = keyConversions[dstKey];
        }
      }
    }
  }
  // We propagate the conversions info to the units nodes until there are no more
  // changes to be made (relaxation algo). We want the conversion info to be flat, for easy lookup.
  let madeChanges = true;
  while (madeChanges) {
    madeChanges = false;
    for (const key in conversions) {
      // n1 for neighbor-1, n2 for neighbor-2
      for (const n1Key in conversions[key]) {
        for (const n2Key in conversions[n1Key]) {
          if (!(n2Key in conversions[key]) && typeof conversions[key][n1Key] == 'number') {
            // console.log(`Adding conversion: ${key} => ${n2Key}`);
            conversions[key][n2Key] = conversions[key][n1Key] * conversions[n1Key][n2Key];
            madeChanges = true;
          }
        }
      }
    }
  }

  return conversions;
}
Units.conversionsMap = makeUnitConversionsMap(Units);
// console.log("Unit conversionsMap: " + prettyJson(Units.conversionsMap));

Units.convertValue = (val, valUnits, targetUnits) => {
  if (targetUnits == valUnits) {
    return val;
  }
  if (targetUnits in Units.conversionsMap[valUnits]) {
    let conversion = Units.conversionsMap[valUnits][targetUnits];
    if (typeof conversion == 'number') {
      return val * conversion;
    } else {
      return conversion(val);
    }
  } else {
    throw new Error(`No known conversion from ${valUnits} to ${targetUnits}`);
  }
}

export function valToStr(val, opts) {
  opts = valOr(opts, {})
  if (typeof val == 'number') {
    // Note: the '+' converts the string back to a number, which drops
    // any unneeded decimal places
    let numDecimals = valOr(opts.precision, 4)
    return +Number.parseFloat(val).toFixed(numDecimals);
  } else if (typeof val == 'object' && val !== null) {
    if ('toValString' in val) {
      return val.toValString();
    }
    // return '<Object>';
    return prettyJson(val);
  } else {
    return prettyJson(val);
  }
}

export function makeValStr(val, units, opts) {
    if (!(units in Units)) {
      throw new Error(`Unknown units: ${units}`);
    }
    let unitsStr = Units._data[units].label;
    return `${valToStr(val, opts)}${unitsStr}`
}


export function getLabel(units) {
  return Units._data[units].label;
}

