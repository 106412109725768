import { MaterialDataTable } from './MaterialDataTable.js';
import { TimeSeriesDataTable } from './TimeSeriesDataTable.js';

import { DishwasherAppliances_Data } from './Tables/DishwasherAppliances_Data.js';
import { KitchenAppliances_Unhooded_Data } from './Tables/KitchenAppliances_Unhooded_Data.js';
import { TimeSeries_Data } from './Tables/TimeSeries_Data.js';

function NumberFromString(numberStr) {
  // Remove commas (not handled by the default Number class)
  if (typeof numberStr === 'string') {
    return Number(numberStr.replace(/,/g, ''))
  }
  return numberStr
}

export let DishwasherAppliances_Table = {
  name: 'DishwasherAppliances',
  class: MaterialDataTable,
  data: DishwasherAppliances_Data,
  headerRows: 2,
  funcs: {
    getLatentLoad: (csv, applianceName) => {
      return csv.lookupValue(applianceName, 'F')
    },
  }
}

export let KitchenAppliances_Unhooded_Table = {
  name: 'KitchenAppliances_Unhooded',
  class: MaterialDataTable,
  data: KitchenAppliances_Unhooded_Data,
  headerRows: 2,
  funcs: {
    getLatentLoad: (csv, applianceName) => {
      let totalLoad = csv.lookupValue(applianceName, 'C', NumberFromString)
      let idleLatentLoad = csv.lookupValue(applianceName, 'H', NumberFromString)
      let idleStandbyLoad = csv.lookupValue(applianceName, 'D', NumberFromString)
      console.log(`totalLoad: ${totalLoad}, idleLatentLoad: ${idleLatentLoad}, idleStandbyLoad: ${idleStandbyLoad}`)
      return totalLoad * idleLatentLoad / idleStandbyLoad;
    },
  }
};

export let TimeSeriesData_Table = {
  name: 'TimeSeriesData',
  class: TimeSeriesDataTable,
  data: TimeSeries_Data,
  funcs: {
  }
}

export function registerCommercialTables(dataCache) {
  let tables = [
    DishwasherAppliances_Table,
    KitchenAppliances_Unhooded_Table,
    TimeSeriesData_Table,
  ]
  for (const tableDesc of tables) {
    console.log(`Registering table: ${tableDesc.name}`)
    dataCache.registerTable(tableDesc.name, tableDesc.class.getLoader(tableDesc))
  }
}