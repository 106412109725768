<script setup>
import { ref } from 'vue'
import { gApp, Wall, WallWindow, WallDoor,
} from './State.js'
import { removeElem } from './SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import LocationData from './LocationData.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'

const props = defineProps({
  space: [Object]
})

let proj = gApp.proj();

</script>

<template>
  <SimpleCollapse class="Section Emphasis" title="Walls" @onOpen="gApp.proj().setHelpId('Walls')">
    <HelpButton class="float-r" id="Walls">
      <h4 class="mb-s">Walls</h4>
      <p class="mb-s">
        When entering the wall <b>area</b>, include the entire wall area. That is, do not subtract window or door areas from the wall area. When you add windows or doors to your wall, the wall area will automatically subtract the areas of those windows and doors. When measuring wall area, use external exposed area, measured floor to floor.
        <br><br>
          When selecting whether the wall is <b>mostly shaded</b> or not, consider whether the wall is mostly shaded during peak sunlight hours. This can include neighbouring houses, trees, or nearby buildings. If you are unsure, then select “No”.
        <br><br>

          <b>Wall types</b> are created in the Walls section in the left-hand menu. Once wall types are created, they will show up in the available list of walls to use to build your house.
        <br><br>

          For adding <b>windows and doors</b>, the options from the dropdown menus come from the window and door types created under the Windows section and Doors section from the left-hand menu. The same goes for interior and exterior shading. See each respective section for more detail.
        <br><br>

          The <b>direction</b> to select is the direction that the wall is facing. That is, if you were standing with your back to the wall, then the direction that you would be looking toward.

      </p>
    </HelpButton>
    <div class="mb-m">
      <p class="mute-header">Info</p>
      <p class="help-text">
        Add your house's walls here. Each wall references a <b>Wall Type</b> from the <b>Walls</b> tab.
      </p>
    </div>
    <div>
      <WarnText v-if="!proj.wallTypes.length" type="warn" class="mb-xs">Before adding walls, go to the <router-link to="walls">Walls</router-link> tab to setup some Wall Types.</WarnText>
      <table class="SimpleTable WallsTable MarginBotXS w-90" v-if="space.walls.length > 0">
        <tr>
          <th>Type</th>
          <th>Area</th>
          <th>Direction</th>
          <th v-if="gApp.proj().isResidential()">Mostly Shaded</th>
          <th>Windows & Doors</th>
          <th></th>
        </tr>
        <template v-for="wall in space.walls">
          <tr class="MarginBotS">
            <td><FieldInput v-model="wall.wallType" :compact="true" /></td>
            <td><FieldInput v-model="wall.area" :compact="true" /></td>
            <td><FieldInput v-model="wall.direction" :compact="true" /></td>
            <td v-if="gApp.proj().isResidential()"><FieldInput v-model="wall.isMostlyShaded" :compact="true" /></td>
            <td>
              <p>{{wall.getNumWindows()}} Window(s)</p>
              <p>{{wall.getNumDoors()}} Door(s)</p>
              <button @click="wall.expandedInUi = !wall.expandedInUi" class="upper">Edit</button>
            </td>
            <td><button @click="removeElem(space.walls, wall)" class="upper">Delete</button></td>
          </tr>
          <tr v-if="wall.expandedInUi" class="mb-s RowDetailsRow">
            <td colspan="100%" class="RowDetails">
              <button class="LinkBtn upper CloseDetailsBtn" @click="wall.expandedInUi = false">Close</button>
              <div class="mb-m">
                <p class="mute-title">Windows</p>
                <p class="help-txt mb-xs">If this wall has windows, add them here.</p>
                <WarnText v-if="!proj.windowTypes.length" type="warn" class="mb-xs">Before adding windows, go to the <router-link to="windows">Windows</router-link> tab to setup some Window Types.</WarnText>
                <table class="SimpleTable MinorTable w-90" v-if="wall.windows.length">
                  <tr>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Interior Shading</th>
                    <th>Exterior Shading</th>
                    <th></th>
                  </tr>
                  <tr v-for="window in wall.windows">
                    <td><FieldInput v-model="window.windowType" :compact="true"/></td>
                    <td><FieldInput v-model="window.quantity" :compact="true"/></td>
                    <td><FieldInput v-model="window.interiorShadingType" :compact="true"/></td>
                    <td><FieldInput v-model="window.exteriorShadingType" :compact="true"/></td>
                    <td><button @click="removeElem(wall.windows, window)" class="small-upper">Delete</button></td>
                  </tr>
                </table>
                <button @click="wall.windows.push(WallWindow.create())" class="small-upper AddBtn">Add Window</button>
              </div>

              <div class="mb-m">
                <p class="mute-title">Doors</p>
                <p class="help-txt mb-xs">If this wall has doors, add them here.</p>
                <WarnText v-if="!proj.doorTypes.length" type="warn" class="mb-xs">Before adding doors, go to the <router-link to="doors">Doors</router-link> tab to setup some Door Types.</WarnText>
                <table class="SimpleTable MinorTable w-90"  v-if="wall.doors.length">
                  <tr>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Interior Shading</th>
                    <th>Exterior Shading</th>
                    <th></th>
                  </tr>
                  <tr v-for="door in wall.doors">
                    <td><FieldInput :compact="true" v-model="door.doorType" /></td>
                    <td><FieldInput :compact="true" v-model="door.quantity" /></td>
                    <td><FieldInput :compact="true" v-model="door.interiorShadingType" /></td>
                    <td><FieldInput :compact="true" v-model="door.exteriorShadingType" /></td>
                    <td><button @click="removeElem(wall.doors, door)" class="small-upper">Delete</button></td>
                  </tr>
                </table>
                <button @click="wall.doors.push(WallDoor.create())" class="small-upper AddBtn">Add Door</button>
              </div>
              <div v-if="gApp.proj().isCommercial()" class="mb-xs">
                <p class="mute-title">Ceiling Plenum</p>
                <FieldInput v-model="wall.hasCeilingPlenum" />
                <FieldInput v-model="wall.portionOfWallLoadToPlenum" />
              </div>
            </td>
          </tr>
        </template>
      </table>
      <button @click="space.walls.push(Wall.create())" class="MarginBotS CreateBtn">Add Wall</button>
    </div>
  </SimpleCollapse>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}
</style>