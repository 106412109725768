import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import * as ser from './SerUtil.js'

export { Units } from './Units.js'

import { BasicObject, makeNoYesField, makeYesNoField, YesNo, RecoveryType, } from './Components.js'
import { Field, FieldGroup, FieldType, } from './Field.js'

import { Zone } from './Zone.js'

import { gApp, DebugOn, } from './State.js'

// TODO - old
export class AirHandlerFan {
  init(name) {
    this.name = Field.makeName(`Name`, name)
    this.power = new Field({
      name: 'Power',
      type: FieldType.Power,
    })
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
    })
    this.motorIsInAirStream = makeYesNoField('Motor is in air stream')
    // TODO - units?
    this.estimatedPressureDrop = new Field({
      name: 'Estimated Pressure Drop',
      type: FieldType.Pressure,
    })
    this.fanEfficiency = new Field({
      name: 'Fan Efficiency',
      type: FieldType.Percent,
    })
    this.motorEfficiency = new Field({
      name: 'Motor Efficiency',
      type: FieldType.Percent,
    })

    this.fields = [
      'name',
      'power',
      'quantity',
      'motorIsInAirStream',
      'estimatedPressureDrop',
      'fanEfficiency',
      'motorEfficiency',
    ]

    this.serFields = [
      ...this.fields,
    ]
    this.childObjs = '$auto'
  }
}
setupClass(AirHandlerFan)

export class SystemFan {
  init() {
    this.power = new Field({
      name: 'Power',
      type: FieldType.Power,
    })
    this.motorIsInAirStream = makeYesNoField('Motor is in air stream')
    this.externalStaticPressure = new Field({
      name: 'External Static Pressure',
      type: FieldType.Pressure,
    })
    this.fanEfficiency = new Field({
      name: 'Fan Efficiency',
      type: FieldType.Percent,
    })
    this.motorEfficiency = new Field({
      name: 'Motor Efficiency',
      type: FieldType.Percent,
    })

    this.fields = [
      'power',
      'motorIsInAirStream',
      'externalStaticPressure',
      'fanEfficiency',
      'motorEfficiency',
    ]

    this.serFields = [
      ...this.fields,
    ]
    this.childObjs = '$auto'
  }
}
setupClass(SystemFan)

let SupplyAirType = makeEnum({
  FlowIsEqualToVentilation: `Flow is equal to ventilation`,
  SpecifiedPortionOfSupplyFlow: `Specified portion of supply flow`,
  ManuallySetSupplyFlow: `Manually set supply flow`,
})

let ExhaustAirType = makeEnum({
  AllExhaustIsThroughUnit: `All exhaust is through unit`,
  SpecifiedPortionOfExhaustFlow: `Specified portion of exhaust flow`,
  ManuallySetExhaustFlow: `Manually set exhaust flow`,
})

export class SystemHeatRecovery {
  init() {
    this.recoveryType = Field.makeSelect('Recovery Type', RecoveryType, {bold: true})

    this.supplyAir = FieldGroup.fromDict({
      'entryType': Field.makeSelect('Supply Air', SupplyAirType),
      'portionOfVentilation': new Field({
        name: 'Portion of Ventilation',
        type: FieldType.Percent,
      }),
      'manualValue': new Field({
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
      }),
    })
    this.supplyAir.get('entryType').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None;
    })
    this.supplyAir.get('portionOfVentilation').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.supplyAir.get('entryType').value == SupplyAirType.SpecifiedPortionOfSupplyFlow;
    });
    this.supplyAir.get('manualValue').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.supplyAir.get('entryType').value == SupplyAirType.ManuallySetSupplyFlow;
    });

    this.exhaustAir = FieldGroup.fromDict({
      'entryType': Field.makeSelect('Exhaust Air', ExhaustAirType),
      'portionOfExhaustFlow': new Field({
        name: 'Portion of Exhaust Flow',
        type: FieldType.Percent,
      }),
      'manualValue': new Field({
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
      }),
    })
    this.exhaustAir.get('entryType').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None;
    })
    this.exhaustAir.get('portionOfExhaustFlow').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.exhaustAir.get('entryType').value == ExhaustAirType.SpecifiedPortionOfExhaustFlow;
    });
    this.exhaustAir.get('manualValue').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.exhaustAir.get('entryType').value == ExhaustAirType.ManuallySetExhaustFlow;
    });

    this.hrvGroup = new FieldGroup([
      new Field({
        key: 'summerEfficiency',
        name: 'Summer Efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'winterEfficiency',
        name: 'Winter Efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      })
    ]);
    this.hrvGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.HRV;
    })

    this.ervGroup = new FieldGroup([
      new Field({
        key: 'summerSensibleEfficiency',
        name: 'Summer sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'summerTotalEfficiency',
        name: 'Summer total efficiency',
        type: FieldType.Percent,
        defaultValue: 60,
      }),
      new Field({
        key: 'winterSensibleEfficiency',
        name: 'Winter sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      }),
      new Field({
        key: 'winterTotalEfficiency',
        name: 'Winter total efficiency',
        type: FieldType.Percent,
        defaultValue: 80,
      }),
    ]);
    this.ervGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.ERV;
    })

    this.serFields = [
      'recoveryType',
      'supplyAir',
      'exhaustAir',
      'hrvGroup',
      'ervGroup',
    ]
    this.childObjs = '$auto'
  }
}
setupClass(SystemHeatRecovery)

let PreconditionOutdoorAirType = makeEnum({
  BeforeHRV_ERV: `Before HRV/ERV`,
  AfterHRV_ERV: `After HRV/ERV`,
  None: 'None'
})

let PreconditionHeatingOptions = makeEnum({
  TotalHeat: `Total Heat`,
  LeavingDryBuldTemp: `Leaving Dry Bulb Temp`,
})

let PreconditionCoolingOptions = makeEnum({
  LeavingDryBulbTemp: `Leaving Dry Bulb Temp`,
  LeavingWetBulbTemp: `Leaving Wet Bulb Temp`,
})

class PreconditionOutdoorAir  {
  init() {
    this.type = Field.makeSelect('Type', PreconditionOutdoorAirType)
    
    this.heatingOptions = Field.makeSelect('Heating', PreconditionHeatingOptions)
    this.totalHeat = new Field({
      name: 'Total Heat',
      type: FieldType.Power,
    })
    this.heatingOptions.setVisibility(() => {
      return this.type.value != PreconditionOutdoorAirType.None;
    })
    this.totalHeat.setVisibility(() => {
      return this.type.value != PreconditionOutdoorAirType.None && this.heatingOptions.value == PreconditionHeatingOptions.TotalHeat;
    })

    this.coolingOptions = Field.makeSelect('Cooling', PreconditionCoolingOptions)
    this.coolingOptions.setVisibility(() => {
      return this.type.value != PreconditionOutdoorAirType.None;
    })

    this.fields = [
      'type',
      'heatingOptions',
      'totalHeat',
      'coolingOptions',
    ]

    this.serFields = [
      'type',
      'heatingOptions',
      'totalHeat',
      'coolingOptions',
    ]
    this.childObjs = '$auto'
  }
}
setupClass(PreconditionOutdoorAir)

class SystemFans {
  init() {
    this.useSupplyFan = makeYesNoField('Use supply fan')
    this.supplyFan = SystemFan.create()
    this.useReturnFan = makeYesNoField('Use exhaust fan')
    this.returnFan = SystemFan.create()

    this.serFields = [
      'useSupplyFan',
      'supplyFan',
      'useReturnFan',
      'returnFan',
    ]
    this.childObjs = '$auto'
  }

  usingSupplyFan() {
    return this.useSupplyFan.value == YesNo.Yes;
  }

  usingReturnFan() {
    return this.useReturnFan.value == YesNo.Yes;
  }
}
setupClass(SystemFans)

export let SystemType = makeEnum({
  CAV: 'CAV',
  VAV: 'VAV',
  RadiantInductionOrChilledBeam: 'Radiant, induction, or chilled beam',
  Unknown: 'Unknown',
})

let ReliefAirOptions = makeEnum({
  PercentageOfVentilationAir: 'Percentage of ventilation air',
  Manual: 'Manual',
})

let ZoneAirflowCalcMethod = makeEnum({
  SumOfPeaks: 'Sum of peaks',
  TotalPeak: 'Total peak',
})

class CAVSystemInputs {
  init() {
    this.heatingSupplyTemp = new Field({
      name: 'Heating Supply Temp',
      type: FieldType.Temp,
    })
    this.coolingSupplyTemp = new Field({
      name: 'Cooling Supply Temp',
      type: FieldType.Temp,
    })
    this.includeZoneTerminalReheat = makeYesNoField('Include zone terminal reheat')
    this.zoneAirflowCalcMethod = Field.makeSelect('Zone Airflow Calculation Method', ZoneAirflowCalcMethod)
    this.keepAirflowsSeparate = makeYesNoField('Keep heating and cooling airflows separate?')

    this.fields = [
      'heatingSupplyTemp',
      'coolingSupplyTemp',
      'includeZoneTerminalReheat',
      'zoneAirflowCalcMethod',
      'keepAirflowsSeparate',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }
}
setupClass(CAVSystemInputs)

class VAVSystemInputs {
  init() {
    this.heatingSupplyTemp = new Field({
      name: 'Heating Supply Temp',
      type: FieldType.Temp,
    })
    this.coolingSupplyTemp = new Field({
      name: 'Cooling Supply Temp',
      type: FieldType.Temp,
    })

    this.fields = [
      'heatingSupplyTemp',
      'coolingSupplyTemp',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }
}
setupClass(VAVSystemInputs)

let PrimaryAirCoolingEntry = makeEnum({
  Airflow: 'Airflow',
  WetBulbTemp: 'Supply Wet Bulb Temp',
  DewPointTemp: 'Supply Dew Point Temp',
  RelativeHumidity: 'Supply Relative Humidity',
})

class RadiantInductionSystemInputs {
  init() {
    this.primaryAirHeatingSupplyTemp = new Field({
      name: 'Primary Air Heating Supply Temp',
      type: FieldType.Temp,
    })
    this.primaryAirCoolingSupplyTemp = new Field({
      name: 'Primary Air Cooling Supply Temp',
      type: FieldType.Temp,
    })
    this.primaryAirCooling = Field.makeSelect('Primary Air Cooling', PrimaryAirCoolingEntry)

    this.airflow = new Field({
      name: 'Airflow',
      type: FieldType.Flow,
    })
    this.airflow.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.Airflow;
    })
    this.supplyWetBulbTemp = new Field({
      name: 'Supply Wet Bulb Temp',
      type: FieldType.Temp,
    })
    this.supplyWetBulbTemp.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.WetBulbTemp;
    })
    this.supplyDewPointTemp = new Field({
      name: 'Supply Dew Point Temp',
      type: FieldType.Temp,
    })
    this.supplyDewPointTemp.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.DewPointTemp;
    })
    this.supplyRelativeHumidity = new Field({
      name: 'Supply Relative Humidity',
      type: FieldType.Percent,
    })
    this.supplyRelativeHumidity.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.RelativeHumidity;
    })

    this.fields = [
      'primaryAirHeatingSupplyTemp',
      'primaryAirCoolingSupplyTemp',
      'primaryAirCooling',
      'airflow',
      'supplyWetBulbTemp',
      'supplyDewPointTemp',
      'supplyRelativeHumidity',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }
}
setupClass(RadiantInductionSystemInputs)

export class System {
  init(name, makeId) {
    this.name = Field.makeName(`System Name`, name)
    this.id = makeId ? gApp.proj().makeId('System') : 0;
    this.zones = []

    this.estimatedTotalOccupancy = new Field({
      name: 'Estimated Total Occupancy',
      type: FieldType.Count,
    })

    this.changeVentilationForModes = makeYesNoField('Change ventilation for heating and cooling modes?')
    this.demandControlledVentilation = FieldGroup.fromDict({
      'use': makeNoYesField('Use demand controlled ventilation'),
      'matchOccupancySchedule': makeYesNoField('Match occupancy schedule'),
      'schedule': Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
        errorWhenEmpty: `You must create a Schedule`,
      }),
    })
    this.demandControlledVentilation.get('matchOccupancySchedule').setVisibility(() => {
      return this.demandControlledVentilation.get('use').value == YesNo.Yes;
    })
    this.demandControlledVentilation.get('schedule').setVisibility(() => {
      return this.demandControlledVentilation.get('use').value == YesNo.Yes &&
        this.demandControlledVentilation.get('matchOccupancySchedule').value == YesNo.No;
    });

    this.heatRecovery = SystemHeatRecovery.create()
    this.preconditionOutdoorAir = PreconditionOutdoorAir.create()

    this.economizer = FieldGroup.fromDict({
      'use': makeNoYesField('Uses economizer'),
      'minOutdoorAirPercentage': new Field({
        name: 'Minimum Outdoor Air Percentage',
        type: FieldType.Percent,
      }),
      'maxOutdoorAirPercentage': new Field({
        name: 'Maximum Outdoor Air Percentage',
        type: FieldType.Percent,
      }),
    })
    this.economizer.get('minOutdoorAirPercentage').setVisibility(() => {
      return this.economizer.get('use').value == YesNo.Yes;
    })
    this.economizer.get('maxOutdoorAirPercentage').setVisibility(() => {
      return this.economizer.get('use').value == YesNo.Yes;
    })

    this.systemFans = SystemFans.create()

    this.systemType = Field.makeSelect('System Type', SystemType)
    this.separateAirflow = makeYesNoField('Separate airflow for heating and cooling?')
    this.balanceReliefExhaustAir = makeYesNoField('Balance relief/exhaust air to ventilation air?')
    this.reliefAirOptions = Field.makeSelect('Relief Air Options', ReliefAirOptions)
    this.reliefAirOptions.setVisibility(() => {
      return this.balanceReliefExhaustAir.value == YesNo.No;
    })
    this.reliefAirPercent = new Field({
      name: 'Relief air as percentage of ventilation air',
      type: FieldType.Percent,
    })
    this.reliefAirPercent.setVisibility(() => {
      return this.reliefAirOptions.visible && this.reliefAirOptions.value == ReliefAirOptions.PercentageOfVentilationAir;
    })
    this.manualReliefAirFlow = new Field({
      name: 'Relief air flow (in addition to HRV/ERV exhaust given above)',
      type: FieldType.AirFlow,
    })
    this.manualReliefAirFlow.setVisibility(() => {
      return this.reliefAirOptions.visible && this.reliefAirOptions.value == ReliefAirOptions.Manual;
    })
    this.detailFields = [
      'systemType',
      'separateAirflow',
      'balanceReliefExhaustAir',
      'reliefAirOptions',
      'reliefAirPercent',
      'manualReliefAirFlow',
    ]

    this.cavSystemInputs = CAVSystemInputs.create()
    this.vavSystemInputs = VAVSystemInputs.create()
    this.radiantInductionSystemInputs = RadiantInductionSystemInputs.create()

    this.serFields = [
        'name',
        'id',
        ser.arrayField('zones', () => { return Zone.create(); }),
        'estimatedTotalOccupancy',
        'changeVentilationForModes',
        'demandControlledVentilation',
        'heatRecovery',
        'preconditionOutdoorAir',
        'economizer',
        ...this.detailFields,
        'systemFans',
        'cavSystemInputs',
        'vavSystemInputs',
        'radiantInductionSystemInputs',
    ]
    this.childObjs = '$auto'
  }

  getSystemName() {
    if (this.systemType.value == SystemType.CAV) {
      return 'CAV System';
    } else if (this.systemType.value == SystemType.VAV) {
      return 'VAV System';
    } else if (this.systemType.value == SystemType.RadiantInductionOrChilledBeam) {
      return 'Radiant Induction or Chilled Beam System';
    }
  }
}
setupClass(System)
