import { ref, reactive } from 'vue'
import { prettyJson, waitMillis, } from './SharedUtils.js'
import { Units, valToStr, makeValStr } from './Units.js'
import { evaluate, matrix, } from 'mathjs'

export { Units, valToStr, makeValStr, } from './Units.js'; 

let ValToStrOpts = {
  precision: 6,
};

let MatrixPrototype = Object.getPrototypeOf(matrix([1, 2, 3]));
MatrixPrototype.toValString = function() {
  return `[${this.size()[0]}x${this.size()[1]}]`;
};
MatrixPrototype.toDenseString = function() {
  let str = ``;
  for (let i = 0; i < this.size()[0]; ++i) {
    for (let j = 0; j < this.size()[1]; ++j) {
      str += `${this.get([i, j])} `;
    }
    str += '\n';
  }
  return str;
};

let MsgType = {
  Log: 'Log',
  SetVar: 'SetVar',
  Eval: 'Eval',
  StartCall: 'StartCall',
  EndCall: 'EndCall',
  StartSection: 'StartSection',
  EndSection: 'EndSection',
  //StartContext: 'StartContext',
  //EndContext: 'EndContext',
};

function findAllVarPositions(eqStr) {
  let varsList = []
  let curPart = '';
  let start = -1;

  for (let i = 0; i < eqStr.length; ++i) {
    let char = eqStr[i];
    if (('a' <= char && char <= 'z') || ('A' <= char && char <= 'Z') || char == '_' ||
      (curPart.length > 0 && '0' <= char && char <= '9')) {
      if (curPart.length === 0) {
        start = i;
      }
      curPart += char;
    } else {
      if (curPart.length > 0) {
        varsList.push({start, end: i, name: curPart});
        curPart = ''
      }
    }
  }
  if (curPart.length > 0) {
    varsList.push({start, end: eqStr.length, name: curPart});
  }

  return varsList;
}

function subVars(eqStr, vars) {
  // Return the eq string with variable names replaced with values
  let varPositions = findAllVarPositions(eqStr);
  let outEq = eqStr;
  for (let i = varPositions.length - 1; i >= 0; --i) {
    let varPos = varPositions[i];
    if (!(varPos.name in vars) ) {
      continue;
    }
    let varVal = vars[varPos.name];
    // Note: some vars may be funcs, do not replace
    if (typeof varVal == 'string' || typeof varVal == 'number') {
      outEq = outEq.substring(0, varPos.start) + valToStr(varVal, ValToStrOpts) + outEq.substring(varPos.end);
    }
  }
  return outEq;
}

// Function decorator that adds argument and result info (esp.
// the units).
export function describeFunc(name, argsDesc, resDesc, func) {
  func.desc = {
    name: name,
    form: 'args-list',
    args: argsDesc,
    res: resDesc,
  }
  return func;
}

export class CalcContext {
  static create() {
    let ctx = new CalcContext();
    return ctx;
  }

  constructor() {
    this._log = [];
    this._structuredLog = [];
    this._ctxStack = [];
    this._callDepth = 0;
    this._nameStack = []
    this._progressText = ''

    return new Proxy(this, {
      get: (obj, key) => {
        return obj[key];
      },
      set: (obj, key, value) => {
        if (typeof key === 'string' && key.length > 0 &&
          key[0] == '_') {
          // Ignore our private vars
          return Reflect.set(obj, key, value);
        }
        obj.pushMsg(`${key.padEnd(20)} = ${valToStr(value, ValToStrOpts)}`);
        obj.pushStructuredMsg(MsgType.SetVar, {key, value})

        // Record that this var is part of a ctx
        if (obj._ctxStack.length > 0) {
          obj._ctxStack[obj._ctxStack.length - 1][key] = true;
        }

        // Error out early on bad values
        if (typeof value == 'number' && !Number.isFinite(value)) {
          throw new Error(`Detected an error during calculations: '${key} = ${value}'`);
        }

        return Reflect.set(obj, key, value);
      }
    })
  }

  add(name, value, opts) {
    this[name] = value;
  }

  addMany(obj) {
    for (const key in obj) {
      this[key] = obj[key];
    }
  }

  startSection(name) {
    this._nameStack.push(name);
    let fullPath = this._nameStack.join('/');
    let str = `>> ${fullPath}:`
    // this.pushMsg(`${str}\n${'='.repeat(str.length)}\n`);
    this.pushMsg(`${str}\n`);
    this.pushStructuredMsg(MsgType.StartSection, {name, fullPath});
  }

  endSection() {
    this._nameStack.pop();
    this.pushMsg("<< DONE\n");
    this.pushStructuredMsg(MsgType.EndSection, {});
  }

  startContext(name) {
    if (name) {
      this._nameStack.push(name);
      let str = `>> ${this._nameStack.join('/')}`;
      this.pushMsg(`${str}\n`);
    }
    this._ctxStack.push({_name: name});
    /*
    this.pushStructuredMsg(MsgType.StartContext, {
      name
    });
    */
  }

  endContext() {
    let ctx = this._ctxStack.pop();
    for (const key in ctx) {
      delete this[key];
    }
    if (ctx._name) {
      this._nameStack.pop();
      this.pushMsg("<< DONE\n");
    }
    //this.pushStructuredMsg(MsgType.EndContext, {});
  }

  async briefWait() {
    // Pause for a bit to allow the UI to update
    await waitMillis(10);
  }

  getProgressText() {
    return this._progressText;
  }

  setProgressText(text) {
    this._progressText = text;
  }

  static makeArgsDesc(argsDesc, argsList) {
    let argKeys = Object.keys(argsDesc);
    if (argKeys.length !== argsList.length) {
      throw new Error(`Expected ${argKeys.length} but` +
        ` got ${argsList.length}.`);
    }
    let str = ``;
    for (let i = 0; i < argKeys.length; ++i) {
      let argKey = argKeys[i];
      str += `${argKey}=${makeValStr(argsList[i], argsDesc[argKey], ValToStrOpts)}`
      if (i < argKeys.length - 1) {
        str += `, `;
      }
    }
    return str;
  }

  pushMsg(msg) {
    let padding = '';
    let strMsg = msg;
    if (this._callDepth > 0) {
      padding = ' '.repeat((this._callDepth)*2);
      strMsg = msg.split("\n").map((line) => (padding + line)).join("\n");
    }
    this._log.push(strMsg);
  }

  pushStructuredMsg(type, value) {
    let newMsg = {type, value}
    this._structuredLog.push(newMsg);
    return newMsg;
  }

  call(func, ...args) {
    // console.log(`func:`, func.desc);
    let argsDesc = CalcContext.makeArgsDesc(func.desc.args, args)
    if (this._callDepth == 0) {
      this.pushMsg("");
    }
    this.pushMsg(`${func.desc.name}(${argsDesc}):`)
    this._callDepth++;

    this.startContext();
    let startMsg = this.pushStructuredMsg(MsgType.StartCall, {func, args, res: null});
    let res = func(this, ...args);
    this.endContext();
    this.pushStructuredMsg(MsgType.EndCall, {func, args, res});
    // Add the `res` to the start msg retroactively, so we can display it early in the logs
    startMsg.value.res = res;

    let retValDesc = makeValStr(res, func.desc.res, ValToStrOpts);
    this.pushMsg(`Out: ${retValDesc}`);
    this._callDepth--;

    return res;
  }

  getCtxObj() {
    let ctx = {};
    for (const varName in this) {
      if (typeof varName == 'string' && varName.length > 0 && varName[0] !== '_') {
        ctx[varName] = this[varName]
      }
    }
    return ctx;
  }

  evalNamedSum(elems, resName) {
    try {
      let ctxObj = this.getCtxObj();
      let msg = ''
      let spacesStr = ' '.repeat(resName.length)
      msg += `${resName} = Sum of...\n`
      let sum = 0;
      for (const key in elems) {
        sum += elems[key];
        msg += `[${key}] = ${valToStr(elems[key], ValToStrOpts)}\n`
      }
      msg += `${spacesStr} = ${sum}`
      this.pushMsg(msg);
      this.pushStructuredMsg(MsgType.Log, {msg});
      return sum;
    } catch (err) {
      let msg = `Error with eq: SUM ${eqStr}\n>> ${err.message}`;
      this.pushMsg(msg);
      this.pushStructuredMsg(MsgType.Log, {msg});
      throw err;
    }
  }

  evalSum(arr, eqStr, resName) {
    try {
      let ctxObj = this.getCtxObj();
      let msg = ''
      let spacesStr = ' '.repeat(resName.length)
      msg += `${resName} = SUM ${eqStr}\n`
      let sum = 0;
      for (let i = 0; i < arr.length; ++i) {
        // let elemCtx = {...ctxObj, E: arr[i], ...};
        let elemCtx = {...ctxObj, ...arr[i]};
        let elemRes = evaluate(eqStr, elemCtx);
        sum += elemRes;
        msg += `[${i + 1}] = ${subVars(eqStr, elemCtx)} = ${valToStr(elemRes, ValToStrOpts)}\n`
      }
      msg += `${spacesStr} = ${sum}`
      this.pushMsg(msg);
      this.pushStructuredMsg(MsgType.Log, {msg});
      return sum;
    } catch (err) {
      let msg = `Error with eq: SUM ${eqStr}\n>> ${err.message}`;
      this.pushMsg(msg);
      this.pushStructuredMsg(MsgType.Log, {msg});
      throw err;
    }
  }

  eval(eqStr, args, resName) {
    let ctxObj = {
      ...this.getCtxObj(),
      ...args
    };
    let msg = ''
    /*
    for (const argName in args) {
      msg += `${argName}: ${valToStr(args[argName])}\n`
    }
    */
    let spacesStr = ' '.repeat(resName.length)
    msg += `${resName} = ${eqStr}\n`
    msg += `${spacesStr} = ${subVars(eqStr, ctxObj)}\n`
    let res = null;
    try {
      res = evaluate(eqStr, ctxObj);
      msg += `${spacesStr} = ${valToStr(res, ValToStrOpts)}`
      this.pushMsg(msg);
      //this.pushStructuredMsg(MsgType.Eval, {eqStr, args, resName, res})
      this.pushStructuredMsg(MsgType.Log, {msg})
    } catch (err) {
      msg += `Error with eq: ${eqStr}\n>> ${err.message}`;
      this.pushMsg(msg);
      this.pushStructuredMsg(MsgType.Log, {msg});
      throw err;
    }
    return res;
  }

  log(str) {
    this.pushMsg(str);
    this.pushStructuredMsg(MsgType.Log, {msg: str})
  }

  logFatalError(err) {
    let errMsg = `Fatal error occurred:\n${err}\n\nTrace:\n${err.stack}`;
    this.pushMsg(errMsg);
    this.pushStructuredMsg(MsgType.Log, {msg: errMsg, extraClasses: 'FatalError'});
  }

  logLoadMatrix(name, matrix) {
    //this.log(`${name}:\n${matrix.toDenseString()}`);
    let str = `<p>${name}:</p><table class="LoadMatrixTable">`;
    let monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    str += `<tr><td></td>`
    for (let i = 0; i < 24; ++i) {
      let hrStr = `${i}`.padStart(2, '0');
      str += `<td><b>${hrStr}</b></td>`;
    }
    str += `</tr>`;
    for (let i = 0; i < matrix.size()[0]; ++i) {
      str += `<tr>`;
      str += `<td><b>${monthes[i]}</b></td>`;
      for (let j = 0; j < matrix.size()[1]; ++j) {
        str += `<td>${valToStr(matrix.get([i, j]), ValToStrOpts)}</td>`;
      }
      str += `</tr>`;
    }
    str += `</table>`;
    this.log(str);
  }

  getLogStr() {
    let logLines = [];
    for (const line of this._log) {
      if (line.startsWith(">>")) {
        if (logLines.length > 0 && !logLines[logLines.length - 1].endsWith('\n')) {
          logLines.push('\n');
        }
        let str = line.substring(3).trim();
        logLines.push(`${str}\n${'='.repeat(str.length)}\n`);
      } else if (line.startsWith("<<")) {
        if (logLines[logLines.length - 1] != '\n') {
          logLines.push("\n");
        }
      } else {
        logLines.push(line);
      }
    }
    return logLines.join("\n");
  }

  getLogDict() {
    let dict = {
      name: 'Calculations',
      log: []
    }
    let stack = [dict];
    let curStrs = []
    for (const item of this._log) {
      console.log("Item: " + item.substring(0, 50) + "...");
      let curObj = stack[stack.length - 1];
      console.log("CurObj name: " + curObj.name);
      if (item.startsWith(">>")) {
        console.log("START: " + item);
        if (curStrs.length > 0) {
          curObj.log.push(curStrs.join('\n'))
          curStrs = [];
        }
        let newObj = {
          name: item.substring(3),
          log: []
        }
        curObj.log.push(newObj);
        stack.push(newObj);
      } else if (item.startsWith("<<")) {
        console.log("END");
        if (curStrs.length > 0) {
          curObj.log.push(curStrs.join('\n'));
          curStrs = [];
        }
        stack.pop();
      } else {
        // console.log("PUSH " + item);
        curStrs.push(item);
      }
    }
    return dict;
  }

  getStructuredLog() {
    return this._structuredLog;
  }

  getStructuredLogDict() {
    // Convert the structuredLog array to a nested dict
    console.log("Making structured log dict...")
    //console.log("Structured log:\n", this._structuredLog);
    let outDict = {
      name: `<span class="RootHdr">Calculations</span>`,
      log: []
    }
    let objStack = [outDict]
    for (let i = 0; i < this._structuredLog.length; ++i) {
      let item = this._structuredLog[i];
      let curObj = objStack[objStack.length - 1];
      switch (item.type) {
        case MsgType.Log: {
          let extraClasses = item.value.extraClasses || '';
          curObj.log.push(`<span class="LogMsg ${extraClasses}">${item.value.msg}</span>`);
          break;
        }
        case MsgType.SetVar: {
          curObj.log.push(`${item.value.key} = ${valToStr(item.value.value, ValToStrOpts)}`);
          break;
        }
        case MsgType.Eval: {
          curObj.log.push(item.value.msg)
          break;
        }
        case MsgType.StartSection: {
          //let str = `>> ${item.value.fullPath}`;
          //curObj.log.push(str);
          let subObj = {name: item.value.name, log: []};
          curObj.log.push(subObj);
          objStack.push(subObj);
          break;
        }
        case MsgType.EndSection: {
          objStack.pop();
          break;
        }
        case MsgType.StartCall: {
          let argsDesc = CalcContext.makeArgsDesc(item.value.func.desc.args, item.value.args);
          let retValDesc = makeValStr(item.value.res, item.value.func.desc.res, ValToStrOpts);
          let subObjName = `<span class="FuncName">${item.value.func.desc.name}</span> ==> <span class="CallRes">${retValDesc}</span>`;
          let subObj = {
            name: subObjName,
            log: [
              `<div class="InputsMsg"><span class="InputsHdr">Inputs</span>: <span class="InputsArgs">${argsDesc}</span></div>`
            ]
          };
          //curObj.log.push(`${item.value.func.desc.name} with: ${argsDesc}`);
          curObj.log.push(subObj);
          objStack.push(subObj);
          break;
        }
        case MsgType.EndCall: {
          let retValDesc = makeValStr(item.value.res, item.value.func.desc.res, ValToStrOpts);
          curObj.log.push(`<div class="ResultMsg"><span class="RetValHdr">Result:</span> <span class="RetVal">${retValDesc}</span></div>`);
          objStack.pop();
          break;
        }
      }
    }
    console.log("Done")
    return outDict;
  }
};

